import Breadcrumb from "../Components/Breadcrumb";
import React, { useState } from "react";
import Base from "../Base";
import { Helmet } from "react-helmet-async";
import CSRFToken from "../CSRFToken";
import { feedbackForm } from "../../src/helpers/others/feedbackForm";
import { toast } from "react-toastify";
import DataLoader2 from "../Components/DataLoaders/DataLoader2";
const Feedback = () => {
	const [dataLoading, setdDataLoading] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const feedback = async (e) => {
		e.preventDefault();
		setdDataLoading(true);
		if (email.includes("@")) {
			await feedbackForm({ name, email, message }).then((data) => {
				if (data.id) {
					setName("");
					setEmail("");
					setMessage("");
					setdDataLoading(false);
					return toast("Feedback Posted", { type: "success", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
				} else {
					setdDataLoading(false);
				}
			});
		} else {
			setdDataLoading(false);
			return toast("You have not entered an email.", { type: "error", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
		}
	};
	return (
		<>
			<Helmet>
				<title>International Lunar University | Feedback</title>
			</Helmet>
			<Base>
				<Breadcrumb title="Feedback" />
				<section className="section feedback">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 col-md-6">
								<div className="me-lg-5 mb-3 mb-lg-0">
									<img src="images/SVGs/Feedback.svg" className="loginsvg" alt="Feedback" />
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="card mx-2 bgcolorgreyish border-0 border5px p-4">
									<div className="card-body p-0">
										<p className="text-start mb-0 colorblack fontsize16 pb-3">
											Thank you for visiting International Lunar University! Please share your experience with us, it will help us grow. <b className="darkbluetext">Keep Exploring!</b>
										</p>
										<p className="text-start mb-0 darkbluetext fw-bold fontsize16 mb-3">- Team International Lunar University</p>
										<form className="mt-4">
											<CSRFToken />
											<div className="row">
												<div className="col-lg-12">
													<h5 className="colorblack text-start mb-2 fontsize14">Name</h5>
													<div className="position-relative mb-3">
														<input
															className="input100 w-100 border5px border-0 colorblack"
															type="text"
															placeholder="Name"
															value={name}
															onChange={(e) => {
																setName(e.target.value);
															}}
															required
														/>
														<span className="focus-input100" />
														<span className="symbol-input100 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className="far fa-user-astronaut" />
															</span>
														</span>
													</div>
												</div>
												<div className="col-lg-12">
													<h5 className="colorblack text-start mb-2 fontsize14">Email</h5>
													<div className="position-relative mb-3">
														<input
															className="input100 w-100 border5px border-0 colorblack"
															type="email"
															placeholder="Email"
															value={email}
															onChange={(e) => {
																setEmail(e.target.value);
															}}
															required
														/>
														<span className="focus-input100" />
														<span className="symbol-input100 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className="far fa-envelope" />
															</span>
														</span>
													</div>
												</div>
												<div className="col-lg-12">
													<h5 className="colorblack text-start mb-2 fontsize14">Message</h5>
													<div className="position-relative mb-3">
														<textarea
															className="colorblack bgcolorwhite p-3 border5px border-0 w-100"
															style={{ height: "150px", resize: "none" }}
															placeholder="Your message"
															value={message}
															onChange={(e) => {
																setMessage(e.target.value);
															}}
															required
														/>
													</div>
												</div>
												<div className="col-lg-12">
													<div className="d-grid">
														<button
															className="fw-bold py-3 px-2 fontsize16 buynowbutton border5px text-uppercase d-inline-block"
															type="submit"
															onClick={(e) => {
																feedback(e);
															}}
															disabled={dataLoading ? true : false}
														>
															{dataLoading ? <DataLoader2 loaderSize={15} loaderType="ScaleLoader" loaderColor="#0007bf" /> : "Submit"}
														</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Base>
		</>
	);
};
export default Feedback;
