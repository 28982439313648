import { ViewQuiz_API } from "../../backend";
export const viewQuiz = async (id, next) => {
	const tokenValue = localStorage.getItem("token").replace(/['"]+/g, "");
	return await fetch(`${ViewQuiz_API}?quiz_guid=${id}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Token " + tokenValue,
		},
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			next(data);
		})
		.catch((err) => console.log(err));
};
