import axios from "axios";
import { FacebookAuth_API } from "../../backend";
const facebookLogin = (accesstoken, next) => {
	// console.log("AAAA", accesstoken);
	axios
		.post(FacebookAuth_API, {
			access_token: accesstoken,
		})
		.then((res) => {
			localStorage.setItem("token", res.data.key);
		})
		.then((data) => {
			next(data);
		});
};
export default facebookLogin;
