import React, { useContext, useEffect, useState } from "react";
import Base from "../Base";
import Breadcrumb from "../Components/Breadcrumb";
import DataLoader from "../Components/DataLoaders/DataLoader";
import { BaseContext, BundlesContext } from "../Context";
import { Helmet } from "react-helmet-async";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CartContext } from "../Contexts/CartContext";
import { isAuthenticated } from "../helpers/auth/authentication";
import tempImg from "../Assets/images/User_Image.webp";
import { singleBundle } from "../data/bundles/bundles";
import CourseListCard from "../Components/CourseListCard";
import { SingleEntityContext } from "../Contexts/SingleEntityContext";
import tempImg2 from "../Assets/images/Course_Image.webp";
import { WishlistContext } from "../Contexts/WishlistContext";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
const BundleDetail = (props) => {
	const history = useHistory();
	const { course_Enrollments, cookies, handleNotification } = useContext(BaseContext);
	const { findBundle, bundle } = useContext(BundlesContext);
	const { addBundle, cartItems } = useContext(CartContext);
	const { addBundleToWishlist, removeBundleFromWishlist, wishlistItems } = useContext(WishlistContext);
	const { addSingleCourse } = useContext(SingleEntityContext);
	const isInCart = (guid, userID) => {
		return !!!cartItems.bundles.find((item) => item.bundle.guid === guid && item.userID === userID);
	};
	const isInWishlist = (guid, userID) => {
		console.log(wishlistItems.bundles.find((item) => item.bundle.guid === guid && item.userID === userID));
		return wishlistItems.bundles.find((item) => item.bundle.guid === guid && item.userID === userID);
	};
	const [loading, setLoading] = useState(false);
	const [animateButton, setAnimateButton] = useState(false);
	useEffect(() => {
		const timer = setTimeout(() => setAnimateButton(false), 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [animateButton]);
	const location = useLocation();
	const [bundleBought, setbundleBought] = useState(false);
	useEffect(() => {
		singleBundle(props.match.params.id, (data) => {
			findBundle(data);
		});
	}, []);
	useEffect(() => {
		var mounted = true;
		var counter = 0;
		if (mounted) {
			!course_Enrollments.error &&
				bundle &&
				bundle.bundle_courses &&
				bundle?.bundle_courses.forEach((value) => {
					console.log("A");
					if (course_Enrollments.find((val) => val.course.guid === value.guid)) {
						counter++;
					}
				});

			if (bundle && bundle?.bundle_courses && counter === bundle?.bundle_courses?.length) {
				setbundleBought(true);
				setLoading(true);
			} else {
				setbundleBought(false);
				bundle && bundle?.bundle_courses && setLoading(true);
			}
		}

		return () => {
			mounted = false;
		};
	}, [course_Enrollments, bundle]);
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	return (
		<>
			<Helmet title={`International Lunar University | ${bundle?.bundle_title}`}>
				<meta name="title" content={bundle.bundle_title} />
				<meta name="description" content={bundle.bundle_meta_description} />
				<meta name="keywords" content={bundle.bundle_meta_tags} />
				<meta property="og:image" content={bundle?.bundle_thumbnail || tempImg} />
			</Helmet>
			<Base>
				<Breadcrumb title={bundle?.bundle_title} />
				{loading ? (
					<>
						{isDesktopOrLaptop && (
							<section className="section">
								<div className="container">
									<div className="row">
										<div className="col-lg-5">
											<div className="mb-3 card border-0">
												<img className="border5px shadow" src={bundle?.bundle_thumbnail || tempImg2} width="450px" height="450px" alt="Bundle_Image" />
												{bundle?.bundle_discount > 0 && (
													<span className="d-flex justify-content-center align-items-center" id="mydiscountpersin">
														{Math.abs(parseInt(bundle.bundle_discount) - parseFloat(bundle.bundle_discount)) > 0.5
															? parseInt(bundle.bundle_discount) + 1
															: parseInt(bundle.bundle_discount)}
														%
													</span>
												)}
												<button
													onClick={() => {
														if (isAuthenticated()) {
															isInWishlist(bundle.guid, cookies?.user?.[0]?.id)
																? removeBundleFromWishlist({ guid: bundle.guid, userID: cookies?.user?.[0].id })
																: addBundleToWishlist({ bundle, userID: cookies?.user?.[0].id });
														} else {
															return toast("Please login to access wishlist!", {
																type: "warning",
																autoClose: 5000,
																position: "bottom-center",
																hideProgressBar: false,
																pauseOnHover: true,
																pauseOnFocusLoss: true,
															});
														}
													}}
													className="d-flex hvr-icon-pulse border-0 fontsize14 justify-content-center align-items-center mywishlistsin"
												>
													<i
														className={`${
															isInWishlist(bundle.guid, cookies?.user?.[0]?.id) ? "fas fa-2x fa-heart darkbluetext hvr-icon" : "far fa-2x darkbluetext fa-heart"
														}`}
													/>
												</button>
											</div>
										</div>
										<div className="col-lg-6 ps-lg-4">
											<h3 className="colorblack mb-2 mt-3 mt-lg-0 text-center text-lg-start">{bundle.bundle_title}</h3>
											{bundle?.bundle_discount > 0 ? (
												<p className="mb-3 text-center text-lg-start">
													<span className="colorblack fontsize16">
														₹&nbsp;
														{(Math.abs(parseInt(bundle.bundle_sp) - parseFloat(bundle.bundle_sp)) > 0.5
															? parseInt(bundle.bundle_sp) + 1
															: parseInt(bundle.bundle_sp)
														).toLocaleString(undefined, { maximumFractionDigits: 2 })}
													</span>
													&nbsp;&nbsp;
													<span className="fontsize14 notaccepted text-decoration-line-through">
														₹ {bundle?.bundle_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
													</span>
												</p>
											) : (
												<p className="mb-3 text-center text-lg-start">
													<span className="colorblack fontsize16">₹ {bundle?.bundle_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
												</p>
											)}
											{bundleBought ? (
												<>
													<div className="row mb-3">
														<div className="col-12 col-lg-12 mb-3 pe-lg-3 mb-lg-0">
															<Link
																to={`/profile/mycourses`}
																className="w-50 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-100 addtocartbutton border5px text-uppercase"
															>
																Go To My Courses
															</Link>
														</div>
														<div className="col-12 col-lg-12 mt-3 mb-lg-0 d-flex justify-content-start align-items-center">
															<a
																href={bundle?.course_brochure}
																className="w-25 me-3 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-100 buynowbutton border5px text-uppercase"
															>
																<i className="fas fa-file-download" />
																&nbsp;&nbsp;Brochure
															</a>
														</div>
													</div>
												</>
											) : (
												<>
													<div className="row mb-3">
														<div className="col-12 col-lg-12 mb-lg-0 d-flex justify-content-start align-items-center">
															{isInCart(bundle?.guid, cookies?.user?.[0].id) ? (
																<>
																	<button
																		className={`${
																			animateButton
																				? "add-to-cart w-25 py-2 px-2 me-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-100 overflow-hidden addtocartbutton border5px text-uppercase is-added"
																				: "add-to-cart w-25 py-2 px-2 me-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-100 overflow-hidden addtocartbutton border5px text-uppercase"
																		}`}
																		onClick={() => {
																			if (isAuthenticated()) {
																				setAnimateButton(true);
																				addBundle({ bundle, userID: cookies?.user?.[0]?.id });
																			} else {
																				handleNotification("Please login to access cart!", "warning");
																				history.push("/signin");
																			}
																		}}
																	>
																		<span>Add To Cart</span>
																		<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
																			<path
																				className={`${animateButton ? "pathatc" : ""}`}
																				strokeDasharray="19.79 19.79"
																				strokeDashoffset="19.79"
																				fill="none"
																				stroke="#fff"
																				strokeWidth={2}
																				strokeLinecap="square"
																				strokeMiterlimit={10}
																				d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
																			/>
																		</svg>
																	</button>
																	<button
																		className="w-25 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-100 buynowbutton border5px text-uppercase"
																		onClick={() => {
																			if (isAuthenticated()) {
																				addSingleCourse(bundle);
																				history.push("/checkout");
																			} else {
																				handleNotification("Please login to buy!", "warning");
																				history.push("/signin");
																			}
																		}}
																	>
																		<span>Buy Now</span>
																	</button>
																</>
															) : (
																<>
																	<button
																		className={`${
																			animateButton
																				? "add-to-cart w-25 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-100 overflow-hidden addtocartbutton border5px text-uppercase is-added"
																				: "add-to-cart w-25 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-100 overflow-hidden addtocartbutton border5px text-uppercase"
																		}`}
																		onClick={() => {
																			history.push("/cart");
																			setAnimateButton(true);
																		}}
																	>
																		<span>Go To Cart</span>
																		<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
																			<path
																				className={`${animateButton ? "pathatc" : ""}`}
																				strokeDasharray="19.79 19.79"
																				strokeDashoffset="19.79"
																				fill="none"
																				stroke="#fff"
																				strokeWidth={2}
																				strokeLinecap="square"
																				strokeMiterlimit={10}
																				d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
																			/>
																		</svg>
																	</button>
																</>
															)}
														</div>
													</div>
													<div className="row mb-3">
														<div className="col-12 col-lg-12 mb-3 mb-lg-0">
															<a
																href={bundle?.course_brochure}
																className="w-25 me-3 cursorpointer py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-100 buynowbutton border5px text-uppercase"
															>
																<i className="fas fa-file-download" />
																&nbsp;&nbsp;Brochure
															</a>
														</div>
													</div>
												</>
											)}
										</div>
									</div>
									<div className="row">
										<div className="col-lg-12 mt-3">
											<ul className="nav d-flex justify-content-center nav-pills mb-3 borderbottomcart" id="pills-tab" role="tablist">
												<li className="nav-item" role="presentation">
													<button
														className="nav-link fontsize14 mybtnsame colorblack darkbluetexthover bgcolorwhite text-uppercase active"
														id="pills-coursesinthisbundle-tab"
														data-bs-toggle="pill"
														data-bs-target="#pills-coursesinthisbundle"
														type="button"
														role="tab"
														aria-controls="pills-coursesinthisbundle"
														aria-selected="true"
														style={{ border: "2.5px", borderRadius: "0px", borderRight: "2.5px dotted #cfcfcf" }}
													>
														Courses
													</button>
												</li>
												<li className="nav-item" role="presentation">
													<button
														className="nav-link fontsize14 mybtnsame colorblack darkbluetexthover bgcolorwhite text-uppercase"
														id="pills-outcomes-tab"
														data-bs-toggle="pill"
														data-bs-target="#pills-outcomes"
														type="button"
														role="tab"
														aria-controls="pills-outcomes"
														aria-selected="true"
														style={{ border: "2.5px", borderRadius: "0px" }}
													>
														Outcomes
													</button>
												</li>
											</ul>
											<div className="tab-content" id="pills-tabContent">
												<div className="tab-pane fade show active" id="pills-coursesinthisbundle" role="tabpanel" aria-labelledby="pills-coursesinthisbundle-tab">
													<div className="row">
														{bundle?.bundle_courses &&
															bundle?.bundle_courses.map((course, index) => {
																return <CourseListCard key={index} course={course} />;
															})}
													</div>
												</div>
												<div className="tab-pane fade" id="pills-outcomes" role="tabpanel" aria-labelledby="pills-outcomes-tab">
													{bundle?.bundle_outcomes?.length > 0 ? (
														bundle?.bundle_outcomes.includes(",") ? (
															bundle?.bundle_outcomes.split(",").map((outcome, index) => {
																return (
																	<li key={index} className="colorblack ps-3">
																		<span>{outcome}</span>
																	</li>
																);
															})
														) : (
															<li className="colorblack list-unstyled ps-3">
																<span>{bundle.bundle_outcomes}</span>
															</li>
														)
													) : (
														<h2 className="colorblack pt-3 text-center">No outcomes added by the administrator!</h2>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						)}
						{isTabletOrMobile && (
							<section className="section">
								<div className="container">
									<div className="row">
										<div className="col-lg-6 d-flex justify-content-center">
											<div className="mb-3 card border-0 shadow" style={{ width: "325px", height: "325px" }}>
												<img className="border5px shadow" src={bundle?.bundle_thumbnail || tempImg2} width="325px" height="325px" alt="Bundle_Image" />
												{bundle?.bundle_discount > 0 && (
													<span className="d-flex justify-content-center align-items-center" id="mydiscountpersin">
														{Math.abs(parseInt(bundle.bundle_discount) - parseFloat(bundle.bundle_discount)) > 0.5
															? parseInt(bundle.bundle_discount) + 1
															: parseInt(bundle.bundle_discount)}
														%
													</span>
												)}
												<button
													onClick={() => {
														if (isAuthenticated()) {
															isInWishlist(bundle.guid, cookies?.user?.[0]?.id)
																? removeBundleFromWishlist({ guid: bundle.guid, userID: cookies?.user?.[0].id })
																: addBundleToWishlist({ bundle, userID: cookies?.user?.[0].id });
														} else {
															return toast("Please login to access wishlist!", {
																type: "warning",
																autoClose: 5000,
																position: "bottom-center",
																hideProgressBar: false,
																pauseOnHover: true,
																pauseOnFocusLoss: true,
															});
														}
													}}
													className="d-flex hvr-icon-pulse border-0 fontsize10 justify-content-center align-items-center mywishlistsin"
												>
													<i
														className={`${
															isInWishlist(bundle.guid, cookies?.user?.[0]?.id) ? "fas fa-2x fa-heart darkbluetext hvr-icon" : "far fa-2x darkbluetext fa-heart"
														}`}
													/>
												</button>
											</div>
										</div>
										<div className="col-lg-6 ps-lg-4">
											<h3 className="colorblack mb-2 mt-3 mt-lg-0 text-center text-lg-start">{bundle.bundle_title}</h3>
											{bundle?.bundle_discount > 0 ? (
												<p className="mb-3 text-center text-lg-start">
													<span className="colorblack fontsize16">
														₹&nbsp;
														{(Math.abs(parseInt(bundle.bundle_sp) - parseFloat(bundle.bundle_sp)) > 0.5
															? parseInt(bundle.bundle_sp) + 1
															: parseInt(bundle.bundle_sp)
														).toLocaleString(undefined, { maximumFractionDigits: 2 })}
													</span>
													&nbsp;&nbsp;
													<span className="fontsize14 notaccepted text-decoration-line-through">
														₹ {bundle?.bundle_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
													</span>
												</p>
											) : (
												<p className="mb-3 text-center text-lg-start">
													<span className="colorblack fontsize16">₹ {bundle?.bundle_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
												</p>
											)}
											{bundleBought ? (
												<>
													<div className="row mb-3">
														<div className="col-12 col-lg-12 pe-lg-3 mb-lg-0 d-flex justify-content-center align-items-center">
															<Link
																to={`/profile/mycourses`}
																className="w-75 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-100 addtocartbutton border5px text-uppercase"
															>
																Go To My Courses
															</Link>
														</div>
														<div className="col-12 col-lg-12 mt-3 mb-lg-0 d-flex justify-content-center align-items-center">
															<a
																href={bundle?.course_brochure}
																className="w-50 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-100 buynowbutton border5px text-uppercase"
															>
																<i className="fas fa-file-download" />
																&nbsp;&nbsp;Brochure
															</a>
														</div>
													</div>
												</>
											) : (
												<>
													<div className="row mb-3">
														<div className="col-12 col-lg-12 mb-lg-0 px-5 d-flex justify-content-center align-items-center">
															{isInCart(bundle?.guid, cookies?.user?.[0].id) ? (
																<>
																	<button
																		className={`${
																			animateButton
																				? "add-to-cart w-50 py-2 px-2 me-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-100 overflow-hidden addtocartbutton border5px text-uppercase is-added"
																				: "add-to-cart w-50 py-2 px-2 me-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-100 overflow-hidden addtocartbutton border5px text-uppercase"
																		}`}
																		onClick={() => {
																			if (isAuthenticated()) {
																				setAnimateButton(true);
																				addBundle({ bundle, userID: cookies?.user?.[0]?.id });
																			} else {
																				handleNotification("Please login to access cart!", "warning");
																				history.push("/signin");
																			}
																		}}
																	>
																		<span>Add To Cart</span>
																		<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
																			<path
																				className={`${animateButton ? "pathatc" : ""}`}
																				strokeDasharray="19.79 19.79"
																				strokeDashoffset="19.79"
																				fill="none"
																				stroke="#fff"
																				strokeWidth={2}
																				strokeLinecap="square"
																				strokeMiterlimit={10}
																				d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
																			/>
																		</svg>
																	</button>
																	<button
																		className="w-50 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-100 buynowbutton border5px text-uppercase"
																		onClick={() => {
																			if (isAuthenticated()) {
																				addSingleCourse(bundle);
																				history.push("/checkout");
																			} else {
																				handleNotification("Please login to buy!", "warning");
																				history.push("/signin");
																			}
																		}}
																	>
																		<span>Buy Now</span>
																	</button>
																</>
															) : (
																<>
																	<button
																		className={`${
																			animateButton
																				? "add-to-cart w-75 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-100 overflow-hidden addtocartbutton border5px text-uppercase is-added"
																				: "add-to-cart w-75 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-100 overflow-hidden addtocartbutton border5px text-uppercase"
																		}`}
																		onClick={() => {
																			history.push("/cart");
																			setAnimateButton(true);
																		}}
																	>
																		<span>Go To Cart</span>
																		<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
																			<path
																				className={`${animateButton ? "pathatc" : ""}`}
																				strokeDasharray="19.79 19.79"
																				strokeDashoffset="19.79"
																				fill="none"
																				stroke="#fff"
																				strokeWidth={2}
																				strokeLinecap="square"
																				strokeMiterlimit={10}
																				d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
																			/>
																		</svg>
																	</button>
																</>
															)}
														</div>
													</div>
													<div className="row mb-3">
														<div className="col-12 col-lg-12 mb-3 mb-lg-0 d-flex justify-content-center align-items-center">
															<a
																href={bundle?.course_brochure}
																className="w-50 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-100 buynowbutton border5px text-uppercase"
															>
																<i className="fas fa-file-download" />
																&nbsp;&nbsp;Brochure
															</a>
														</div>
													</div>
												</>
											)}
										</div>
									</div>
									<div className="row">
										<div className="col-lg-12 mt-3">
											<ul className="nav d-flex justify-content-center nav-pills mb-3 borderbottomcart" id="pills-tab" role="tablist">
												<li className="nav-item" role="presentation">
													<button
														className="nav-link fontsize14 mybtnsame colorblack darkbluetexthover bgcolorwhite text-uppercase active"
														id="pills-coursesinthisbundle-tab"
														data-bs-toggle="pill"
														data-bs-target="#pills-coursesinthisbundle"
														type="button"
														role="tab"
														aria-controls="pills-coursesinthisbundle"
														aria-selected="true"
														style={{ border: "2.5px", borderRadius: "0px", borderRight: "2.5px dotted #cfcfcf" }}
													>
														Courses
													</button>
												</li>
												<li className="nav-item" role="presentation">
													<button
														className="nav-link fontsize14 mybtnsame colorblack darkbluetexthover bgcolorwhite text-uppercase"
														id="pills-outcomes-tab"
														data-bs-toggle="pill"
														data-bs-target="#pills-outcomes"
														type="button"
														role="tab"
														aria-controls="pills-outcomes"
														aria-selected="true"
														style={{ border: "2.5px", borderRadius: "0px" }}
													>
														Outcomes
													</button>
												</li>
											</ul>
											<div className="tab-content" id="pills-tabContent">
												<div className="tab-pane fade show active" id="pills-coursesinthisbundle" role="tabpanel" aria-labelledby="pills-coursesinthisbundle-tab">
													<div className="row">
														{bundle?.bundle_courses &&
															bundle?.bundle_courses.map((course, index) => {
																return <CourseListCard key={index} course={course} />;
															})}
													</div>
												</div>
												<div className="tab-pane fade" id="pills-outcomes" role="tabpanel" aria-labelledby="pills-outcomes-tab">
													{bundle?.bundle_outcomes?.length > 0 ? (
														bundle?.bundle_outcomes.includes(",") ? (
															bundle?.bundle_outcomes.split(",").map((outcome, index) => {
																return (
																	<li key={index} className="colorblack ps-3">
																		<span>{outcome}</span>
																	</li>
																);
															})
														) : (
															<li className="colorblack list-unstyled ps-3">
																<span>{bundle.bundle_outcomes}</span>
															</li>
														)
													) : (
														<h2 className="colorblack pt-3 text-center">No outcomes added by the administrator!</h2>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						)}
					</>
				) : (
					<>
						<DataLoader />
					</>
				)}
			</Base>
		</>
	);
};
export default BundleDetail;
