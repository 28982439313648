import React, { useContext, useEffect, useState } from "react";
import { CoursesContext } from "../Context";
import { ratingCount } from "../data/others/reviews";
import ReactTooltip from "react-tooltip";
const CourseDetailCard = () => {
	const { course } = useContext(CoursesContext);
	const [rateCount, setRateCount] = useState({});
	useEffect(() => {
		ratingCount(course.guid, (data) => {
			setRateCount(data);
		});
	}, []);
	return (
		<>
			<div className="col-lg-6 col-md-6 col-12 px-4 px-lg-0 mt-4 mt-lg-0 order-lg-2 order-1 mb-4">
				<div className="card hovergoup transitionease position-sticky shadow sticky-bar overflow-hidden border5px border-0">
					<div className="card-body content p-0">
						<iframe
							className="card-img-top"
							height={315}
							src={`https://www.youtube.com/embed/${course.course_intro_video?.split("/").slice(-1)[0]}`}
							title="YouTube video player"
							frameBorder={0}
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
						<ul className="list-unstyled p-3 d-flex mb-2 justify-content-between align-items-center mx-4">
							<li className="small" data-tip="Course Experience" data-for="experience">
								<i className="far fa-trophy-alt text-info" /> {course.course_exp}
								<ReactTooltip id="experience" className="fontsize12 px-2" place="top" effect="solid" offset={{ right: 10 }} />
							</li>
							<li className="small" data-tip="Course Duration" data-for="duration">
								<i className="far fa-clock text-warning" /> {course.course_duration}
								<ReactTooltip id="duration" className="fontsize12" place="top" effect="solid" />
							</li>
							<li
								className="small"
								data-tip={`<i class='fas fa-star coloryellow fontsize10'></i> (${rateCount.one})<br><i class='fas fa-star coloryellow fontsize10'></i><i class='fas fa-star coloryellow fontsize10'></i> (${rateCount.two})<br><i class='fas fa-star coloryellow fontsize10'></i><i class='fas fa-star coloryellow fontsize10'></i><i class='fas fa-star coloryellow fontsize10'></i> (${rateCount.three})<br><i class='fas fa-star coloryellow fontsize10'></i><i class='fas fa-star coloryellow fontsize10'></i><i class='fas fa-star coloryellow fontsize10'></i><i class='fas fa-star coloryellow fontsize10'></i> (${rateCount.four})<br><i class='fas fa-star coloryellow fontsize10'></i><i class='fas fa-star coloryellow fontsize10'></i><i class='fas fa-star coloryellow fontsize10'></i><i class='fas fa-star coloryellow fontsize10'></i><i class='fas fa-star coloryellow fontsize10'></i> (${rateCount.five})<br>`}
								data-for="stars"
							>
								<ul className="list-unstyled coloryellow mb-0">
									{(Math.abs(parseInt(course?.course_rating) - parseFloat(course?.course_rating)) > 0.5 ? parseInt(course?.course_rating) + 1 : parseInt(course?.course_rating)) ==
										0 && (
										<>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
										</>
									)}
									{(Math.abs(parseInt(course?.course_rating) - parseFloat(course?.course_rating)) > 0.5 ? parseInt(course?.course_rating) + 1 : parseInt(course?.course_rating)) ==
										1 && (
										<>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
										</>
									)}
									{(Math.abs(parseInt(course?.course_rating) - parseFloat(course?.course_rating)) > 0.5 ? parseInt(course?.course_rating) + 1 : parseInt(course?.course_rating)) ==
										2 && (
										<>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
										</>
									)}
									{(Math.abs(parseInt(course?.course_rating) - parseFloat(course?.course_rating)) > 0.5 ? parseInt(course?.course_rating) + 1 : parseInt(course?.course_rating)) ==
										3 && (
										<>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
										</>
									)}
									{(Math.abs(parseInt(course?.course_rating) - parseFloat(course?.course_rating)) > 0.5 ? parseInt(course?.course_rating) + 1 : parseInt(course?.course_rating)) ==
										4 && (
										<>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="far fa-star" />
											</li>
										</>
									)}
									{(Math.abs(parseInt(course?.course_rating) - parseFloat(course?.course_rating)) > 0.5 ? parseInt(course?.course_rating) + 1 : parseInt(course?.course_rating)) ==
										5 && (
										<>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
											<li className="list-inline-item hvr-icon-grow me-1">
												<i className="fas fa-star hvr-icon" />
											</li>
										</>
									)}
								</ul>
								<ReactTooltip id="stars" html="true" place="top" effect="solid" />
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};
export default CourseDetailCard;
