const BACKEND = "https://backend.internationallunaruniversity.com/";

//AUTHENTICATION APIs
export const Login_API = `${BACKEND}auth/login/`;
export const Logout_API = `${BACKEND}auth/logout/`;
export const Register_API = `${BACKEND}auth/registration/`;
export const EmailVerify_API = `${BACKEND}auth/registration/verify-email/`;
export const PasswordReset_API = `${BACKEND}auth/password/reset/`;
export const PasswordResetConfirm_API = `${BACKEND}auth/password/reset/confirm/`;
export const PasswordChange_API = `${BACKEND}auth/password/change/`;
export const EmailChange_API = `${BACKEND}api/email_change/`;

//Social Authentication APIs
export const GoogleAuth_API = `${BACKEND}auth/google/`;
export const FacebookAuth_API = `${BACKEND}auth/facebook/`;

//USER DETAILS APIs
export const ProfileData_API = `${BACKEND}api/get_profile/`;

//LMS APIs
export const Courses_API = `${BACKEND}api/get_courses/`;
export const Bundles_API = `${BACKEND}api/get_bundles/`;
export const Testimonials_API = `${BACKEND}api/get_testimonials/`;
export const CourseContent_API = `${BACKEND}api/get_content/`;
export const CourseCategory_API = `${BACKEND}api/get_course_categories/`;
export const CourseEnrollments_API = `${BACKEND}api/get_enrollments/`;
export const CouponValidity_API = `${BACKEND}api/check_coupon_validity/`;
export const MyOrders_API = `${BACKEND}api/get_user_orders/`;
export const MarkAsComplete_API = `${BACKEND}api/mark_as_complete/`;
export const SubmitQuiz_API = `${BACKEND}api/submit_quiz/`;
export const ViewQuiz_API = `${BACKEND}api/view_quiz/`;
export const Accomplishments_API = `${BACKEND}api/get_accomplishments/`;
export const Badges_API = `${BACKEND}api/get_user_badge/`;

//Payment APIs
export const PaymentSuccess_API = `${BACKEND}api/razorpay/success/`;
export const Payment_API = `${BACKEND}api/razorpay/order/`;
export const RazorpayKey_API = `${BACKEND}api/get_razorpay_id/`;

//OTHER APIs
export const EmailSubscription_API = `${BACKEND}api/emailsubscriber/`;
export const FeedbackForm_API = `${BACKEND}api/feedbackform/`;
export const ContactUsForm_API = `${BACKEND}api/contactusform/`;
export const ReviewRatingForm_API = `${BACKEND}api/reviewandrating/`;
export const CourseForumForm_API = `${BACKEND}api/courseforum/`;
export const OurTeam_API = `${BACKEND}api/get_team_members/`;
export const ReportABug_API = `${BACKEND}api/reportabug/`;
export const FAQs_API = `${BACKEND}api/get_FAQ/`;
export const TnC_API = `${BACKEND}api/get_TermsandCondition/`;
export const HnC_API = `${BACKEND}api/get_HonorCode/`;
export const PrivP_API = `${BACKEND}api/get_PrivacyPolicy/`;
export const CnR_API = `${BACKEND}api/get_CancellationAndRefund/`;
export const RatingCountAPI = `${BACKEND}api/get_course_ratings/`;
export const InvoiceRequest_API = `${BACKEND}api/request_invoice/`;
export const VerifyCertificate_API = `${BACKEND}api/verify/`;
