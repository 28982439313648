import React from "react";
import { Link } from "react-router-dom";
import tempImg from "../Assets/images/Course_Image.webp";
const ProfileCourseCard = ({ course, toggleClass }) => {
	return (
		<>
			<div
				className={toggleClass ? "col-lg-6 col-12 mt-4 px-2 d-flex justify-content-center" : "col-lg-4 col-12 mt-4 px-3 d-flex justify-content-center"}
				data-aos="flip-right"
				data-aos-duration="1000"
				data-aos-once="true"
			>
				<div className="card transitionease overflow-hidden border5px border-0 shadow" style={{ height: "475px", width: "300px" }}>
					<Link to={`/learn/mycourses/${course?.course?.guid}`}>
						<img src={`https://backend.internationallunaruniversity.com/${course.course.course_thumbnail}` || tempImg} style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }} width="300px" height="300px" alt="Course_Image" />
					</Link>
					<div className="card-body px-3 py-2 bgcolorwhite">
						<h6 className="h-25 text-start pt-1 w-100 mb-4">
							<Link to={`/learn/mycourses/${course?.course?.guid}`} className="colorblack darkbluetexthover">
								{course?.course?.course_title}
							</Link>
						</h6>
						<div className="row">
							<div className="col-lg-12">
								<div className="progress">
									<div
										className="progress-bar gradientblue"
										role="progressbar"
										style={{ width: `${course?.complete_percentage}%` }}
										aria-valuenow={course?.complete_percentage}
										aria-valuemin={0}
										aria-valuemax={100}
									/>
								</div>
								<p className="colorblack text-end fontsize14 mt-1 mb-0">{course?.complete_percentage}% Complete</p>
							</div>
						</div>
						{course?.is_course_completed && (
							<div className="row mt-3">
								<div className="col-lg-12 d-flex justify-content-between">
									<Link to={`/learn/course/${course?.course?.slug}`} className="fw-bold w-75 fontsize12 px-2 py-2 addtocartbutton border5px text-uppercase text-center">
										Leave A Rating
									</Link>
									{course?.is_course_completed && (
										<a
											href={course?.certificate_url}
											target="_blank"
											alt="Download Certificate"
											rel="noopener noreferrer"
											className="ms-2 w-25 fw-bold fontsize12 px-2 py-2 buynowbutton border5px text-uppercase text-center"
										>
											<i className="fas fa-file-certificate" />
										</a>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
export default ProfileCourseCard;
