import Playlist from "../Components/Playlist";
import VideoDetailSection from "../Components/VideoDetailSection";
import VideoPlayer from "../Components/VideoPlayer";
import img from "../Assets/images/Course_Image.webp";
import React, { useEffect, useState } from "react";
import videojs from "video.js";
import { courseContent } from "../data/courses/courses";
import Breadcrumb from "../Components/Breadcrumb";
import DataLoader from "../Components/DataLoaders/DataLoader";
import Base from "../Base";
import { Helmet } from "react-helmet-async";
import { useContext } from "react";
import { BaseContext } from "../Context";
import { useMediaQuery } from "react-responsive";
const CourseView = (props) => {
	const { course_Enrollments } = useContext(BaseContext);
	const videoURLUpdate = (str) => {
		var str1 = "";
		if (str) {
    let baseUrl = "https://backend.internationallunaruniversity.com";
    let str1 = baseUrl + str.toString().replace("input", "input").replace("mp4", "mp4").replace("_", "");
    console.log("#################################################################################");
    console.log(str1);
}


		return str1;
	};
	const [loading, setLoading] = useState(true);
	const [contentID, setContentID] = useState("");
	const [updateForums, setUpdateForums] = useState(false);
	const [updateQ, setUpdateQ] = useState(false);
	useEffect(() => {
		var mounted = true;
		if (mounted) {
			courseContent(props.match.params.id, (data) => {
				props.findCourseContent(data);
				setContentID(props.match.params.id);
				setLoading(false);
			});
		}
		return () => {
			mounted = false;
		};
	}, [updateForums, updateQ]);
	const videoJSOptions = {
		autoplay: false,
		playbackRates: [0.5, 1, 1.25, 1.5, 2],
		controls: true,
		sources: [{
			src: videoURLUpdate(props.courseContent?.[Object.values(props?.courseContent).findIndex((content) => content.video !== null)]?.video?.file),
			type: "video/mp4"
		}],
		poster: props.courseContent?.[Object.values(props?.courseContent).findIndex((content) => content.video !== null)]?.video?.thumbnail || img,
	};
	
	const videoURLSelector = (videoID) => {
		var player = videojs(document.querySelector(".video-js"));
		player.fluid(true);
		player.responsive(true);
		player.src({
			src: videoURLUpdate(props.courseContent?.[videoID]?.video?.file),
			type: "video/mp4"
		});
		player.poster(props.courseContent?.[videoID]?.video?.thumbnail || img);
	};
	
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	return (
		<>
			<Helmet title={`International Lunar University | ${props.courseContent?.[0]?.course?.course_title ? props.courseContent?.[0]?.course?.course_title : ""}`}></Helmet>
			<Base>
				{loading ? (
					<>
						<DataLoader />
					</>
				) : course_Enrollments.find((item) => item.course.guid === props.match.params.id) !== undefined ? (
					<>
						<Breadcrumb title={props.courseContent?.[0]?.course?.course_title} />
						{isDesktopOrLaptop && (
							<section className="section mx-5 px-3">
								<div className="row">
									<div className="col-lg-7 px-4">
										<div className="card transitionease shadow sticky-bar overflow-hidden border5px border-0">
											<video controls="true" autoplay="true" name="media"><source src={`https://backend.internationallunaruniversity.com${props.courseContent?.[Object.values(props?.courseContent).findIndex((content) => content.video !== null)]?.video?.file}`} type="video/mp4"/></video>
										</div>
									</div>
									<div className="col-lg-5">
										<Playlist
											enrollmentID={course_Enrollments.find((item) => item.course.guid === props.match.params.id).id}
											courseContent={props.courseContent}
											videoURLSelector={videoURLSelector}
											videoURLUpdate={videoURLUpdate}
											guid={props.match.params.id}
											handleQuizData={props.handleQuizData}
											contentID={contentID}
											nameInPlaylist={props.courseContent?.[0]?.course?.course_title || "Playlist"}
											setUpdateQ={setUpdateQ}
											updateQ={updateQ}
										/>
									</div>
								</div>
								<div className="row mt-4 pt-2">
									<div className="col-lg-12">
										<VideoDetailSection
											courseContent={props.courseContent}
											course={props.courseContent?.[0]?.course}
											updateForums={updateForums}
											setUpdateForums={setUpdateForums}
										/>
									</div>
								</div>
							</section>
						)}
						{isTabletOrMobile && (
							<section className="section px-3">
								<div className="row">
									<div className="col-lg-12">
										<Playlist
											enrollmentID={course_Enrollments.find((item) => item.course.guid === props.match.params.id).id}
											courseContent={props.courseContent}
											videoURLSelector={videoURLSelector}
											videoURLUpdate={videoURLUpdate}
											guid={props.match.params.id}
											handleQuizData={props.handleQuizData}
											contentID={contentID}
											nameInPlaylist={props.courseContent?.[0]?.course?.course_title || "Playlist"}
											setUpdateQ={setUpdateQ}
											updateQ={updateQ}
										/>
									</div>
								</div>
								<div className="row mt-5">
									<div className="col-lg-12">
										<div className="card transitionease shadow overflow-hidden border5px border-0">
											<video controls="" autoplay="" name="media"><source src={`https://backend.internationallunaruniversity.com${props.courseContent?.[Object.values(props?.courseContent).findIndex((content) => content.video !== null)]?.video?.file}`} type="video/mp4"/></video>
										</div>
									</div>
								</div>
								<div className="row mt-5">
									<div className="col-lg-12">
										<VideoDetailSection
											courseContent={props.courseContent}
											course={props.courseContent?.[0]?.course}
											updateForums={updateForums}
											setUpdateForums={setUpdateForums}
										/>
									</div>
								</div>
							</section>
						)}
					</>
				) : (
					<div>
						<h1 className="section text-center">You Have Not Enrolled In This Course!</h1>
					</div>
				)}
			</Base>
		</>
	);
};
export default CourseView;
