import { CourseContent_API, Courses_API, CourseCategory_API, CourseEnrollments_API, Accomplishments_API } from "../../backend";
export const courses = async (next) => {
	await fetch(Courses_API)
		.then((resp) => {
			return resp.json();
		})
		.then((data) => {
			next(data);
		})
		.catch((err) => console.log(err));
};
export const coursesCategory = async (next) => {
	await fetch(CourseCategory_API)
		.then((resp) => {
			return resp.json();
		})
		.then((data) => {
			next(data);
		})
		.catch((err) => console.log(err));
};
export const singleCourse = async (id, next) => {
	await fetch(`${Courses_API}/${id}`)
		.then((resp) => {
			return resp.json();
		})
		.then((data) => {
			next(data);
		})
		.catch((err) => console.log(err));
};
export const courseContent = async (id, next) => {
	const tokenValue = localStorage.getItem("token").replace(/['"]+/g, "");
	await fetch(`${CourseContent_API}${id}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Token " + tokenValue,
		},
	})
		.then((resp) => {
			return resp.json();
		})
		.then((data) => {
			next(data);
		})
		.catch((err) => console.log(err));
};
export const courseEnrollments = async (next) => {
	const tokenValue = localStorage.getItem("token").replace(/['"]+/g, "");
	await fetch(`${CourseEnrollments_API}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Token " + tokenValue,
		},
	})
		.then((resp) => {
			return resp.json();
		})
		.then((data) => {
			next(data);
		})
		.catch((err) => console.log(err));
};
export const accomplishments = async (next) => {
	const tokenValue = localStorage.getItem("token").replace(/['"]+/g, "");
	await fetch(`${Accomplishments_API}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Token " + tokenValue,
		},
	})
		.then((resp) => {
			return resp.json();
		})
		.then((data) => {
			next(data);
		})
		.catch((err) => console.log(err));
};
