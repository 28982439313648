import Breadcrumb from "../Components/Breadcrumb";
import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../Contexts/CartContext";
import Base from "../Base";
import { coupon, razorpaykey } from "../helpers/ecom/checkout";
import { useHistory } from "react-router-dom";
import CSRFToken from "../CSRFToken";
import { Payment_API, PaymentSuccess_API } from "../backend";
import { Helmet } from "react-helmet-async";
import { Link, Redirect } from "react-router-dom";
import { BaseContext } from "../Context";
import { toast } from "react-toastify";
import DataLoader2 from "../Components/DataLoaders/DataLoader2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { SingleEntityContext } from "../Contexts/SingleEntityContext";
import { profileDataUpdate } from "../data/users/profileData";
const Checkout = () => {
	const history = useHistory();
	const { cookies, setCookie, handleNotification, update_Enrollments, setUpdate_Enrollments } = useContext(BaseContext);
	const { cartItems, allItemsTotalPrice, allItemsTotalDiscount, handleCheckout } = useContext(CartContext);
	const { singleEntityValue, handleSingleCheckout } = useContext(SingleEntityContext);
	const [changeRZKey, setChangeRZKey] = useState("123");
	const [loading, setLoading] = useState(false);
	const [couponApplied, setCouponApplied] = useState(false);
	const [mycoupon, setMyCoupon] = useState("");
	const [billingAddress, setBillingAddress] = useState(cookies?.user?.[0]);
	const [dataObj, setDataObj] = useState({
		courses: [],
		bundles: [],
		coupon_code: "",
		billing_address: JSON.stringify(billingAddress),
	});
	const [couponDiscount, setCouponDiscount] = useState(0);
	useEffect(() => {
		var mounted = true;
		if (mounted) {
			couponDiscount === 0 && setMyCoupon("");
		}
		return () => {
			mounted = false;
		};
	}, [couponDiscount]);
	const checkoutData1 = () => {
		let newCoursesArray = [];
		let newBundlesArray = [];
		if (!!!singleEntityValue?.guid) {
			cartItems.courses.filter((item) => item.userID === cookies?.user?.[0]?.id).map((item) => newCoursesArray.push({ course: item.course.guid }));
			cartItems.bundles.filter((item) => item.userID === cookies?.user?.[0]?.id).map((item) => newBundlesArray.push({ bundle: item.bundle.guid }));
			setDataObj({
				courses: newCoursesArray,
				bundles: newBundlesArray,
				coupon_code: mycoupon,
				billing_address:
					billingAddress.first_name +
					" " +
					billingAddress.last_name +
					", " +
					billingAddress.address_line_1 +
					", " +
					billingAddress.city +
					", " +
					billingAddress.state +
					", " +
					billingAddress.pin_code +
					", " +
					billingAddress.country +
					", " +
					billingAddress.mobile +
					", " +
					billingAddress.email,
			});
		} else {
			singleEntityValue?.course_title && newCoursesArray.push({ course: singleEntityValue.guid });
			singleEntityValue?.bundle_title && newBundlesArray.push({ bundle: singleEntityValue.guid });
			setDataObj({
				courses: newCoursesArray,
				bundles: newBundlesArray,
				coupon_code: mycoupon,
				billing_address:
					billingAddress.first_name +
					" " +
					billingAddress.last_name +
					", " +
					billingAddress.address_line_1 +
					", " +
					billingAddress.city +
					", " +
					billingAddress.state +
					", " +
					billingAddress.pin_code +
					", " +
					billingAddress.country +
					", " +
					billingAddress.mobile +
					", " +
					billingAddress.email,
			});
		}
	};
	useEffect(() => {
		var mounted = true;
		if (mounted) {
			checkoutData1();
		}
		return () => {
			mounted = false;
		};
	}, [mycoupon, billingAddress]);
	useEffect(() => {
		var mounted = true;
		if (mounted) {
			setBillingAddress({
				first_name: cookies.user[0].first_name,
				last_name: cookies.user[0].last_name,
				address_line_1: cookies.user[0].address_line_1,
				city: cookies.user[0].city,
				state: cookies.user[0].state,
				pin_code: cookies.user[0].pin_code,
				country: cookies.user[0].country,
				mobile: cookies.user[0].mobile,
				email: cookies.user[0].email,
			});
		}
		return () => {
			mounted = false;
		};
	}, []);
	const handlePaymentSuccess = async (response) => {
		const tokenValue = localStorage.getItem("token").replace(/['"]+/g, "");
		try {
			await fetch(PaymentSuccess_API, {
				method: "POST",
				body: JSON.stringify(response),
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Token " + tokenValue,
				},
			})
				.then((res) => {
					if (res.ok) {
						history.push("/profile/myorders");
						!!singleEntityValue?.guid ? handleSingleCheckout() : handleCheckout(cookies?.user?.[0]?.id);
						setUpdate_Enrollments(!update_Enrollments);
						setDataObj({
							courses: [],
							bundles: [],
							coupon_code: "",
							billing_address: "",
						});
					}
					return res.json();
				})
				.then((data) => {
					return toast(data?.message, {
						type: "success",
						autoClose: 5000,
						position: "bottom-center",
						hideProgressBar: false,
						pauseOnHover: true,
						pauseOnFocusLoss: true,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		} catch (error) {
			console.log(error);
		}
	};
	function loadScript(src) {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	}
	const showRazorpay = async () => {
		const tokenValue = localStorage.getItem("token").replace(/['"]+/g, "");
		const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
		if (!res) {
			alert("Razorpay SDK failed to load. Are you online?");
			return;
		}
		const data = await fetch(Payment_API, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Token " + tokenValue,
			},
			body: JSON.stringify(dataObj),
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				return data;
			});
		if (!data) {
			alert("Server error. Are you online?");
			return;
		}
		var options = {
			key: changeRZKey.razorpay_id,
			amount: data?.payment?.amount,
			currency: "INR",
			name: "International Lunar University",
			// image: "https://www.spaceonova.space/images/Meta_Image.jpg",
			order_id: data?.payment?.id,
			handler: function (response) {
				handlePaymentSuccess(response);
			},
			prefill: {
				name: `${cookies.user[0].first_name !== "" || cookies.user[0].last_name !== "" ? cookies.user[0].first_name + " " + cookies.user[0].last_name : cookies.user[0].username}`,
				email: cookies.user[0].email,
				contact: `+${cookies.user[0].mobile}`,
			},
			notes: {
				address: cookies.user[0].address_line_1 + ", " + cookies.user[0].city + ", " + cookies.user[0].state + " - " + cookies.user[0].pin_code + ", " + cookies.user[0].country,
			},
			theme: {
				color: "#0007bf",
			},
		};
		var rzp1 = new window.Razorpay(options);
		rzp1.open();
	};
	const CouponValidity = async (e) => {
		e.preventDefault();
		setLoading(true);
		await coupon({ coupon_code: mycoupon }).then((data) => {
			if (data?.cashback) {
				setLoading(false);
				if (singleEntityValue?.guid) {
					if ((data?.discount / 100) * parseFloat(singleEntityValue?.course_sp || singleEntityValue?.bundle_sp).toFixed(2) > data?.cashback) {
						setCouponDiscount(data.cashback);
					} else {
						setCouponDiscount((data?.discount / 100) * parseFloat(singleEntityValue?.course_sp || singleEntityValue?.bundle_sp).toFixed(2));
					}
				} else {
					if ((data?.discount / 100) * parseFloat(allItemsTotalPrice - allItemsTotalDiscount).toFixed(2) > data?.cashback) {
						setCouponDiscount(data.cashback);
					} else {
						setCouponDiscount((data?.discount / 100) * parseFloat(allItemsTotalPrice - allItemsTotalDiscount).toFixed(2));
					}
				}
				setCouponApplied(true);
			} else {
				setLoading(false);
				setCouponApplied(false);
			}
			return toast(data?.message, {
				type: `${data?.cashback ? "success" : "error"}`,
				autoClose: 5000,
				position: "bottom-center",
				hideProgressBar: false,
				pauseOnHover: true,
				pauseOnFocusLoss: true,
			});
		});
	};
	useEffect(() => {
		var mounted = true;
		if (mounted) {
			razorpaykey((data) => {
				setChangeRZKey(data);
			});
		}
		return () => {
			mounted = false;
		};
	}, []);
	const handleProfileUpdate = (event) => {
		event.preventDefault();
		var flag = false;
		const uploadData = new FormData();
		for (const key in cookies?.user?.[0]) {
			for (const k in billingAddress) {
				if (k == key) {
					flag = true;
					break;
				} else {
					flag = false;
				}
			}

			flag ? uploadData.append(key, billingAddress[key]) : key === "image" ? uploadData.set(key, "") : uploadData.append(key, cookies?.user?.[0][key]);
		}
		profileDataUpdate(uploadData, (d) => {
			setCookie("user", d, { path: "/" });
			return toast("Your profile has been updated.", {
				type: "success",
				autoClose: 5000,
				position: "bottom-center",
				hideProgressBar: false,
				pauseOnHover: true,
				pauseOnFocusLoss: true,
			});
		});
	};
	function truncate(str, n) {
		return str?.length > n ? str.substr(0, n - 1) + "..." : str;
	}
	const redirect = (value) => {
		if (value === "/profile/account") {
			handleNotification("Enter Your Billing Address.", "error");
		}
		return <Redirect to={`${value}`} />;
	};
	return cookies.user[0]?.address_line_1 !== " " &&
		cookies.user[0]?.address_line_1 !== "" &&
		cookies.user[0]?.pin_code !== "" &&
		cookies.user[0]?.mobile != " " &&
		cookies.user[0]?.mobile != "" &&
		cookies.user[0]?.city != " " &&
		cookies.user[0]?.city != "" &&
		cookies.user[0]?.state != " " &&
		cookies.user[0]?.state != "" &&
		cookies.user[0]?.country != " " &&
		cookies.user[0]?.country != "" ? (
		!!singleEntityValue?.guid || cartItems.courses.length > 0 || cartItems.bundles.length > 0 ? (
			<>
				<Helmet>
					<title>International Lunar University | Checkout</title>
				</Helmet>
				<Base>
					<Breadcrumb title="Checkout" />
					<section className="section">
						<div className="container">
							<div className="row mx-3">
								<div className="col-lg-6 bgcolorgreyish border5px p-4 h-100">
									<div className="row mb-3">
										<div className="col-lg-12">
											<h2 className="colorblack text-center">Billing Details</h2>
										</div>
									</div>
									<form>
										<div className="row">
											<div className="col-lg-6">
												<div className="mb-4 pb-1">
													<h5 className="colorblack text-start mb-3 fontsize16">First Name</h5>
													<input
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="text"
														placeholder="First Name"
														onChange={(e) => {
															setBillingAddress({
																...billingAddress,
																first_name: e.target.value,
															});
														}}
														value={billingAddress?.first_name}
														required
													/>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="mb-4 pb-1">
													<h5 className="colorblack text-start mb-3 fontsize16">Last Name</h5>
													<input
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="text"
														placeholder="Last Name"
														onChange={(e) => {
															setBillingAddress({
																...billingAddress,
																last_name: e.target.value,
															});
														}}
														value={billingAddress?.last_name}
														required
													/>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-12">
												<div className="mb-4 pb-1">
													<h5 className="colorblack text-start mb-3 fontsize16">Address</h5>
													<input
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="text"
														placeholder="Address"
														onChange={(e) => {
															setBillingAddress({
																...billingAddress,
																address_line_1: e.target.value,
															});
														}}
														value={billingAddress?.address_line_1 === " " ? "" : billingAddress?.address_line_1}
														required
													/>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-12">
												<div className="mb-4 pb-1">
													<h5 className="colorblack text-start mb-3 fontsize16">Pincode</h5>
													<input
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="number"
														placeholder="Pincode"
														onChange={(e) => {
															setBillingAddress({
																...billingAddress,
																pin_code: e.target.value,
															});
														}}
														value={billingAddress?.pin_code}
														required
													/>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-12">
												<div className="mb-4 pb-1">
													<h5 className="colorblack text-start mb-3 fontsize16">City</h5>
													<input
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="text"
														placeholder="Town / City"
														onChange={(e) => {
															setBillingAddress({
																...billingAddress,
																city: e.target.value,
															});
														}}
														value={billingAddress?.city === " " ? "" : billingAddress?.city}
														required
													/>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-6">
												<div className="mb-4 pb-1">
													<h5 className="colorblack text-start mb-3 fontsize16">State</h5>
													<input
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="text"
														placeholder="State"
														onChange={(e) => {
															setBillingAddress({
																...billingAddress,
																state: e.target.value,
															});
														}}
														value={billingAddress?.state === " " ? "" : billingAddress?.state}
														required
													/>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="mb-4 pb-1">
													<h5 className="colorblack text-start mb-3 fontsize16">Country</h5>
													<input
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="text"
														placeholder="Country"
														onChange={(e) => {
															setBillingAddress({
																...billingAddress,
																country: e.target.value,
															});
														}}
														value={billingAddress?.country === " " ? "" : billingAddress?.country}
														required
													/>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-6">
												<div className="mb-4 mb-lg-0">
													<h5 className="colorblack text-start mb-3 fontsize16">Mobile Number</h5>
													<PhoneInput
														inputClass="input100 w-100 shadow-none border5px pe-5 border-0 colorblack"
														buttonClass="border5px border-0 ps-2 colorblack bgcolorwhite"
														inputStyle={{ height: "50px" }}
														specialLabel={""}
														country={"in"}
														value={billingAddress?.mobile || ""}
														onChange={(value) => {
															setBillingAddress({
																...billingAddress,
																mobile: value,
															});
														}}
													/>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="">
													<h5 className="colorblack text-start mb-3 fontsize16">Email</h5>
													<input
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="email"
														placeholder="Email"
														required
														onChange={(e) => {
															setBillingAddress({
																...billingAddress,
																email: e.target.value.toLowerCase(),
															});
														}}
														value={billingAddress?.email.toLowerCase()}
													/>
												</div>
											</div>
										</div>
										<div className="row text-center">
											<div className="col-lg-12 mt-4">
												<button
													onClick={(e) => {
														handleProfileUpdate(e);
													}}
													className="fw-bold py-2 px-3 fontsize16 h-100 w-100 buynowbutton border5px text-uppercase"
												>
													Update Billing Address
												</button>
											</div>
										</div>
									</form>
								</div>
								<div className="col-lg-1"></div>
								<div className="col-lg-5">
									<div className="row position-sticky sticky-bar">
										<div className="col-lg-12 bgcolorgreyish border5px p-4 h-100 mt-4 mt-lg-0">
											<h2 className="colorblack mb-3 text-center">Your Order Summary</h2>
											{!!!singleEntityValue?.guid ? (
												<>
													{cartItems.courses.filter((item) => item.userID === cookies?.user?.[0]?.id).length > 0 && (
														<>
															<h5 className="colorblack mb-2 text-start">Courses</h5>
															<ul className="list-unstyled colorblack fontsize14">
																{cartItems.courses
																	.filter((item) => item.userID === cookies?.user?.[0]?.id)
																	.map((item, index) => {
																		const { course } = item;
																		return (
																			<li
																				key={index}
																				className="d-flex justify-content-between"
																				data-bs-toggle="tooltip"
																				data-bs-placement="top"
																				title={course?.course_title}
																			>
																				<Link to={`/learn/course/${course?.slug}`} className="colorblack darkbluetexthover">
																					{truncate(course?.course_title, 25)}
																				</Link>
																				<span>
																					₹&nbsp;
																					{(Math.abs(parseInt(course?.course_sp) - parseFloat(course?.course_sp)) > 0.5
																						? parseInt(course?.course_sp) + 1
																						: parseInt(course?.course_sp)
																					).toLocaleString(undefined, { maximumFractionDigits: 2 })}
																				</span>
																			</li>
																		);
																	})}
															</ul>
														</>
													)}
													{cartItems.bundles.filter((item) => item.userID === cookies?.user?.[0]?.id).length > 0 && (
														<>
															<h5 className="colorblack mb-2 text-start">Bundles</h5>
															<ul className="list-unstyled colorblack fontsize14">
																{cartItems.bundles
																	.filter((item) => item.userID === cookies?.user?.[0]?.id)
																	.map((item, index) => {
																		const { bundle } = item;
																		return (
																			<li
																				key={index}
																				className="d-flex justify-content-between"
																				data-bs-toggle="tooltip"
																				data-bs-placement="top"
																				title={bundle?.bundle_title}
																			>
																				<Link to={`/learn/course/${bundle?.slug}`} className="colorblack darkbluetexthover">
																					{truncate(bundle?.bundle_title, 25)}
																				</Link>
																				<span>
																					₹&nbsp;
																					{(Math.abs(parseInt(bundle?.bundle_sp) - parseFloat(bundle?.bundle_sp)) > 0.5
																						? parseInt(bundle?.bundle_sp) + 1
																						: parseInt(bundle?.bundle_sp)
																					).toLocaleString(undefined, { maximumFractionDigits: 2 })}
																				</span>
																			</li>
																		);
																	})}
															</ul>
														</>
													)}
												</>
											) : (
												<>
													<h5 className="colorblack mb-2 text-start">{singleEntityValue?.course_title ? "Course" : "Bundle"}</h5>
													<ul className="list-unstyled colorblack fontsize14">
														{
															<li className="d-flex justify-content-between" data-bs-toggle="tooltip" data-bs-placement="top" title={singleEntityValue?.course_title}>
																<Link to={`/learn/course/${singleEntityValue?.slug}`} className="colorblack darkbluetexthover">
																	{truncate(singleEntityValue?.course_title || singleEntityValue?.bundle_title, 25)}
																</Link>
																<span>
																	₹&nbsp;
																	{(Math.abs(
																		parseInt(singleEntityValue?.course_sp || singleEntityValue?.bundle_sp) -
																			parseFloat(singleEntityValue?.course_sp || singleEntityValue?.bundle_sp)
																	) > 0.5
																		? parseInt(singleEntityValue?.course_sp || singleEntityValue?.bundle_sp) + 1
																		: parseInt(singleEntityValue?.course_sp || singleEntityValue?.bundle_sp)
																	).toLocaleString(undefined, { maximumFractionDigits: 2 })}
																</span>
															</li>
														}
													</ul>
												</>
											)}
											{!!!singleEntityValue?.guid ? (
												<div className="d-flex py-3 justify-content-between align-items-center bordertopcheckout">
													<h3 className="mb-0 colorblack">Subtotal</h3>
													<p className="mb-0 colorblack">
														₹ {(parseFloat(allItemsTotalPrice).toFixed(2) - allItemsTotalDiscount).toLocaleString(undefined, { maximumFractionDigits: 2 })}
													</p>
												</div>
											) : (
												<div className="d-flex py-3 justify-content-between align-items-center bordertopcheckout">
													<h3 className="mb-0 colorblack">Subtotal</h3>
													<p className="mb-0 colorblack">
														₹ {(singleEntityValue?.course_sp || singleEntityValue?.bundle_sp).toLocaleString(undefined, { maximumFractionDigits: 2 })}
													</p>
												</div>
											)}
											{couponApplied ? (
												<div className="py-3 bordertopcheckout">
													<h3 className="mb-0 colorblack">Discount</h3>
													<div className="d-flex justify-content-between align-items-center">
														<p className="mt-1 mb-0 colorblack">Coupon Code "{mycoupon}" applied!</p>
														{couponDiscount > 0 && (
															<button
																className="ms-2 colorblack border-0 border5px bgcolorwhite"
																onClick={() => {
																	setCouponDiscount(0);
																	setCouponApplied(false);
																}}
																style={{ width: 25, height: 25 }}
															>
																<i className="fas fa-times" />
															</button>
														)}
														<p className="mb-0 accepted">-&nbsp;&nbsp;&nbsp;₹ {couponDiscount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
													</div>
												</div>
											) : (
												<div className="d-flex py-3 justify-content-between align-items-center bordertopcheckout">
													<h3 className="mb-0 colorblack">Discount</h3>
													<p className="mb-0 accepted">-&nbsp;&nbsp;&nbsp;₹ {couponDiscount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
												</div>
											)}
											<div className="d-flex py-3 justify-content-between align-items-center bordertopcheckout">
												<h3 className="mb-0 colorblack">Total</h3>
												{!!!singleEntityValue?.guid ? (
													<p className="mb-0 colorblack">
														₹&nbsp;
														{Math.abs(parseFloat(allItemsTotalPrice).toFixed(2) - allItemsTotalDiscount - couponDiscount).toLocaleString(undefined, {
															maximumFractionDigits: 2,
														})}
													</p>
												) : (
													<p className="mb-0 colorblack">
														₹&nbsp;
														{Math.abs((singleEntityValue?.course_sp || singleEntityValue?.bundle_sp) - couponDiscount).toLocaleString(undefined, {
															maximumFractionDigits: 2,
														})}
													</p>
												)}
											</div>
											<div className="py-3 bordertopcheckout">
												<div className="row d-flex justify-content-center align-items-center">
													<h4 className="mb-3 text-center colorblack">Discount Code</h4>
													<form action="#">
														<CSRFToken />
														<div className="row">
															<div className="col-lg-9 col-7">
																<input
																	className="input100 w-100 border5px ps-3 border-0 colorblack"
																	type="text"
																	placeholder="Coupon Code"
																	value={mycoupon}
																	onChange={(e) => {
																		setMyCoupon(e.target.value);
																	}}
																	required
																/>
															</div>
															<div className="col-lg-3 col-5">
																<button
																	className="fw-bold py-2 px-3 fontsize16 w-100 h-100 buynowbutton border5px text-uppercase"
																	onClick={(e) => {
																		CouponValidity(e);
																	}}
																	disabled={loading ? true : false}
																>
																	{loading ? <DataLoader2 loaderSize={15} loaderType="ScaleLoader" loaderColor="#0007bf" /> : "Apply"}
																</button>
															</div>
														</div>
													</form>
												</div>
											</div>
											<button className="mt-2 fw-bold py-2 px-3 fontsize16 w-100 buynowbutton border5px text-uppercase" onClick={showRazorpay}>
												PAY
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Base>
			</>
		) : (
			redirect("")
		)
	) : (
		redirect("/profile/account")
	);
};
export default Checkout;
