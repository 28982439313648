import { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { CartContext } from "../Contexts/CartContext";
import { BaseContext } from "../Context";
import { isAuthenticated } from "../helpers/auth/authentication";
import tempImg from "../Assets/images/Course_Image.webp";
import { SingleEntityContext } from "../Contexts/SingleEntityContext";
import DataLoader from "./DataLoaders/DataLoader";
import { WishlistContext } from "../Contexts/WishlistContext";
import { toast } from "react-toastify";
const BundlesListCard = ({ bundle }) => {
	const history = useHistory();
	const { course_Enrollments, cookies, handleNotification } = useContext(BaseContext);
	const { addBundle, cartItems } = useContext(CartContext);
	const { addSingleCourse } = useContext(SingleEntityContext);
	const { addBundleToWishlist, removeBundleFromWishlist, wishlistItems } = useContext(WishlistContext);
	const [loading, setLoading] = useState(true);
	const isInCart = (guid, userID) => {
		return !!!cartItems.bundles.find((item) => item.bundle.guid === guid && item.userID === userID);
	};
	const isInWishlist = (guid, userID) => {
		return wishlistItems.bundles.find((item) => item.bundle.guid === guid && item.userID === userID);
	};
	const [animateButton, setAnimateButton] = useState(false);
	useEffect(() => {
		const timer = setTimeout(() => setAnimateButton(false), 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [animateButton]);
	const [bundleBought, setbundleBought] = useState(false);
	useEffect(() => {
		var mounted = true;
		var counter = 0;
		setLoading(true);
		if (mounted) {
			!course_Enrollments.error &&
				course_Enrollments &&
				bundle.bundle_courses.forEach((value) => {
					if (course_Enrollments.find((val) => val.course.guid === value.guid)) {
						counter++;
					}
				});
			if (counter === bundle.bundle_courses.length) {
				setbundleBought(true);
				setLoading(false);
			} else {
				setbundleBought(false);
			}
		}
		setLoading(false);
		return () => {
			mounted = false;
		};
	}, [course_Enrollments]);
	return (
		<>
			{!loading ? (
				<div className="col-lg-4 col-12 mt-4 px-4 d-flex justify-content-center" data-aos="flip-left" data-aos-duration="1000" data-aos-once="true">
					<div className="card transitionease border-0 shadow hovergoup" style={{ height: "445px", width: "325px" }}>
						<Link to={`/bundles/${bundle.slug}`}>
							<img src={bundle?.bundle_thumbnail || tempImg} style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }} width="325px" height="325px" alt="Bundle_Image" />
						</Link>
						{bundle?.bundle_discount > 0 && (
							<span className="d-flex justify-content-center align-items-center" id="mydiscountper">
								{Math.abs(parseInt(bundle?.bundle_discount) - parseFloat(bundle?.bundle_discount)) > 0.5 ? parseInt(bundle?.bundle_discount) + 1 : parseInt(bundle?.bundle_discount)}%
							</span>
						)}
						{!bundleBought && (
							<button
								className="d-flex hvr-icon-grow border-0 justify-content-center align-items-center mywishlist"
								onClick={() => {
									if (isAuthenticated()) {
										isInWishlist(bundle.guid, cookies?.user?.[0]?.id)
											? removeBundleFromWishlist({ guid: bundle.guid, userID: cookies?.user?.[0].id })
											: addBundleToWishlist({ bundle, userID: cookies?.user?.[0].id });
									} else {
										return toast("Please login to access wishlist!", {
											type: "warning",
											autoClose: 5000,
											position: "bottom-center",
											hideProgressBar: false,
											pauseOnHover: true,
											pauseOnFocusLoss: true,
										});
									}
								}}
							>
								<i className={`${isInWishlist(bundle.guid, cookies?.user?.[0]?.id) ? "fas fa-heart darkbluetext hvr-icon" : "far darkbluetext fa-heart"}`} />
							</button>
						)}
						<div className="text-center" style={{ borderBottomRightRadius: "5px", borderBottomLeftRadius: "5px" }}>
							<div className="row my-1">
								<div className="col">
									<h5 className="mb-0">
										<Link to={`/bundles/${bundle.slug}`} className="colorblack darkbluetexthover">
											{bundle.bundle_title}
										</Link>
									</h5>
								</div>
							</div>
							<div className="row my-1">
								<div className="col">
									{bundle?.bundle_discount > 0 ? (
										<p className="mb-0">
											<span className="fontsize16 darkbluetext">
												₹&nbsp;
												{(Math.abs(parseInt(bundle?.bundle_sp) - parseFloat(bundle?.bundle_sp)) > 0.5
													? parseInt(bundle?.bundle_sp) + 1
													: parseInt(bundle?.bundle_sp)
												).toLocaleString(undefined, { maximumFractionDigits: 2 })}
											</span>
											&nbsp;&nbsp;
											<span className="fontsize14" id="mydiscountpri">
												₹&nbsp;
												{(bundle?.bundle_mrp).toLocaleString(undefined, { maximumFractionDigits: 2 })}
											</span>
										</p>
									) : (
										<p className="mb-0">
											<span className="fontsize16 darkbluetext">₹ {(bundle?.bundle_mrp).toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
										</p>
									)}
								</div>
							</div>
							<div className="row my-1">
								<div className="d-flex justify-content-center align-items-center">
									{bundleBought ? (
										<Link
											to={`/profile/mycourses`}
											className="w-75 py-3 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold addtocartbutton h-75 border5px text-uppercase"
										>
											Go To My Courses
										</Link>
									) : isInCart(bundle.guid, cookies?.user?.[0]?.id) ? (
										<>
											<button
												className={`${
													animateButton
														? "add-to-cart w-50 ms-3 py-3 px-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-75 overflow-hidden addtocartbutton border5px text-uppercase is-added"
														: "add-to-cart w-50 ms-3 py-3 px-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-75 overflow-hidden addtocartbutton border5px text-uppercase"
												}`}
												onClick={() => {
													if (isAuthenticated()) {
														setAnimateButton(true);
														addBundle({ bundle, userID: cookies?.user?.[0]?.id });
													} else {
														handleNotification("Please login to access cart!", "warning");
														history.push("/signin");
													}
												}}
											>
												<span>Add To Cart</span>
												<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
													<path
														className={`${animateButton ? "pathatc" : ""}`}
														strokeDasharray="19.79 19.79"
														strokeDashoffset="19.79"
														fill="none"
														stroke="#fff"
														strokeWidth={2}
														strokeLinecap="square"
														strokeMiterlimit={10}
														d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
													/>
												</svg>
											</button>
											<button
												className="w-50 mx-3 py-3 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-75 buynowbutton border5px text-uppercase"
												onClick={() => {
													if (isAuthenticated()) {
														addSingleCourse(bundle);
														history.push("/checkout");
													} else {
														handleNotification("Please login to buy!", "warning");
														history.push("/signin");
													}
												}}
											>
												<span>Buy Now</span>
											</button>
										</>
									) : (
										<>
											<button
												className={`${
													animateButton
														? "add-to-cart w-50 mx-3 py-3 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-75 overflow-hidden addtocartbutton border5px text-uppercase is-added"
														: "add-to-cart w-50 mx-3 py-3 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-75 overflow-hidden addtocartbutton border5px text-uppercase"
												}`}
												onClick={() => {
													history.push("/cart");
													setAnimateButton(true);
												}}
											>
												<span>Go To Cart</span>
												<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
													<path
														className={`${animateButton ? "pathatc" : ""}`}
														strokeDasharray="19.79 19.79"
														strokeDashoffset="19.79"
														fill="none"
														stroke="#fff"
														strokeWidth={2}
														strokeLinecap="square"
														strokeMiterlimit={10}
														d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
													/>
												</svg>
											</button>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<DataLoader />
			)}
		</>
	);
};
export default BundlesListCard;
