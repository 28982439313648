import React, { useState } from "react";
import CSRFToken from "../../CSRFToken";
import { toast } from "react-toastify";
import { PasswordReset_API } from "../../backend";
import Base from "../../Base";
import { Helmet } from "react-helmet-async";
import DataLoader2 from "../../Components/DataLoaders/DataLoader2";
import Breadcrumb from "../../Components/Breadcrumb";
import { Link } from "react-router-dom";
const PasswordReset = () => {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const forgotPassword = (e) => {
		e.preventDefault();
		setLoading(true);
		return fetch(PasswordReset_API, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email: email.toLowerCase(),
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data?.detail) {
					setEmail("");
					setLoading(false);
					return toast(data.detail, {
						type: "info",
						autoClose: 5000,
						position: "bottom-center",
						hideProgressBar: false,
						pauseOnHover: true,
						pauseOnFocusLoss: true,
					});
				} else {
					setLoading(false);
					if (data?.email?.[0]) {
						setEmail("");
						return toast(`email: ${data.email[0]}`, {
							type: "error",
							autoClose: 5000,
							position: "bottom-center",
							hideProgressBar: false,
							pauseOnHover: true,
							pauseOnFocusLoss: true,
						});
					}
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};
	return (
		<>
			<Helmet>
				<title>International Lunar University | Password Reset</title>
			</Helmet>
			<Base>
				<Breadcrumb title="Password Reset" />
				<section className="section">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 col-md-6">
								<div className="me-lg-5 mb-5 mb-lg-0">
									<img src="images/SVGs/Forgot_Password.svg" className="loginsvg" alt="Reset_Password" />
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="card mx-2 bgcolorgreyish border-0 border5px p-4">
									<div className="card-body">
										<h2 className="card-title colorblack pb-2 text-center">Reset Password</h2>
										<form className="mt-2">
											<CSRFToken />
											<div className="row">
												<div className="col-lg-12">
													<p className="colorblack text-center">Please enter your email address. You will receive a link to create a new password via email.</p>
													<div className="position-relative mb-4">
														<input
															className="input100 w-100 border5px border-0 colorblack"
															type="email"
															placeholder="Registered Email"
															value={email.toLowerCase()}
															onChange={(e) => {
																setEmail(e.target.value);
															}}
															required
														/>
														<span className="focus-input100" />
														<span className="symbol-input100 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className="far fa-envelope" />
															</span>
														</span>
													</div>
												</div>
												<div className="col-lg-12 mb-0">
													<div className="d-grid">
														<button
															disabled={loading ? true : false}
															onClick={forgotPassword}
															className="fw-bold py-2 px-3 fontsize16 buynowbutton border5px text-uppercase d-inline-block"
														>
															{loading ? <DataLoader2 loaderSize={15} loaderType="ScaleLoader" loaderColor="#0007bf" /> : "Send"}
														</button>
													</div>
												</div>
												<div className="col-12 text-center">
													<p className="mb-0 mt-4">
														<span className="colorblack me-2">Remember your password ?</span>
														<Link to="/signin" className="colorblack cursorpointer">
															Sign In
														</Link>
													</p>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Base>
		</>
	);
};
export default PasswordReset;
