import React, { useState, useRef } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import Base from "../Base";
import { Helmet } from "react-helmet-async";
import { verifyCert } from "../helpers/others/verifyCertificate";
import DataLoader2 from "../Components/DataLoaders/DataLoader2";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
const VerifyCert = (props) => {
	const certiClick = useRef(null);
	const [dataLoading, setdDataLoading] = useState(false);
	const location = useLocation();
	const [certID, setCertID] = useState(props.match.params.id);
	const [url, setURL] = useState("");
	const certificate = async (e) => {
		e.preventDefault();
		setdDataLoading(true);
		await verifyCert(certID).then((data) => {
			if (data.status === "verified") {
				setdDataLoading(false);
				setURL(data.url);
				certiClick.current.click();
				return toast("Certificate is authentic.", { type: "success", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
			}
			if (data.status === "unverified") {
				setCertID("");
				setURL("");
				setdDataLoading(false);
				return toast("Invalid certificate ID.", { type: "error", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
			} else {
				setdDataLoading(false);
			}
		});
	};
	return (
		<>
			<Helmet>
				<title>International Lunar University | Verify Certificate</title>
			</Helmet>
			<Base>
				<Breadcrumb title="Verify Certificate" />
				<div className="container" style={{ width: "350px" }}>
					<form className="bgcolorgreyish border-0 border5px my-4 p-4">
						<div className="text-center w-100 mb-4">
							<img src="images/SVGs/Verify_Certificate.svg" alt="Verify_Email" className="loginsvg" height="200px" />
						</div>
						<div className="text-center w-100">
							<p className="colorblack mb-4">Please enter the Certificate ID that you want to verify.</p>
							<div className="position-relative mb-4">
								<input
									className="input100 w-100 border5px border-0 colorblack"
									type="text"
									placeholder="Certificate ID"
									value={certID}
									onChange={(e) => {
										setCertID(e.target.value);
									}}
									required
								/>
								<span className="focus-input100" />
								<span className="symbol-input100 d-flex align-items-center position-absolute colorblack h-100">
									<span>
										<i className="far fa-file-certificate" />
									</span>
								</span>
							</div>
						</div>
						<div className="text-center mt-2">
							<button
								type="submit"
								className="fw-bold py-3 px-3 buynowbutton border5px text-uppercase d-inline-block"
								onClick={(e) => {
									certificate(e);
								}}
								disabled={dataLoading ? true : false}
							>
								{dataLoading ? <DataLoader2 loaderSize={15} loaderType="ScaleLoader" loaderColor="#0007bf" /> : "Verify"}
							</button>
							<a className="d-none" href={url} ref={certiClick} download="Certificate.jpg">
								DOWNLOAD
							</a>
						</div>
					</form>
				</div>
			</Base>
		</>
	);
};
export default VerifyCert;
