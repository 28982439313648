const Storage = (orderdetailItems) => {
	localStorage.setItem("orderdetail", JSON.stringify(orderdetailItems.length > 0 ? orderdetailItems : []));
};
export const setStorage = (orderdetailItems) => {
	Storage(orderdetailItems);
};
export const OrderDetailReducer = (state, action) => {
	switch (action.type) {
		case "ADD_ORDER_DETAIL":
			if (!state.orderdetailItems.find((item) => item.order_id === action.payload.order.order_id && item.userID === action.payload.userID)) {
				state.orderdetailItems.push({
					courses: action.payload.order.ordered_courses,
					order_id: action.payload.order.order_id,
					userID: action.payload.userID,
				});
			}
			return {
				...state,
				...setStorage(state.orderdetailItems),
				orderdetailItems: [...state.orderdetailItems],
			};
		default:
			return state;
	}
};
