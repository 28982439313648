import CourseListCard from "./CourseListCard";
import { courses } from "../data/courses/courses";
import { useContext, useEffect } from "react";
import { CoursesContext } from "../Context";
const CourseList = ({ handleHomeCourses, home_courses, toggleSpacings }) => {
	const { allCourses, handleAllCourses } = useContext(CoursesContext);
	useEffect(() => {
		courses((data) => {
			handleAllCourses(data);
		});
	}, []);
	useEffect(() => {
		handleHomeCourses(allCourses.filter((course) => course?.course_rating > 4).slice(0, 6));
	}, [allCourses]);
	return (
		<>
			<section className={toggleSpacings ? "section pt-0 overflow-hidden" : "section pt-0 overflow-hidden"}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12">
							<div className="text-center my-2 py-4" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
								<h2 className="mt-5 colorblack">Popular Courses</h2>
							</div>
						</div>
					</div>
					<div className="row">
						{home_courses.map((course, index) => {
							return <CourseListCard key={index} course={course} />;
						})}
					</div>
				</div>
			</section>
		</>
	);
};
export default CourseList;
