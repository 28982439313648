import React, { createContext, useReducer } from "react";
import { QuizResponseReducer, sum } from "./QuizResponseReducer";
export const QuizResponseContext = createContext();
const storage = sessionStorage.getItem("quizResponse") ? JSON.parse(sessionStorage.getItem("quizResponse")) : [];
const initialState = {
	quizResponseValue: storage,
	...sum(storage),
};
const QuizResponseProvider = ({ children }) => {
	const [state, dispatch] = useReducer(QuizResponseReducer, initialState);

	//this one is for MSQ response only.
	const addResponse = (payload) => {
		dispatch({ type: "ADD_RESPONSE", payload });
	};

	//this one is for MCQ and NAT
	const addResponse2 = (payload) => {
		dispatch({ type: "ADD_RESPONSE_2", payload });
	};
	// const handleSingleCheckout = () => {
	// 	dispatch({ type: "REMOVE" });
	// };

	const contextValues = {
		addResponse,
		addResponse2,
		...state,
	};
	return <QuizResponseContext.Provider value={contextValues}>{children}</QuizResponseContext.Provider>;
};
export default QuizResponseProvider;
