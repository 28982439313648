import { Link } from "react-router-dom";
const Info1 = () => {
	return (
		<>
			<section className="section my-0 overflow-hidden">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 pt-3 pt-lg-0 pe-lg-5 text-center" data-aos="fade-right" data-aos-duration="1000" data-aos-once="true">
							<svg className="w-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450.37966 275.9011">
								<defs>
									<pattern id="pattern1" height="100%" width="100%" patternContentUnits="objectBoundingBox">
										<image height={1} width={1} preserveAspectRatio="none" xlinkHref="images/Space_01.webp" />
									</pattern>
								</defs>
								<path
									d="M404.97164,112.88475c31.04392,28.8418,55.0713,65.5493,41.5596,104.2597-7.044,20.1826-25.3555,31.0567-45.8369,34.8926-70.41509,13.1875-136.79689-17.1426-206.19829-14.5488-33.8203,1.2636-58.7822,23.205-89.9687,33.08-37.3496,11.8243-88.1421,5.9356-100.7959-37.5849-12.5293-43.1045,9.1132-84.0015,26.2124-122.2061,14.1054-31.5141,38.976-69.4477,66.6836-90.7314,69.5073-53.3755,187.852,12.7383,248.3549,48.7998C362.56145,79.32315,385.19234,94.50825,404.97164,112.88475Z"
									fill="url(#pattern1)"
								/>
							</svg>
						</div>
						<div className="col-lg-6 mt-4 mt-lg-0 pt-4 pt-lg-0 ps-3" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true">
							<div className="ms-lg-4">
								<div className="mb-4">
									<h2 className="colorblack mb-4 text-center text-lg-start">
										Who are We? <br /> Your Partner is Space ;)
									</h2>
									<p className="colorblack text-center text-lg-start mb-0">
										International Lunar University is a space education and research based company with a vision to establish a space ecosystem, where anyone can LEARN, CREATE, INNOVATE and IMPACT.
										<br />
										<br />
										International Lunar University specialises in the fields of Space Robotics, Astrobiology, Computational Astronomy and Rocketry and have impacted more than 30k+ enthusiasts so far
										and enabled them to pursue careers in the field of space sciences and exploration.
									</p>
								</div>
								<div className="mt-4 d-flex justify-content-lg-start justify-content-center pt-2">
									<Link to="/learn/all" className="mybtnsame darkbluetext border5px text-uppercase bordercolordarkblue gradientbluehover">
										Learn Now &nbsp;
										<i className="fas fa-video" />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export default Info1;
