import "./style.scss";
import React from "react";
import Base from "../../Base";
import { Helmet } from "react-helmet-async";
const NotFound404 = () => {
	return (
		<>
			<Helmet>
				<title>International Lunar University | Error 404</title>
			</Helmet>
			<Base>
				<div className="fakediv">
					<div className="bg-purple" style={{ backgroundImage: "url(/images/404_Error/404_BG.png)" }}>
						<div className="stars" style={{ backgroundImage: "url(/images/404_Error/Stars.svg)" }}>
							<div className="central-body">
								<img className="image-404" src={"images/404_Error/404.svg"} width="300px" alt="404_Image" />
							</div>
							<div className="objects">
								<img className="object_rocket" src={"images/404_Error/Rocket.svg"} width="40px" alt="404_Image" />
								<div className="earth-moon">
									<img className="object_earth" src={"images/404_Error/Earth.svg"} width="100px" alt="404_Image" />
									<img className="object_moon" src={"images/404_Error/Moon.svg"} width="80px" alt="404_Image" />
								</div>
								<div className="box_astronaut">
									<img className="object_astronaut" src={"images/404_Error/Astronaut.svg"} width="140px" alt="404_Image" />
								</div>
							</div>
							<div className="glowing_stars">
								<div className="star" />
								<div className="star" />
								<div className="star" />
								<div className="star" />
								<div className="star" />
							</div>
						</div>
					</div>
				</div>
			</Base>
		</>
	);
};
export default NotFound404;
