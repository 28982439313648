import Breadcrumb from "../Components/Breadcrumb";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";
import TestimonialsList from "../Components/TestimonialsList";
import tempImg from "../Assets/images/User_Image.webp";
import Base from "../Base";
const AboutUs = ({ ourTeam }) => {
	const location = useLocation();
	useEffect(() => {
		if (location.hash) {
			let elem = document.getElementById(location.hash.slice(1));
			if (elem) {
				elem.scrollIntoView({ behavior: "smooth" });
			}
		} else {
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		}
	}, [location]);
	const [toggleAOS, setToggleAOS] = useState(false);
	useEffect(() => {
		var mounted = true;
		if (mounted) {
			AOS.init();
		}
		return () => {
			mounted = false;
		};
	}, []);
	useEffect(() => {
		var mounted = true;
		if (mounted) {
			AOS.refresh();
		}
		return () => {
			mounted = false;
		};
	}, [toggleAOS]);
	const [countUpNum1, setCountUpNum1] = useState(0);
	const [countUpNum2, setCountUpNum2] = useState(0);
	const [countUpNum3, setCountUpNum3] = useState(0);
	return (
		<>
			<Helmet>
				<title>International Lunar University | About Us</title>
			</Helmet>
			<Base>
				<Breadcrumb title="About Us" />
				<section className="section overflow-hidden">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="row">
									<div className="col-lg-5 px-3 border5px d-flex justify-content-center" data-aos="fade-right" data-aos-duration="1000" data-aos-once="true">
										<img className="shadow border5px" src="images/Space_02.webp" alt="Our_Team" width="100%" />
									</div>
									<div className="aboutus col-lg-7 colorblue mt-4 pt-2 pt-lg-0 mt-lg-0 text-center" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true">
										<h1 className="mb-4">Welcome to International Lunar University</h1>
										<p className="mb-2">
											<b>International Lunar University</b>
										</p>
										<p className="mb-2">
											We have strict <b>quality control</b> measures in place. For us, your <b>satisfaction and happiness</b> is utmost important.
										</p>
										<div className="mt-4 d-flex justify-content-center">
											<Link to="/learn/all" className="mybtnsame fontsize16 colorblue bgyellow bglightblue border5px border-0 text-uppercase">
												Learn Now &nbsp;
												<i className="fas fa-video" />
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section mb-0 overflow-hidden">
					<div className="container">
						<div className="row mx-3 mt-3 mx-lg-0">
							<div className="col-lg-4 col-12" data-aos="flip-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="400">
								<div className="card border5px border-0 shadow text-center">
									<div className="card-body">
										<div className="explore-features rounded-circle hvr-icon-grow shadow-lg d-inline-block">
											<i className="fas hvr-icon fa-users" />
										</div>
										<div className="content mt-3">
											<h2 className="mb-0 colorblack pt-2">
												<span>
													<CountUp
														duration={3.5}
														end={33000}
														start={countUpNum1}
														onEnd={() => {
															setCountUpNum1(33000);
														}}
													>
														{({ countUpRef, start }) => (
															<VisibilitySensor onChange={start} delayedCall>
																<span ref={countUpRef} />
															</VisibilitySensor>
														)}
													</CountUp>
												</span>
												+
											</h2>
											<h6 className="mb-0 mt-2 darkbluetext">Students Impacted</h6>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-12 mt-3 mt-lg-0" data-aos="flip-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="500">
								<div className="card border5px border-0 shadow text-center">
									<div className="card-body">
										<div className="explore-features rounded-circle hvr-icon-grow shadow-lg d-inline-block">
											<i className="fas hvr-icon fa-chalkboard-teacher" />
										</div>
										<div className="content mt-3">
											<h2 className="mb-0 colorblack pt-2">
												<span>
													<CountUp
														duration={3}
														end={6}
														start={countUpNum2}
														onEnd={() => {
															setCountUpNum2(6);
														}}
													>
														{({ countUpRef, start }) => (
															<VisibilitySensor onChange={start} delayedCall>
																<span ref={countUpRef} />
															</VisibilitySensor>
														)}
													</CountUp>
												</span>
												+
											</h2>
											<h6 className="mb-0 mt-2 darkbluetext">Current Courses</h6>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-12 mt-3 mt-lg-0" data-aos="flip-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="600">
								<div className="card border5px border-0 shadow text-center">
									<div className="card-body">
										<div className="explore-features rounded-circle hvr-icon-grow shadow-lg d-inline-block">
											<i className="fas hvr-icon fa-trophy-alt" />
										</div>
										<div className="content mt-3">
											<h2 className="mb-0 colorblack pt-2">
												<span>
													<CountUp
														duration={3}
														end={50}
														start={countUpNum3}
														onEnd={() => {
															setCountUpNum3(50);
														}}
													>
														{({ countUpRef, start }) => (
															<VisibilitySensor onChange={start} delayedCall>
																<span ref={countUpRef} />
															</VisibilitySensor>
														)}
													</CountUp>
												</span>
												+
											</h2>
											<h6 className="mb-0 mt-2 darkbluetext">Cummulative Years of Experience</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<VisibilitySensor
					onChange={() => {
						setToggleAOS(!toggleAOS);
					}}
				>
					<TestimonialsList />
				</VisibilitySensor>
				<section className="section mt-0 overflow-hidden">
					<div className="container">
						<div className="row mx-3 mx-lg-0">
							<div className="col-12">
								<div className="row">
									<div className="col-lg-4" data-aos="flip-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="">
										<div className="card border-0 text-center p-4 shadow overflow-hidden" style={{ height: "200px" }}>
											<div className="text-center mx-auto hvr-icon-grow">
												<i className="fas border5px shadow features colorwhite gradientblue hvr-icon fa-project-diagram d-block fa-2x mb-0" />
											</div>
											<div className="card-body p-0 mt-3">
												<p className="fontsize16 mb-1 text-uppercase darkbluetext">Project Based Learning</p>
												<p className="fontsize16 colorblack">Select your Project / training Programme.</p>
											</div>
										</div>
									</div>
									<div className="col-lg-4 mt-4 pt-2 mt-sm-0 pt-sm-0" data-aos="flip-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="200">
										<div className="card border-0 text-center p-4 shadow overflow-hidden" style={{ height: "200px" }}>
											<div className="text-center mx-auto hvr-icon-grow">
												<i className="fas border5px shadow features colorwhite gradientblue hvr-icon fa-badge-check d-block fa-2x mb-0" />
											</div>
											<div className="card-body p-0 mt-3">
												<p className="fontsize16 mb-1 text-uppercase darkbluetext">Lifetime Access</p>
												<p className="fontsize16 colorblack">Anytime, anywhere at your pace</p>
											</div>
										</div>
									</div>
									<div className="col-lg-4 mt-4 pt-2 mt-sm-0 pt-sm-0" data-aos="flip-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="300">
										<div className="card border-0 text-center p-4 shadow overflow-hidden" style={{ height: "200px" }}>
											<div className="text-center mx-auto hvr-icon-grow">
												<i className="fas border5px shadow features colorwhite gradientblue hvr-icon fa-user-headset d-block fa-2x mb-0" />
											</div>
											<div className="card-body p-0 mt-3">
												<p className="fontsize16 mb-1 text-uppercase darkbluetext">Career Support</p>
												<p className="fontsize16 colorblack">Expert Mentors Interactive Community Also Consultations</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="pb-5 pt-4 ourteam">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12">
								<div className="text-center mb-4 pb-1" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
									<h2 className="colorblue">Our Team</h2>
								</div>
							</div>
						</div>
						<div className="row mt-2">
							<div className="col-lg-12">
								<div className="row">
									{ourTeam.map((member, index) => {
										return (
											<div key={index} className="col-lg-3 mb-5 px-4" data-aos="flip-left" data-aos-duration="1000" data-aos-once="true">
												<img
													src={member.dp || tempImg}
													className="w-100 border5px shadow"
													width="250px"
													height="250px"
													alt="Team_Member_Image"
													data-aos="flip-up"
													data-aos-duration="1000"
													data-aos-once="true"
													data-aos-delay={`${index}00`}
												/>
												<div className="text-center shadow bgcolorwhite border5px py-2 mt-3">
													<div className="row my-1">
														<div className="col">
															<h5 className="mb-0 colorblue">{member.name}</h5>
														</div>
													</div>
													<div className="row my-1">
														<div className="col">
															<p className="mb-0 fontsize14 colorlightblue">{member.role}</p>
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</section>
			</Base>
		</>
	);
};
export default AboutUs;
