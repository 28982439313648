const Storage = (quizResponseValue) => {
	sessionStorage.setItem("quizResponse", JSON.stringify(quizResponseValue.length > 0 ? quizResponseValue : []));
};
export const sum = (obj) => {
	Storage(obj);
	return;
};
export const QuizResponseReducer = (state, action) => {
	switch (action.type) {
		case "ADD_RESPONSE":
			if (state.quizResponseValue.find((val) => val.question_guid === action.payload.question_guid)) {
				if (!!action.payload.response.checked) {
					if (
						!state.quizResponseValue
							.find((val) => val.question_guid === action.payload.question_guid)
							.response.split(",")
							.find((val) => val === action.payload.response.name[0])
					) {
						state.quizResponseValue.find((val) => val.question_guid === action.payload.question_guid).response = [
							...state.quizResponseValue.find((val) => val.question_guid === action.payload.question_guid).response.split(","),
							action.payload.response.name[0],
						].toString();
					}
				} else {
					state.quizResponseValue.find((val) => val.question_guid === action.payload.question_guid).response = state.quizResponseValue
						.find((val) => val.question_guid === action.payload.question_guid)
						.response.split(",")
						.filter((val) => val !== action.payload.response.name[0])
						.toString();
				}
			} else {
				let newResp = [];

				!!action.payload.response.checked && newResp.push(action.payload.response.name[0]);

				state.quizResponseValue = [
					...state.quizResponseValue,
					{
						question_guid: action.payload.question_guid,
						response: newResp.toString(),
					},
				];
			}
			return {
				...state,
				...sum(state.quizResponseValue),
				quizResponseValue: state.quizResponseValue,
			};
		case "ADD_RESPONSE_2":
			if (state.quizResponseValue.find((val) => val.question_guid === action.payload.question_guid)) {
				state.quizResponseValue.find((val) => val.question_guid === action.payload.question_guid).response = action.payload.response;
			} else {
				state.quizResponseValue = [
					...state.quizResponseValue,
					{
						question_guid: action.payload.question_guid,
						response: action.payload.response,
					},
				];
			}
			return {
				...state,
				...sum(state.quizResponseValue),
				quizResponseValue: state.quizResponseValue,
			};
		default:
			return state;
	}
};
