import React, { useState, useEffect } from "react";
import DataLoader from "../Components/DataLoaders/DataLoader";
import { viewQuiz } from "../helpers/lms/viewQuiz";
import Breadcrumb from "../Components/Breadcrumb";
import Base from "../Base";
import { Helmet } from "react-helmet-async";
const ViewQuiz = (props) => {
	const [quizResponse, setQuizResponse] = useState([]);
	const [quizTitle, setQuizTitle] = useState("");
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		var mounted = true;
		if (mounted) {
			viewQuiz(props.match.params.id, (data) => {
				setQuizResponse(data?.[0]);
				setQuizTitle(data?.[0]?.quiz?.quiz_title);
				setLoading(false);
			});
		}
		return () => {
			mounted = false;
		};
	}, []);
	return (
		<>
			<Helmet>
				<title>International Lunar University | Quiz</title>
			</Helmet>
			<Base>
				<Breadcrumb title="Quiz" />
				{loading ? (
					<DataLoader />
				) : (
					<div className="section">
						<div className="container">
							<div className="row bgcolorgreyish mx-1 p-3 border5px">
								<div className="col-lg-12 bg-white p-3 border5px mb-4">
									<h2 className="mb-3 text-center colorblue">{quizTitle}</h2>
									<p className="fontsize16 colorlightblue text-center fw-bold mb-2">
										{quizResponse?.total_marks !== 0 ? (
											<>
												<span className="colorblue">{quizResponse?.marks}</span> / {quizResponse?.total_marks}
											</>
										) : (
											"Not Graded"
										)}
									</p>
									<p className="fontsize14 colorlightblue text-center fw-bold mb-2">
										Attempted At <span className="colorblue">{quizResponse?.date_attempted.slice(11, 16)}</span> On
										<span className="colorblue"> {quizResponse?.date_attempted.slice(0, 10)}</span>
									</p>
									<p className="fontsize14 colorlightblue text-center fw-bold mb-0">
										MCQ - Multiple Choice Question | MSQ - Multiple Solution Question | NAT - Numerical Answer Type
									</p>
								</div>
								{quizResponse?.question &&
									quizResponse?.question.length > 0 &&
									quizResponse?.question.map((ques, index) => {
										return (
											<div key={ques?.id} className="col-lg-12 mb-4 px-0">
												{ques?.question?.question?.option_type === "Text" && (
													<p className="fontsize16 colorblue fw-bold mb-0">
														Q{index + 1}. {ques?.question?.question?.text} [ {ques?.question?.questype} ]
														{ques?.marks ? (
															<span className="accepted"> [ Correct, {ques?.question?.marks} Marks ]</span>
														) : (
															<span className="notaccepted"> [ Incorrect, 0 Marks ]</span>
														)}
													</p>
												)}
												{ques?.question?.question?.option_type === "Image" && (
													<p className="fontsize16 colorblue fw-bold mb-0">
														Q{index + 1}. <img className="border5px" src={ques?.question?.question?.image} height="200px" width="200px" />
														{ques?.marks ? (
															<span className="accepted"> [ Correct, {ques?.question?.marks} Marks ]</span>
														) : (
															<span className="notaccepted"> [ Incorrect, 0 Marks ]</span>
														)}
													</p>
												)}
												{ques?.question?.question?.option_type === "Both" && (
													<>
														<p className="fontsize16 colorblue fw-bold mb-0">
															Q{index + 1}. {ques?.question?.question?.text} [ {ques?.question?.questype} ]
															{ques?.marks ? (
																<span className="accepted"> [ Correct, {ques?.question?.marks} Marks ]</span>
															) : (
																<span className="notaccepted"> [ Incorrect, 0 Marks ]</span>
															)}
														</p>
														<img className="mt-3 border5px" src={ques?.question?.question?.image} height="200px" width="200px" />
													</>
												)}
												{ques?.question?.questype === "MCQ" && (
													<div>
														<input
															checked={ques?.response === "A" ? true : false}
															type="radio"
															className="d-none one"
															name={`MCQ_Question${ques?.question?.guid}`}
															id={`${index}${ques?.question?.option_a?.id}`}
															value="A"
														/>
														<input
															checked={ques?.response === "B" ? true : false}
															type="radio"
															className="d-none two"
															name={`MCQ_Question${ques?.question?.guid}`}
															id={`${index}${ques?.question?.option_b?.id}`}
															value="B"
														/>
														<input
															checked={ques?.response === "C" ? true : false}
															type="radio"
															className="d-none three"
															name={`MCQ_Question${ques?.question?.guid}`}
															id={`${index}${ques?.question?.option_c?.id}`}
															value="C"
														/>
														<input
															checked={ques?.response === "D" ? true : false}
															type="radio"
															className="d-none four"
															name={`MCQ_Question${ques?.question?.guid}`}
															id={`${index}${ques?.question?.option_d?.id}`}
															value="D"
														/>
														<label
															htmlFor={`${index}${ques?.question?.option_a?.id}`}
															style={{ cursor: "default" }}
															className={
																ques?.question?.right_answer === "A"
																	? "box first correct"
																	: !(!ques?.marks && ques?.response === "A")
																	? "box first"
																	: "box first incorrect"
															}
														>
															<div className="course">
																<span className="circle" />
																{ques?.question?.option_a?.option_type === "Text" && (
																	<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_a?.text}</p>
																)}
																{ques?.question?.option_a?.option_type === "Image" && (
																	<img className="border5px" src={ques?.question?.option_a?.image} height="200px" width="200px" />
																)}
																{ques?.question?.option_a?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_a?.text}</p>
																		<img className="border5px" src={ques?.question?.option_a?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label
															htmlFor={`${index}${ques?.question?.option_b?.id}`}
															style={{ cursor: "default" }}
															className={
																ques?.question?.right_answer === "B"
																	? "box second correct"
																	: !(!ques?.marks && ques?.response === "B")
																	? "box second"
																	: "box second incorrect"
															}
														>
															<div className="course">
																<span className="circle" />
																{ques?.question?.option_b?.option_type === "Text" && (
																	<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_b?.text}</p>
																)}
																{ques?.question?.option_b?.option_type === "Image" && (
																	<img className="border5px" src={ques?.question?.option_b?.image} height="200px" width="200px" />
																)}
																{ques?.question?.option_b?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_b?.text}</p>
																		<img className="border5px" src={ques?.question?.option_b?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label
															htmlFor={`${index}${ques?.question?.option_c?.id}`}
															style={{ cursor: "default" }}
															className={
																ques?.question?.right_answer === "C"
																	? "box third correct"
																	: !(!ques?.marks && ques?.response === "C")
																	? "box third"
																	: "box third incorrect"
															}
														>
															<div className="course">
																<span className="circle" />
																{ques?.question?.option_c?.option_type === "Text" && (
																	<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_c?.text}</p>
																)}
																{ques?.question?.option_c?.option_type === "Image" && (
																	<img className="border5px" src={ques?.question?.option_c?.image} height="200px" width="200px" />
																)}
																{ques?.question?.option_c?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_c?.text}</p>
																		<img className="border5px" src={ques?.question?.option_c?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label
															htmlFor={`${index}${ques?.question?.option_d?.id}`}
															style={{ cursor: "default" }}
															className={
																ques?.question?.right_answer === "D"
																	? "box fourth correct"
																	: !(!ques?.marks && ques?.response === "D")
																	? "box fourth"
																	: "box fourth incorrect"
															}
														>
															<div className="course">
																<span className="circle" />
																{ques?.question?.option_d?.option_type === "Text" && (
																	<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_d?.text}</p>
																)}
																{ques?.question?.option_d?.option_type === "Image" && (
																	<img className="border5px" src={ques?.question?.option_d?.image} height="200px" width="200px" />
																)}
																{ques?.question?.option_d?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_d?.text}</p>
																		<img className="border5px" src={ques?.question?.option_d?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
													</div>
												)}
												{ques?.question?.questype === "MSQ" && (
													<>
														<input
															checked={ques?.response?.includes("A") ? true : false}
															type="checkbox"
															className="d-none one"
															name="A"
															id={`${index}${ques?.question?.option_a?.id}`}
															value="A"
														/>
														<input
															checked={ques?.response?.includes("B") ? true : false}
															type="checkbox"
															className="d-none two"
															name="B"
															id={`${index}${ques?.question?.option_b?.id}`}
															value="B"
														/>
														<input
															checked={ques?.response?.includes("C") ? true : false}
															type="checkbox"
															className="d-none three"
															name="C"
															id={`${index}${ques?.question?.option_c?.id}`}
															value="C"
														/>
														<input
															checked={ques?.response?.includes("D") ? true : false}
															type="checkbox"
															className="d-none four"
															name="D"
															id={`${index}${ques?.question?.option_d?.id}`}
															value="D"
														/>
														<label
															htmlFor={`${index}${ques?.question?.option_a?.id}`}
															style={{ cursor: "default" }}
															className={
																ques?.response?.includes("A") && ques?.question?.right_answer?.includes("A")
																	? "box first correct"
																	: ques?.question?.right_answer?.includes("A")
																	? "box first correct"
																	: ques?.response?.includes("A")
																	? "box first incorrect"
																	: "box first"
															}
														>
															<div className="course">
																<span className="circle" />
																{ques?.question?.option_a?.option_type === "Text" && (
																	<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_a?.text}</p>
																)}
																{ques?.question?.option_a?.option_type === "Image" && (
																	<img className="border5px" src={ques?.question?.option_a?.image} height="200px" width="200px" />
																)}
																{ques?.question?.option_a?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_a?.text}</p>
																		<img className="border5px" src={ques?.question?.option_a?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label
															htmlFor={`${index}${ques?.question?.option_b?.id}`}
															style={{ cursor: "default" }}
															className={
																ques?.response?.includes("B") && ques?.question?.right_answer?.includes("B")
																	? "box second correct"
																	: ques?.question?.right_answer?.includes("B")
																	? "box second correct"
																	: ques?.response?.includes("B")
																	? "box second incorrect"
																	: "box second"
															}
														>
															<div className="course">
																<span className="circle" />
																{ques?.question?.option_b?.option_type === "Text" && (
																	<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_b?.text}</p>
																)}
																{ques?.question?.option_b?.option_type === "Image" && (
																	<img className="border5px" src={ques?.question?.option_b?.image} height="200px" width="200px" />
																)}
																{ques?.question?.option_b?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_b?.text}</p>
																		<img className="border5px" src={ques?.question?.option_b?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label
															htmlFor={`${index}${ques?.question?.option_c?.id}`}
															style={{ cursor: "default" }}
															className={
																ques?.response?.includes("C") && ques?.question?.right_answer?.includes("C")
																	? "box third correct"
																	: ques?.question?.right_answer?.includes("C")
																	? "box third correct"
																	: ques?.response?.includes("C")
																	? "box third incorrect"
																	: "box third"
															}
														>
															<div className="course">
																<span className="circle" />
																{ques?.question?.option_c?.option_type === "Text" && (
																	<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_c?.text}</p>
																)}
																{ques?.question?.option_c?.option_type === "Image" && (
																	<img className="border5px" src={ques?.question?.option_c?.image} height="200px" width="200px" />
																)}
																{ques?.question?.option_c?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_c?.text}</p>
																		<img className="border5px" src={ques?.question?.option_c?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label
															htmlFor={`${index}${ques?.question?.option_d?.id}`}
															style={{ cursor: "default" }}
															className={
																ques?.response?.includes("D") && ques?.question?.right_answer?.includes("D")
																	? "box fourth correct"
																	: ques?.question?.right_answer?.includes("D")
																	? "box fourth correct"
																	: ques?.response?.includes("D")
																	? "box fourth incorrect"
																	: "box fourth"
															}
														>
															<div className="course">
																<span className="circle" />
																{ques?.question?.option_d?.option_type === "Text" && (
																	<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_d?.text}</p>
																)}
																{ques?.question?.option_d?.option_type === "Image" && (
																	<img className="border5px" src={ques?.question?.option_d?.image} height="200px" width="200px" />
																)}
																{ques?.question?.option_d?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblue fw-bold mb-0">{ques?.question?.option_d?.text}</p>
																		<img className="border5px" src={ques?.question?.option_d?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
													</>
												)}
												{ques?.question?.questype === "NAT" && (
													<>
														<div className="col-lg-12">
															<input
																disabled
																className="input100 mt-3 px-3 py-2 w-50 border5px border-0 colorblue"
																type="text"
																value={ques?.question?.right_answer}
																style={{ background: "rgb(0, 230, 118, 0.3)" }}
															/>
														</div>
														<div className="col-lg-12">
															<input
																disabled
																className="input100 mt-3 px-3 py-2 w-50 border5px border-0 colorblue"
																type="text"
																value={ques?.response}
																style={
																	ques?.question?.right_answer === ques?.response ? { background: "rgb(0, 230, 118, 0.3)" } : { background: "rgb(255, 23, 68, 0.3)" }
																}
															/>
														</div>
													</>
												)}
											</div>
										);
									})}
							</div>
						</div>
					</div>
				)}
			</Base>
		</>
	);
};
export default ViewQuiz;
