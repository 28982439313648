import { useState, useContext, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { WishlistContext } from "../Contexts/WishlistContext";
import { CartContext } from "../Contexts/CartContext";
import { BaseContext } from "../Context";
import { toast } from "react-toastify";
import { isAuthenticated } from "../helpers/auth/authentication";
import tempImg from "../Assets/images/Course_Image.webp";
import { SingleEntityContext } from "../Contexts/SingleEntityContext";
import { useMediaQuery } from "react-responsive";
const CourseListCard = ({ course }) => {
	const history = useHistory();
	const { course_Enrollments, cookies, handleNotification } = useContext(BaseContext);
	const { addCourse, cartItems } = useContext(CartContext);
	const { addSingleCourse } = useContext(SingleEntityContext);
	const { addCourseToWishlist, removeCourseFromWishlist, wishlistItems } = useContext(WishlistContext);
	const isInCart = (guid, userID) => {
		return !!!cartItems.courses.find((item) => item.course.guid === guid && item.userID === userID);
	};
	const isInWishlist = (guid, userID) => {
		return wishlistItems.courses.find((item) => item.course.guid === guid && item.userID === userID);
	};
	function truncate(str, n) {
		return str?.length > n ? str.substr(0, n - 1) + "..." : str;
	}
	const [animateButton, setAnimateButton] = useState(false);
	useEffect(() => {
		const timer = setTimeout(() => setAnimateButton(false), 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [animateButton]);
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	const [onHoverDropdown1, setOnHoverDropdown1] = useState(false);
	var dropdownToggle1 = useRef(null);
	useEffect(() => {
		if (isDesktopOrLaptop) {
			const mouseOver1 = () => {
				setOnHoverDropdown1(true);
			};
			const mouseLeave1 = () => {
				setOnHoverDropdown1(false);
			};
			dropdownToggle1.current.addEventListener("mouseover", mouseOver1);
			dropdownToggle1.current.addEventListener("mouseleave", mouseLeave1);
			if (dropdownToggle1 === null) {
				return () => {
					dropdownToggle1.current.removeEventListener("mouseover", mouseOver1);
					dropdownToggle1.current.removeEventListener("mouseleave", mouseLeave1);
				};
			}
		}
	});
	return (
		<>
			{isDesktopOrLaptop && (
				<div className="col-lg-4 selectdisable col-12 mt-4 px-3 d-flex justify-content-center" data-aos="flip-right" data-aos-duration="1000" data-aos-once="true">
					<div
						ref={dropdownToggle1}
						className={
							onHoverDropdown1 ? "card hovergoup transitionease overflow-hidden border5px border-0 shadow" : "card hovergoup transitionease overflow-hidden border5px border-0 shadow"
						}
						style={{ height: "430px", width: "350px" }}
					>
						<Link to={`/learn/course/${course.slug}`} className="transitionease" style={{ height: `${onHoverDropdown1 ? "55%" : "79%"}` }}>
							<img src={course?.course_thumbnail || tempImg} style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }} width="350px" height="350px" alt="Course_Image" />
						</Link>
						<div className="card-body bg-white p-3 pt-2 d-flex flex-wrap">
							<div className="d-flex w-100" style={{ height: "40%" }}>
								<div className="me-2 flex-grow-1 pt-2 d-flex flex-wrap">
									<h5 className={onHoverDropdown1 ? "transitionease fontsize16 h-25 mb-0" : "transitionease fontsize16 h-25 mb-3"}>
										<Link to={`/learn/course/${course.slug}`} className="colorblack darkbluetexthover">
											{course.course_title}
										</Link>
									</h5>
								</div>
								<div className="course-fee colorwhite text-center shadow rounded-circle">
									<h6 className="mb-0 fontsize14 fee">
										₹&nbsp;
										{(Math.abs(parseInt(course?.course_sp) - parseFloat(course?.course_sp)) > 0.5 ? parseInt(course?.course_sp) + 1 : parseInt(course?.course_sp)).toLocaleString(
											undefined,
											{ maximumFractionDigits: 2 }
										)}
									</h6>
								</div>
							</div>
							<div className="d-flex mt-1 align-items-center w-100" style={{ height: "30%" }}>
								<div className="flex-grow-1 pt-2 d-flex flex-wrap">
									<p className="colorblack fontsize14 mb-1">{truncate(course.course_description, 60)}</p>
								</div>
							</div>
							<div className="w-100">
								<div className="d-flex mt-2 justify-content-between align-items-center">
									{!(!course_Enrollments.error && course_Enrollments.filter((item) => item.course?.guid === course?.guid).length > 0) && (
										<button
											className="wishlistbutton py-2 fontsize14 px-3 border5px"
											onClick={() => {
												if (isAuthenticated()) {
													isInWishlist(course.guid, cookies?.user?.[0]?.id)
														? removeCourseFromWishlist({ guid: course.guid, userID: cookies?.user?.[0].id })
														: addCourseToWishlist({ course, userID: cookies?.user?.[0].id });
												} else {
													return toast("Please login to access wishlist!", {
														type: "warning",
														autoClose: 5000,
														position: "bottom-center",
														hideProgressBar: false,
														pauseOnHover: true,
														pauseOnFocusLoss: true,
													});
												}
											}}
										>
											<i className={`${isInWishlist(course.guid, cookies?.user?.[0]?.id) ? "fas fa-heart darkbluetext" : "far fa-heart darkbluetext"}`} />
										</button>
									)}
									{!course_Enrollments.error && course_Enrollments.filter((item) => item.course?.guid === course?.guid).length > 0 && isAuthenticated() ? (
										<Link
											to={`/learn/mycourses/${course.guid}`}
											className="w-75 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold addtocartbutton h-75 border5px text-uppercase"
										>
											Go To Course
										</Link>
									) : isInCart(course.guid, cookies?.user?.[0]?.id) ? (
										<>
											<button
												className={`${
													animateButton
														? "add-to-cart w-75 mx-2 py-2 px-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-75 overflow-hidden addtocartbutton border5px text-uppercase is-added"
														: "add-to-cart w-75 mx-2 py-2 px-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-75 overflow-hidden addtocartbutton border5px text-uppercase"
												}`}
												onClick={() => {
													if (isAuthenticated()) {
														setAnimateButton(true);
														addCourse({ course, userID: cookies?.user?.[0]?.id });
													} else {
														handleNotification("Please login to access cart!", "warning");
														history.push("/signin");
													}
												}}
											>
												<span>Add To Cart</span>
												<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
													<path
														className={`${animateButton ? "pathatc" : ""}`}
														strokeDasharray="19.79 19.79"
														strokeDashoffset="19.79"
														fill="none"
														stroke="#fff"
														strokeWidth={2}
														strokeLinecap="square"
														strokeMiterlimit={10}
														d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
													/>
												</svg>
											</button>
											<button
												className="w-75 py-2 px-2 fontsize14 d-flex justify-content-center align-items-center fw-bold h-75 buynowbutton border5px text-uppercase"
												onClick={() => {
													if (isAuthenticated()) {
														addSingleCourse(course);
														history.push("/checkout");
													} else {
														handleNotification("Please login to buy!", "warning");
														history.push("/signin");
													}
												}}
											>
												<span>Buy Now</span>
											</button>
										</>
									) : (
										<>
											<button
												className={`${
													animateButton
														? "add-to-cart w-75 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-75 overflow-hidden addtocartbutton border5px text-uppercase is-added"
														: "add-to-cart w-75 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-75 overflow-hidden addtocartbutton border5px text-uppercase"
												}`}
												onClick={() => {
													history.push("/cart");
													setAnimateButton(true);
												}}
											>
												<span>Go To Cart</span>
												<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
													<path
														className={`${animateButton ? "pathatc" : ""}`}
														strokeDasharray="19.79 19.79"
														strokeDashoffset="19.79"
														fill="none"
														stroke="#fff"
														strokeWidth={2}
														strokeLinecap="square"
														strokeMiterlimit={10}
														d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
													/>
												</svg>
											</button>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{isTabletOrMobile && (
				<div className="col-lg-4 selectdisable col-12 mt-4 px-3 d-flex justify-content-center" data-aos="flip-right" data-aos-duration="1000" data-aos-once="true">
					<div className="card hovergoup transitionease overflow-hidden border5px border-0 shadow" style={{ height: "500px", width: "350px" }}>
						<Link to={`/learn/course/${course.slug}`} className="transitionease" style={{ height: "57.5%" }}>
							<img src={course?.course_thumbnail || tempImg} style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }} width="350px" height="350px" alt="Course_Image" />
						</Link>
						<div className="card-body bg-white p-3 d-flex flex-wrap">
							<div className="d-flex w-100">
								<div className="me-2 flex-grow-1 d-flex flex-wrap">
									<h5 className={onHoverDropdown1 ? "transitionease fontsize16 h-25 mb-0" : "transitionease fontsize16 h-25 mb-3"}>
										<Link to={`/learn/course/${course.slug}`} className="colorblack darkbluetexthover">
											{course.course_title}
										</Link>
									</h5>
								</div>
								<div className="course-fee colorwhite text-center shadow rounded-circle">
									<h6 className="mb-0 fontsize14 fee">
										₹&nbsp;
										{(Math.abs(parseInt(course?.course_sp) - parseFloat(course?.course_sp)) > 0.5 ? parseInt(course?.course_sp) + 1 : parseInt(course?.course_sp)).toLocaleString(
											undefined,
											{ maximumFractionDigits: 2 }
										)}
									</h6>
								</div>
							</div>
							<div className="d-flex mt-2 align-items-center w-100" style={{ height: "30%" }}>
								<div className="flex-grow-1 d-flex flex-wrap">
									<p className="colorblack fontsize14 mb-1">{truncate(course.course_description, 60)}</p>
								</div>
							</div>
							<div className="w-100">
								<div className="d-flex mt-2 justify-content-between align-items-center">
									{!(!course_Enrollments.error && course_Enrollments.filter((item) => item.course?.guid === course?.guid).length > 0) && (
										<button
											className="wishlistbutton py-2 fontsize14 px-3 border5px"
											onClick={() => {
												if (isAuthenticated()) {
													isInWishlist(course.guid, cookies?.user?.[0]?.id)
														? removeCourseFromWishlist({ guid: course.guid, userID: cookies?.user?.[0].id })
														: addCourseToWishlist({ course, userID: cookies?.user?.[0].id });
												} else {
													return toast("Please login to access wishlist!", {
														type: "warning",
														autoClose: 5000,
														position: "bottom-center",
														hideProgressBar: false,
														pauseOnHover: true,
														pauseOnFocusLoss: true,
													});
												}
											}}
										>
											<i className={`${isInWishlist(course.guid, cookies?.user?.[0]?.id) ? "fas fa-heart darkbluetext" : "far fa-heart darkbluetext"}`} />
										</button>
									)}
									{!course_Enrollments.error && course_Enrollments.filter((item) => item.course?.guid === course?.guid).length > 0 && isAuthenticated() ? (
										<Link
											to={`/learn/mycourses/${course.guid}`}
											className="w-75 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold addtocartbutton h-75 border5px text-uppercase"
										>
											Go To Course
										</Link>
									) : isInCart(course.guid, cookies?.user?.[0]?.id) ? (
										<>
											<button
												className={`${
													animateButton
														? "add-to-cart w-75 mx-2 py-2 px-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-75 overflow-hidden addtocartbutton border5px text-uppercase is-added"
														: "add-to-cart w-75 mx-2 py-2 px-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-75 overflow-hidden addtocartbutton border5px text-uppercase"
												}`}
												onClick={() => {
													if (isAuthenticated()) {
														setAnimateButton(true);
														addCourse({ course, userID: cookies?.user?.[0]?.id });
													} else {
														handleNotification("Please login to access cart!", "warning");
														history.push("/signin");
													}
												}}
											>
												<span>Add To Cart</span>
												<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
													<path
														className={`${animateButton ? "pathatc" : ""}`}
														strokeDasharray="19.79 19.79"
														strokeDashoffset="19.79"
														fill="none"
														stroke="#fff"
														strokeWidth={2}
														strokeLinecap="square"
														strokeMiterlimit={10}
														d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
													/>
												</svg>
											</button>
											<button
												className="w-75 py-2 px-2 fontsize14 d-flex justify-content-center align-items-center fw-bold h-75 buynowbutton border5px text-uppercase"
												onClick={() => {
													if (isAuthenticated()) {
														addSingleCourse(course);
														history.push("/checkout");
													} else {
														handleNotification("Please login to buy!", "warning");
														history.push("/signin");
													}
												}}
											>
												<span>Buy Now</span>
											</button>
										</>
									) : (
										<>
											<button
												className={`${
													animateButton
														? "add-to-cart w-75 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-75 overflow-hidden addtocartbutton border5px text-uppercase is-added"
														: "add-to-cart w-75 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-75 overflow-hidden addtocartbutton border5px text-uppercase"
												}`}
												onClick={() => {
													history.push("/cart");
													setAnimateButton(true);
												}}
											>
												<span>Go To Cart</span>
												<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
													<path
														className={`${animateButton ? "pathatc" : ""}`}
														strokeDasharray="19.79 19.79"
														strokeDashoffset="19.79"
														fill="none"
														stroke="#fff"
														strokeWidth={2}
														strokeLinecap="square"
														strokeMiterlimit={10}
														d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
													/>
												</svg>
											</button>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
export default CourseListCard;
