import React, { createContext, useReducer } from "react";
import { WishlistReducer, setStorage } from "./WishlistReducer";
export const WishlistContext = createContext();
const storage = localStorage.getItem("wishlist")
	? JSON.parse(localStorage.getItem("wishlist"))
	: {
			courses: [],
			bundles: [],
	  };
const initialState = {
	wishlistItems: storage,
	...setStorage(storage, {
		courses: [],
		bundles: [],
	}),
};
const WishlistProvider = ({ children }) => {
	const [state, dispatch] = useReducer(WishlistReducer, initialState);
	const addCourseToWishlist = (payload) => {
		dispatch({ type: "ADD_COURSE", payload });
	};
	const removeCourseFromWishlist = (payload) => {
		dispatch({ type: "REMOVE_COURSE", payload });
	};
	const addBundleToWishlist = (payload) => {
		dispatch({ type: "ADD_BUNDLE", payload });
	};
	const removeBundleFromWishlist = (payload) => {
		dispatch({ type: "REMOVE_BUNDLE", payload });
	};
	// const clearWishlist = (payload) => {
	// 	dispatch({ type: "CLEAR_WISHLIST", payload });
	// };
	const updateWishlistOnAuth = (payload) => {
		dispatch({ type: "UPDATE_WISHLIST_ON_AUTH", payload });
	};
	const contextValues = {
		removeCourseFromWishlist,
		addCourseToWishlist,
		addBundleToWishlist,
		removeBundleFromWishlist,
		// clearWishlist,
		updateWishlistOnAuth,
		...state,
	};
	return <WishlistContext.Provider value={contextValues}>{children}</WishlistContext.Provider>;
};
export default WishlistProvider;
