import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home";
import { toast } from "react-toastify";
import CourseDetail from "./Pages/CourseDetail";
import CourseView from "./Pages/CourseView";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import TermsCond from "./Pages/TnC";
import HonoCode from "./Pages/HonorCode";
import CancRef from "./Pages/CnR";
import PrivPol from "./Pages/PrivP";
import Faqs from "./Pages/Faqs";
import Profile from "./Pages/ProfilePages/Profile";
import RepBug from "./Pages/RepBug";
import Feedback from "./Pages/Feedback";
import NotFound404 from "./Pages/NotFound404/NotFound404";
import LoginPage from "./Pages/AuthPages/LoginPage";
import { useEffect, useState, useContext } from "react";
import { isAuthenticated, signout } from "./helpers/auth/authentication";
import VerifyEmail from "./Pages/AuthPages/VerifyEmail";
import PasswordReset from "./Pages/AuthPages/PasswordReset";
import PasswordResetConfirm from "./Pages/AuthPages/PasswordResetConfirm";
import PrivateRoutes from "./helpers/auth/PrivateRoutes";
import CoursesList from "./Pages/CoursesListPage";
import { profileData } from "./data/users/profileData";
import { BaseContext, CoursesContext, TestimonialsContext, BundlesContext } from "./Context";
import CartPage from "./Pages/CartPage";
import PreLoader from "./PreLoader";
import Checkout from "./Pages/Checkout";
import { courseEnrollments } from "../src/data/courses/courses";
import { accomplishments } from "../src/data/courses/courses";
import { MyOrder } from "../src/data/users/myOrders";
import { ourteamdata } from "../src/data/others/ourteam";
import { badgesdata } from "../src/data/others/badges";
import { cnrdata } from "../src/data/others/cnr";
import { faqdata } from "../src/data/others/faq";
import { privpdata } from "../src/data/others/privp";
import { tncdata } from "../src/data/others/tnc";
import { hncdata } from "../src/data/others/hnc";
import { useCookies } from "react-cookie";
import { WishlistContext } from "./Contexts/WishlistContext";
import SearchPage from "./Pages/SearchPage";
import { CartContext } from "./Contexts/CartContext";
import Testimonials from "./Pages/Testimonials";
import BundlesList from "./Pages/BundlesList";
import { bundles } from "./data/bundles/bundles";
import BundleDetail from "./Pages/BundleDetail";
import Policies from "./Pages/Policies";
import VerifyEmailConfirm from "./Pages/AuthPages/VerifyEmailConfirm";
import MyQuiz from "./Pages/Quiz";
import VerifyCert from "./Pages/VerifyCertificate";
import ViewQuiz from "./Pages/ViewQuiz";
const Routes = () => {
	// ANCHOR Contexts
	const { removeCourseFromWishlist, wishlistItems, updateWishlistOnAuth } = useContext(WishlistContext);
	const { updateCartOnAuth } = useContext(CartContext);

	// ANCHOR User & Auth
	const [cookies, setCookie, removeCookie] = useCookies(["user"]);
	const logoutUser = (event) => {
		event.preventDefault();
		signout((data) => {
			removeCookie("user", { path: "/" });
			return toast(data?.detail, { type: "success", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
		});
	};
	useEffect(() => {
		if (isAuthenticated()) {
			profileData((statusText, status) => {
				if (status === 403 || status === 401) {
					localStorage.removeItem("token");
					removeCookie("user", { path: "/" });
					handleNotification(`Something went wrong! Status: ${statusText}`, "error");
				}
			}).then((data) => {
				setCookie("user", data, { path: "/" });
				updateCartOnAuth(data?.[0]?.id);
				updateWishlistOnAuth(data?.[0]?.id);
				setUpdate_Enrollments(!update_Enrollments);
			});
		} else {
			updateCartOnAuth(null);
			updateWishlistOnAuth(null);
			removeCookie("user", { path: "/" });
		}
	}, [isAuthenticated()]);
	function requireAuth(nextState, replace, next) {
		if (!(isAuthenticated() && cookies.user)) {
			replace({
				pathname: "/signin",
				state: { nextPathname: nextState.location.pathname },
			});
		}
		next();
	}

	// ANCHOR Notifications
	const [notification, setNotification] = useState("");
	const [notificationType, setNotificationType] = useState("");
	const [toggleNotification, setToggleNotification] = useState(false);
	const handleNotification = (message, type) => {
		setNotification(message);
		setNotificationType(type);
		setToggleNotification(!toggleNotification);
	};
	useEffect(() => {
		if (notificationType === "success") {
			return toast(notification, { type: "success", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
		}
		if (notificationType === "error") {
			return toast(notification, { type: "error", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
		}
		if (notificationType === "warning") {
			return toast(notification, { type: "warning", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
		}
	}, [toggleNotification]);

	// ANCHOR Courses
	const [allCourses, setAllCourses] = useState([]);
	const [course, setCourse] = useState({});
	const [courseContent, setCourseContent] = useState({});
	const handleAllCourses = (list) => {
		setAllCourses(list);
	};
	const findCourse = (data) => {
		setCourse(data);
	};
	const findCourseContent = (data) => {
		setCourseContent(data);
	};

	//ANCHOR Bundles
	const [allBundles, setAllBundles] = useState([]);
	const [bundle, setBundle] = useState({});
	const handleAllBundles = (list) => {
		setAllBundles(list);
	};
	const findBundle = (data) => {
		setBundle(data);
	};
	useEffect(() => {
		bundles((data) => {
			handleAllBundles(data);
		});
	}, []);

	// ANCHOR Testimonials
	const [testimonials, setTestimonials] = useState([]);
	const handleTestimonials = (list) => {
		setTestimonials(list);
	};

	// ANCHOR Remember Me
	const [rememberMe, setRememberMe] = useState(true);
	const handleRememberMe = (e) => {
		// e.preventDefault();
		const input = e.target;
		const value = input.type === "checkbox" ? input.checked : input.value;
		setRememberMe(value);
	};
	useEffect(() => {
		var mounted = true;
		if (mounted) {
			if (!rememberMe) {
				// window.addEventListener("beforeunload", alertUser);
				window.addEventListener("unload", signout);
				return () => {
					// window.removeEventListener("beforeunload", alertUser);
					window.removeEventListener("unload", signout);
				};
			}
		}
		return () => {
			mounted = false;
		};
	});

	// ANCHOR Course Enrollments
	const [course_Enrollments, setCourse_Enrollments] = useState([]);
	const [update_Enrollments, setUpdate_Enrollments] = useState(false);
	useEffect(async () => {
		if (isAuthenticated()) {
			await courseEnrollments((data) => {
				if (!data.error) {
					setCourse_Enrollments(data);
					data.map((value) => {
						wishlistItems.courses.find((item) => item.course.guid === value.course.guid) && removeCourseFromWishlist({ guid: value.course.guid, userID: cookies?.user?.[0]?.id });
					});
				} else {
					setCourse_Enrollments([]);
				}
			});
		} else {
			setCourse_Enrollments([]);
		}
	}, [isAuthenticated(), update_Enrollments]);
	const [myOrders, setMyOrders] = useState([]);
	useEffect(async () => {
		if (isAuthenticated()) {
			await MyOrder((data) => {
				setMyOrders(data);
			});
		}
	}, [isAuthenticated(), update_Enrollments]);

	// ANCHOR Accomplishments
	const [course_Accomplishments, setCourse_Accomplishments] = useState([]);
	const [update_Accomplishments, setUpdate_Accomplishments] = useState(false);
	useEffect(async () => {
		if (isAuthenticated()) {
			await accomplishments((data) => {
				if (!data.error) {
					setCourse_Accomplishments(data);
				} else {
					setCourse_Accomplishments([]);
				}
			});
		}
	}, [isAuthenticated(), update_Accomplishments]);

	// ANCHOR Search Results
	const [searchResults, setSearchResults] = useState([]);

	// ANCHOR Our Team
	const [ourTeam, setOurTeam] = useState([]);
	useEffect(async () => {
		await ourteamdata((data) => {
			setOurTeam(data);
		});
	}, []);

	// ANCHOR Badges
	const [badge, setBadge] = useState([]);
	useEffect(async () => {
		await badgesdata((data) => {
			setBadge(data);
		});
	}, []);

	// ANCHOR CNR
	const [cnrDoc, setCnrDoc] = useState([]);
	useEffect(async () => {
		await cnrdata((data) => {
			setCnrDoc(data);
		});
	}, []);

	// ANCHOR FAQ
	const [faqDoc, setFaqDoc] = useState([]);
	useEffect(async () => {
		await faqdata((data) => {
			setFaqDoc(data);
		});
	}, []);

	// ANCHOR PrivP
	const [privpDoc, setPrivpDoc] = useState([]);
	useEffect(async () => {
		await privpdata((data) => {
			setPrivpDoc(data);
		});
	}, []);

	// ANCHOR TNC
	const [tncDoc, setTncDoc] = useState([]);
	useEffect(async () => {
		await tncdata((data) => {
			setTncDoc(data);
		});
	}, []);

	// ANCHOR Honor Code
	const [honCode, setHonCode] = useState([]);
	useEffect(async () => {
		await hncdata((data) => {
			setHonCode(data);
		});
	}, []);

	const handleQuizData = (data) => {
		localStorage.setItem("contentID", JSON.stringify(data));
	};
	return (
		<>
			<BaseContext.Provider
				value={{
					logoutUser,
					notification,
					notificationType,
					handleNotification,
					course_Enrollments,
					setCourse_Enrollments,
					update_Enrollments,
					setUpdate_Enrollments,
					myOrders,
					setMyOrders,
					cookies,
					setCookie,
					setSearchResults,
					course_Accomplishments,
				}}
			>
				<BrowserRouter>
					<Switch>
						<Route
							path="/"
							exact={true}
							render={() => (
								<CoursesContext.Provider
									value={{
										course,
										handleAllCourses,
										allCourses,
										courseContent,
										findCourse,
										findCourseContent,
									}}
								>
									<TestimonialsContext.Provider
										value={{
											testimonials,
											handleTestimonials,
										}}
									>
										<BundlesContext.Provider
											value={{
												bundle,
												handleAllBundles,
												allBundles,
												findBundle,
											}}
										>
											{!!!sessionStorage.getItem("loader") ? <PreLoader /> : <Home />}
										</BundlesContext.Provider>
									</TestimonialsContext.Provider>
								</CoursesContext.Provider>
							)}
						/>
						<Route path="/signin" exact={true} render={() => <LoginPage rememberMe={rememberMe} handleRememberMe={handleRememberMe} />} />
						<Route
							path="/learn/:category"
							exact={true}
							render={(props) => (
								<CoursesContext.Provider
									value={{
										course,
										handleAllCourses,
										allCourses,
										courseContent,
										findCourse,
										findCourseContent,
									}}
								>
									<CoursesList {...props} />
								</CoursesContext.Provider>
							)}
						/>
						<Route
							path="/bundles"
							exact={true}
							render={(props) => (
								<BundlesContext.Provider
									value={{
										bundle,
										handleAllBundles,
										allBundles,
										findBundle,
									}}
								>
									<BundlesList {...props} />
								</BundlesContext.Provider>
							)}
						/>
						<Route
							path="/learn/course/:id"
							exact={true}
							render={(props) => (
								<CoursesContext.Provider
									value={{
										course,
										handleAllCourses,
										allCourses,
										courseContent,
										findCourse,
										findCourseContent,
									}}
								>
									<CourseDetail {...props} />
								</CoursesContext.Provider>
							)}
						/>
						<Route
							path="/bundles/:id"
							exact={true}
							render={(props) => (
								<BundlesContext.Provider
									value={{
										bundle,
										findBundle,
									}}
								>
									<BundleDetail {...props} />
								</BundlesContext.Provider>
							)}
						/>
						<PrivateRoutes
							path="/learn/mycourses/:id"
							component={CourseView}
							exact={true}
							onEnter={requireAuth}
							courseContent={courseContent}
							findCourseContent={findCourseContent}
							course={course}
							findCourse={findCourse}
							handleQuizData={handleQuizData}
						/>
						<PrivateRoutes path="/checkout" exact={true} component={Checkout} />
						<PrivateRoutes path="/cart" exact={true} component={CartPage} />
						<PrivateRoutes path="/quiz/:id" exact={true} component={MyQuiz} />
						<PrivateRoutes path="/viewquiz/:id" exact={true} component={ViewQuiz} />
						<Route
							path="/aboutus"
							exact={true}
							render={() => (
								<TestimonialsContext.Provider
									value={{
										testimonials,
										handleTestimonials,
									}}
								>
									<AboutUs ourTeam={ourTeam} />
								</TestimonialsContext.Provider>
							)}
						/>
						<Route path="/emailconfirm" exact={true} component={VerifyEmailConfirm} />
						<Route path="/testimonials" exact={true} render={() => <Testimonials handleTestimonials={handleTestimonials} testimonials={testimonials} />} />
						<Route path="/searchresults/:input" exact={true} render={(props) => <SearchPage {...props} searchResults={searchResults} setSearchResults={setSearchResults} />} />
						<Route path="/contactus" exact={true} component={ContactUs} />
						<Route path="/policiesandfaqs" exact={true} component={Policies} />
						<Route path="/honorcode" exact={true} render={() => <HonoCode honCode={honCode} />} />
						<Route path="/termsandconditions" exact={true} render={() => <TermsCond tncDoc={tncDoc} />} />
						<Route path="/cancellationandrefunds" exact={true} render={() => <CancRef cnrDoc={cnrDoc} />} />
						<Route path="/privacypolicy" exact={true} render={() => <PrivPol privpDoc={privpDoc} />} />
						<Route path="/faqs" exact={true} render={() => <Faqs faqDoc={faqDoc} />} />
						<Route path="/reportabug" exact={true} component={RepBug} />
						<Route path="/feedback" exact={true} component={Feedback} />
						<Route path="/auth/forgot-password" exact={true} component={PasswordReset} />
						<Route path="/auth/password/reset/confirm/:params" exact={true} render={() => <PasswordResetConfirm />} />
						<Route path="/auth/verify-email/:key" exact={true} render={() => <VerifyEmail />} />
						<Route path="/verify/:id" exact={true} component={VerifyCert} />
						<Route path="/verify" exact={true} component={VerifyCert} />
						<PrivateRoutes path="/profile/:option" component={Profile} exact={true} onEnter={requireAuth} badge={badge} />
						<PrivateRoutes path="/profile/orderdetail/:id" component={Profile} exact={true} onEnter={requireAuth} />
						<Route component={NotFound404} exact={true} />
					</Switch>
				</BrowserRouter>
			</BaseContext.Provider>
		</>
	);
};
export default Routes;
