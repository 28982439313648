import Breadcrumb from "../Components/Breadcrumb";
import React, { useContext, useState, useRef } from "react";
import Base from "../Base";
import CartCard from "../Components/CartCard";
import { CartContext } from "../Contexts/CartContext";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { BaseContext } from "../Context";
import { SingleEntityContext } from "../Contexts/SingleEntityContext";
import { useEffect } from "react";
import DataLoader from "../Components/DataLoaders/DataLoader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useHistory } from "react-router";
const CartPage = () => {
	const { cookies } = useContext(BaseContext);
	const { cartItems, allItemsTotalPrice, allItemsTotalDiscount, handleCheckout } = useContext(CartContext);
	const { handleSingleCheckout } = useContext(SingleEntityContext);
	const [loading, setLoading] = useState(true);
	const autoClose1 = useRef(null);
	const history = useHistory();
	const [finishStatus1, setfinishStatus1] = useState(false);
	useEffect(() => {
		handleSingleCheckout();
		setLoading(false);
	}, []);
	const confirmModal = () => {
		return confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert-body">
						<h1>Clear Cart</h1>
						<p className="colorblack mb-0">Do you really want to delete everything from your cart?</p>
						<div className="react-confirm-alert-button-group">
							<button
								onClick={() => {
									handleCheckout(cookies?.user?.[0]?.id);
									onClose();
								}}
							>
								Yes
							</button>
							<button ref={autoClose1} onClick={onClose}>
								No
							</button>
						</div>
					</div>
				);
			},
			onClickOutside: () => setfinishStatus1(false),
		});
	};
	useEffect(() => {
		const onBackButtonEvent = (e) => {
			e.preventDefault();
			if (finishStatus1) {
				autoClose1.current.click();
			}
			history.push(window.location.pathname);
		};
		window.addEventListener("popstate", onBackButtonEvent);
		return () => {
			window.removeEventListener("popstate", onBackButtonEvent);
		};
	}, [finishStatus1]);
	return (
		<>
			<Helmet>
				<title>International Lunar University | Cart</title>
			</Helmet>
			<Base>
				{loading ? (
					<DataLoader />
				) : (
					<>
						<Breadcrumb title="Cart" />
						<section className="section bgcolorgreyish">
							<div className="container">
								<div className="row">
									<div className="col-lg-12 px-3">
										{cartItems?.courses.filter((item) => item.userID === cookies?.user?.[0]?.id).length === 0 &&
										cartItems?.bundles.filter((item) => item.userID === cookies?.user?.[0]?.id).length === 0 ? (
											<div className="row justify-content-center mx-3">
												<div className="col-lg-6 text-center">
													<h2 className="mb-4 pt-2 text-center colorblack">Your cart is empty!</h2>
													<Link to="/learn/all" className="mb-5 fw-bold py-2 px-3 buynowbutton text-uppercase border5px d-inline-block">
														Browse courses now
													</Link>
													<img src="images/SVGs/Add_To_Cart.svg" className="loginsvg" alt="Nothing_In_Cart" />
												</div>
											</div>
										) : (
											<div className="row">
												<div className="col-lg-8 order-lg-1 mt-4 mt-lg-0 order-2 px-3">
													<div className="row">
														<div className="col-lg-12">
															{cartItems?.courses.filter((item) => item.userID === cookies?.user?.[0]?.id).length > 0 && (
																<div className="row mt-4">
																	<div className="col-md-12 text-center">
																		<h3 className="mb-2 colorblack pb-2 borderbottomcart">Courses In Your Cart</h3>
																		{cartItems?.courses
																			.filter((item) => item.userID === cookies?.user?.[0]?.id)
																			.map((item, index) => {
																				const { course } = item;
																				return <CartCard key={index} item={course} />;
																			})}
																	</div>
																</div>
															)}
															{cartItems?.bundles.filter((item) => item.userID === cookies?.user?.[0]?.id).length > 0 && (
																<div className="row mt-4">
																	<div className="col-md-12 text-center">
																		<h3 className="mb-2 colorblack pb-2 borderbottomcart">Bundles In Your Cart</h3>
																		{cartItems?.bundles
																			.filter((item) => item.userID === cookies?.user?.[0]?.id)
																			.map((item, index) => {
																				const { bundle } = item;
																				return <CartCard key={index} item={bundle} />;
																			})}
																	</div>
																</div>
															)}
															<div className="row mt-4">
																<div className="col-lg-6 mb-4 mb-md-0 text-center">
																	<Link to="/" className="fw-bold px-2 py-2 fontsize16 h-100 w-100 buynowbutton border5px text-uppercase">
																		Continue Exploring
																	</Link>
																</div>
																<div className="col-lg-6 text-center">
																	<span
																		onClick={() => {
																			setfinishStatus1(true);
																			confirmModal();
																		}}
																		className="fw-bold py-2 px-2 transitionease cursorpointer fontsize16 h-100 w-100 addtocartbutton border5px text-uppercase"
																	>
																		Clear Cart
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-lg-4 order-lg-2 order-1 mb-4">
													<div className="card position-sticky shadow sticky-bar border5px border-0">
														<div className="card-body p-3">
															<div className="row mx-2">
																<div className="col-12 p-0">
																	<div className="row my-2 mx-2">
																		<div className="col-12 text-center">
																			<h3 className="colorblack mb-3">Cart Total</h3>
																		</div>
																	</div>
																	<div className="row my-2 py-2 mx-2 borderbottomcart">
																		<div className="col-6 text-start">
																			<p className="fontsize16 mb-0 colorblack">Subtotal</p>
																		</div>
																		<div className="col-6 text-end pr-0">
																			<p className="fontsize16 mb-0 colorblack">
																				₹ {parseFloat(allItemsTotalPrice).toLocaleString(undefined, { maximumFractionDigits: 2 })}
																			</p>
																		</div>
																	</div>
																	<div className="row my-2 py-2 mx-2 borderbottomcart">
																		<div className="col-6 text-start">
																			<p className="fontsize16 mb-0 colorblack">Discount</p>
																		</div>
																		<div className="col-6 text-end pr-0">
																			<p className="fontsize16 mb-0 colorlightblue">
																				₹ {parseFloat(allItemsTotalDiscount).toLocaleString(undefined, { maximumFractionDigits: 2 })}
																			</p>
																		</div>
																	</div>
																	<div className="row my-2 py-2 mx-2 borderbottomcart">
																		<div className="col-6 text-start">
																			<p className="fontsize16 mb-0 colorblack">Total</p>
																		</div>
																		<div className="col-6 text-end pr-0">
																			<p className="fontsize16 mb-0 accepted">
																				₹&nbsp;
																				{Math.abs(parseFloat(allItemsTotalPrice).toFixed(2) - parseFloat(allItemsTotalDiscount).toFixed(2)).toLocaleString(
																					undefined,
																					{ maximumFractionDigits: 2 }
																				)}
																			</p>
																		</div>
																	</div>
																	<div className="row pt-4 pb-3 mx-2">
																		<div className="col-12 text-center">
																			<Link to="/checkout" className="fw-bold px-2 py-2 fontsize16 h-100 w-100 buynowbutton border5px text-uppercase">
																				CHECKOUT
																			</Link>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</section>
					</>
				)}
			</Base>
		</>
	);
};
export default CartPage;
