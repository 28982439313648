import { SubmitQuiz_API } from "../../backend";
export const submitQuiz = async (data, next) => {
	const tokenValue = localStorage.getItem("token").replace(/['"]+/g, "");
	return await fetch(`${SubmitQuiz_API}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Token " + tokenValue,
		},
		body: JSON.stringify(data),
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			next(data);
		})
		.catch((err) => console.log(err));
};
