import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
const CookiePolicyModal = () => {
	return (
		<>
			<CookieConsent
				location="bottom"
				cookieName="myAwesomeCookieName2"
				style={{ background: "#f2f4f6", color: "#000", fontSize: "16px", fontWeight: "600", zIndex: "9999" }}
				buttonClasses="darkbluetext fontsize14 fw-bold border5px bg-transparent bordercolordarkblue gradientbluehover"
				buttonText={"I understood"}
				expires={150}
			>
				By continuing browsing our site you agree with our&nbsp;
				<Link className="darkbluetext lightbluehover" to="/privacypolicy">
					Privacy & Cookie Policy
				</Link>
				&nbsp;and&nbsp;
				<Link className="darkbluetext lightbluehover" to="/termsandconditions">
					Terms &amp; Conditions
				</Link>
				.
			</CookieConsent>
		</>
	);
};
export default CookiePolicyModal;
