import { useState } from "react";
import { toast } from "react-toastify";
import CSRFToken from "../CSRFToken";
import { courseforumForm } from "../helpers/others/courseforumForm";
import DataLoader2 from "./DataLoaders/DataLoader2";
import tempImg2 from "../Assets/images/User_Image.webp";
const VideoDetailSection = (props) => {
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const courseforum = async (e) => {
		e.preventDefault();
		setLoading(true);
		await courseforumForm({ course_id: props.course.guid, message })
			.then((data) => {
				props.setUpdateForums(!props.updateForums);
				setLoading(false);
				setMessage("");
				if (data?.user) {
					return toast("Comment Posted!", { type: "success", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
				} else {
					return toast("Something went wrong!", { type: "warning", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};
	return (
		<>
			<ul className="nav d-flex justify-content-center nav-pills mb-3 borderbottomcart" id="pills-tab" role="tablist">
				<li className="nav-item" role="presentation">
					<button
						className="nav-link fontsize14 mybtnsame lightbluehover colorblack bgcolorwhite text-uppercase active"
						id="pills-Overview-tab"
						data-bs-toggle="pill"
						data-bs-target="#pills-Overview"
						type="button"
						role="tab"
						aria-controls="pills-Overview"
						aria-selected="true"
						style={{ border: "2.5px", borderRadius: "0px", borderRight: "2.5px dotted #cfcfcf" }}
					>
						Overview
					</button>
				</li>
				<li className="nav-item" role="presentation">
					<button
						className="nav-link fontsize14 mybtnsame lightbluehover colorblack bgcolorwhite text-uppercase"
						id="pills-Forum-tab"
						data-bs-toggle="pill"
						data-bs-target="#pills-Forum"
						type="button"
						role="tab"
						aria-controls="pills-Forum"
						aria-selected="false"
					>
						Forum
					</button>
				</li>
			</ul>
			<div className="tab-content" id="pills-tabContent">
				<div className="tab-pane fade show active text-center" id="pills-Overview" role="tabpanel" aria-labelledby="pills-Overview-tab">
					{props?.course?.course_description}
				</div>
				<div className="tab-pane fade" id="pills-Forum" role="tabpanel" aria-labelledby="pills-Forum-tab">
					<section className="feedback">
						<div className="row align-items-center">
							<div className="col-lg-12">
								<div className="card bgcolorgreyish border-0 border5px p-4">
									<form className="">
										<CSRFToken />
										<div className="row">
											<div className="col-lg-12">
												<div className="position-relative mb-4">
													<textarea
														className="colorblack bgcolorwhite p-3 border5px border-0 w-100"
														style={{ height: "150px", resize: "none" }}
														placeholder="Add your comment"
														value={message}
														onChange={(e) => {
															setMessage(e.target.value);
														}}
														required
													/>
												</div>
											</div>
											<div className="col-lg-12 text-center">
												<button
													disabled={loading ? true : false}
													className="mybtnsame fontsize16 bglightblue colorblack bgyellow border5px border-0 text-uppercase d-inline-block"
													type="submit"
													onClick={(e) => {
														courseforum(e);
													}}
													style={{ width: "35%" }}
												>
													{loading ? <DataLoader2 loaderSize={15} loaderType="ScaleLoader" loaderColor="#0007bf" /> : "Submit"}
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</section>
					{props?.course?.course_forums.length > 4 ? (
						props?.course?.course_forums.map((forum, index) => {
							return (
								<div key={index} className="row my-4">
									<div className="col-12">
										<div className="teacher d-flex align-items-center">
											<img src={forum?.user?.image ? forum.user.image : tempImg2} className="avatar-md-lg rounded-circle shadow" alt="User_Image" />
											<div className="ms-3">
												<h4 className="mb-1 colorblack lightbluehover">{forum.user?.first_name !== "" ? forum.user?.first_name : forum.user?.username}&nbsp;&nbsp;&nbsp;</h4>
												<p className="colorblack mb-0">{forum.message}</p>
											</div>
										</div>
									</div>
								</div>
							);
						})
					) : (
						<div className="row mt-5">
							<div className="col-lg-12 text-center">
								<h3 className="mb-5 pt-3 text-center colorblack">There are no comments to show as of now!</h3>
								<img width="250px" src="images/SVGs/No_Comments.svg" className="loginsvg" alt="No_Comments" />
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
export default VideoDetailSection;
