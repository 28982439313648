import tempImg2 from "../Assets/images/User_Image.webp";
const TestimonialsVideoCard = ({ testimonial }) => {
	var stars = [];
	var showStars = (number) => {
		for (var i = 0; i < number; i++) {
			stars.push(<i className="fas fa-star fontsize16 coloryellow" />);
		}
		return (
			<li key={i} className="list-inline-item">
				{stars}
			</li>
		);
	};
	function truncate(str, n) {
		return str?.length > n ? str.substr(0, n - 1) + "..." : str;
	}
	return (
		<>
			<div className="col-lg-6 col-md-6 col-12 mb-4 px-4 py-3">
				<div className="card hovergoup transitionease overflow-hidden shadow border5px border-0">
					{testimonial.file && (
						<div className="position-relative">
							<iframe
								className="w-100"
								height="315"
								src={`https://www.youtube.com/embed/${testimonial.file.split("/").slice(-1)[0]}`}
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe>
						</div>
					)}
					<div className="card-body content p-3 pt-2">
						<div className="d-flex justify-content-start align-items-center">
							<img src={testimonial?.user?.image ? testimonial.user.image : tempImg2} className="avatar-md-lg rounded-circle shadow m-2" alt="Profile_Image" />
							<div className="mb-2 ms-2">
								<h5 className="mb-0">
									<p className="darkbluetext mb-1">{testimonial.user.first_name}</p>
									{testimonial?.designation && <p className="darkbluetext fontsize12 mb-1">({`${truncate(testimonial?.designation, 130)}`})</p>}
									<ul className="list-unstyled mt-1 mb-0">{testimonial.rating > 5 ? showStars(5) : showStars(testimonial.rating)}</ul>
								</h5>
							</div>
						</div>
						<p className="colorblack mb-1 mt-2">{`"${truncate(testimonial.review, 130)}"`}</p>
					</div>
				</div>
			</div>
		</>
	);
};
export default TestimonialsVideoCard;
