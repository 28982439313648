import React, { useContext } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import { BundlesContext } from "../Context";
import Base from "../Base";
import { Helmet } from "react-helmet-async";
import BundlesListCard from "../Components/BundlesListCard";
const BundlesList = () => {
	const { allBundles } = useContext(BundlesContext);
	return (
		<>
			<Helmet>
				<title>International Lunar University | All Bundles</title>
			</Helmet>
			<Base>
				<Breadcrumb title="Bundles" />
				<section className="section">
					<div className="container">
						<div className="row">
							{allBundles.map((bundle, index) => {
								return <BundlesListCard key={index} bundle={bundle} />;
							})}
						</div>
					</div>
				</section>
			</Base>
		</>
	);
};
export default BundlesList;
