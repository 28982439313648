import { Bundles_API } from "../../backend";
export const bundles = async (next) => {
	await fetch(Bundles_API)
		.then((resp) => {
			return resp.json();
		})
		.then((data) => {
			next(data);
		})
		.catch((err) => console.log(err));
};
export const singleBundle = async (id, next) => {
	await fetch(`${Bundles_API}/${id}`)
		.then((resp) => {
			return resp.json();
		})
		.then((data) => {
			next(data);
		})
		.catch((err) => console.log(err));
};
