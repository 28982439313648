import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const Hero = () => {
	useEffect(() => {
		AOS.init({
			once: !0,
			duration: 1e3,
		});
	}, []);
	return (
        <>
            <section
                className="d-flex justify-content-center align-items-center overflow-hidden"
                style={{
                    backgroundImage: "url(/images/Hero_BG.webp)",
                    backgroundPosition: "top",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "95vh",
                    width: "100%",
                }}
            >
                <div className="row text-center">
                    <div
                        className="col-lg-12 px-4 colorwhite"
                        data-aos="zoom-in"
                        data-aos-delay={100}
                    >
                        <h1 className="mb-4" style={{ fontSize: "4em" }}>
                            International Lunar University
                            <br />
                            <span style={{ fontSize: "0.5em" }}>
                                Globus Unus, Luna Discimus
                            </span>
                        </h1>

                        <Link
                            to="/learn/all"
                            className="fw-bold py-2 px-3 addtocartbutton bg-white text-uppercase border5px d-inline-block"
                        >
                            LEARN NOW
                        </Link>
                        <h1 className="mt-3 d-flex justify-content-center">
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.internationallunaruniversity.com/contactus"
                                className="py-2 px-3 transitionease fontsize14 addtocartbutton bg-white text-uppercase border5px"
                            >
                                Support Our Work
                            </a>
                        </h1>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Hero;
