import { useState, useContext } from "react";
import { CartContext } from "../Contexts/CartContext";
import "hover.css";
import "animate.css";
import { Link } from "react-router-dom";
import tempImg2 from "../Assets/images/Course_Image.webp";
import { BaseContext } from "../Context";
import { useMediaQuery } from "react-responsive";
const CartCard = ({ item }) => {
	const [deleteToggle, setDeleteToggle] = useState(false);
	const { removeCourse, removeBundle } = useContext(CartContext);
	const { cookies } = useContext(BaseContext);
	const deletebutton = () => {
		if (item?.course_id) {
			setDeleteToggle(!deleteToggle);
			setTimeout(() => {
				removeCourse({ guid: item?.guid, userID: cookies?.user?.[0]?.id });
				setDeleteToggle(false);
			}, 900);
		} else if (item?.bundle_id) {
			setDeleteToggle(!deleteToggle);
			setTimeout(() => {
				removeBundle({ guid: item?.guid, userID: cookies?.user?.[0]?.id });
				setDeleteToggle(false);
			}, 900);
		}
	};
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	return item?.course_id ? (
		<>
			{isDesktopOrLaptop && (
				<div className={`${deleteToggle ? "row py-2 animate__animated animate__fadeOutLeft" : "row px-2 bgcolorwhite mb-2 border5px border-0 py-3"}`}>
					<div className="col-3 text-start">
						<Link to={`/learn/course/${item?.slug}`}>
							<img className="border5px shadow" src={item?.course_thumbnail || tempImg2} width="150px" height="150px" alt="Course_Image" />
						</Link>
					</div>
					<div className="col-8 d-flex flex-column justify-content-center text-start">
						<div className="row">
							<div className="col-lg-12">
								<Link className="colorblack fontsize16 darkbluetexthover" to={`${"/learn/course/"}${item?.slug}`}>
									{item?.course_title}
								</Link>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-lg-7 d-flex align-items-center">
								<p className="mb-0 colorblack fontsize16">
									{item?.course_discount > 0 ? (
										<>
											₹&nbsp;
											{(Math.abs(parseInt(item?.course_sp) - parseFloat(item?.course_sp)) > 0.5 ? parseInt(item?.course_sp) + 1 : parseInt(item?.course_sp)).toLocaleString(
												undefined,
												{ maximumFractionDigits: 2 }
											)}
											&nbsp;
											<span className="fontsize14 notaccepted text-decoration-line-through">₹ {item?.course_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
											<span className="colorlightblue fontsize16">
												&nbsp;
												{Math.abs(parseInt(item?.course_discount) - parseFloat(item?.course_discount)) > 0.5
													? parseInt(item?.course_discount) + 1
													: parseInt(item?.course_discount)}
												% OFF
											</span>
										</>
									) : (
										<>₹ {item?.course_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</>
									)}
								</p>
							</div>
						</div>
					</div>
					<div className="col-1 d-flex flex-column justify-content-center text-end">
						<button
							className="colorblack border-0 border5px bgcolorgreyish me-3"
							onClick={() => {
								deletebutton();
							}}
							style={{ width: 40, height: 40 }}
						>
							<i className="fas fa-times" />
						</button>
					</div>
				</div>
			)}
			{isTabletOrMobile && (
				<div className={`${deleteToggle ? "row py-2 animate__animated animate__fadeOutLeft" : "row px-2 bgcolorwhite mb-2 border5px border-0 py-3"}`}>
					<div className="col-4 text-start">
						<Link to={`/learn/course/${item?.slug}`}>
							<img className="border5px shadow" src={item?.course_thumbnail || tempImg2} width="100px" height="100px" alt="Course_Image" />
						</Link>
					</div>
					<div className="col-8 d-flex flex-column justify-content-center text-start">
						<div className="row">
							<div className="col-lg-12">
								<Link className="colorblack fontsize16 darkbluetexthover" to={`${"/learn/course/"}${item?.slug}`}>
									{item?.course_title}
								</Link>
							</div>
						</div>
						<div className="row mt-1">
							<div className="col-lg-12 d-flex align-items-center">
								<p className="mb-0 colorblack fontsize16">
									{item?.course_discount > 0 ? (
										<>
											₹&nbsp;
											{(Math.abs(parseInt(item?.course_sp) - parseFloat(item?.course_sp)) > 0.5 ? parseInt(item?.course_sp) + 1 : parseInt(item?.course_sp)).toLocaleString(
												undefined,
												{ maximumFractionDigits: 2 }
											)}
											&nbsp;
											<span className="fontsize14 notaccepted text-decoration-line-through">₹ {item?.course_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
											<span className="colorlightblue fontsize16">
												&nbsp;
												{Math.abs(parseInt(item?.course_discount) - parseFloat(item?.course_discount)) > 0.5
													? parseInt(item?.course_discount) + 1
													: parseInt(item?.course_discount)}
												% OFF
											</span>
										</>
									) : (
										<>₹ {item?.course_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</>
									)}
								</p>
							</div>
						</div>
						<div className="row mt-1">
							<div className="col-lg-12 d-flex justify-content-start">
								<button
									className="colorblack border-0 border5px bgcolorgreyish me-3"
									onClick={() => {
										deletebutton();
									}}
									style={{ width: 30, height: 30 }}
								>
									<i className="fas fa-times" />
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	) : (
		<>
			{isDesktopOrLaptop && (
				<div className={`${deleteToggle ? "row py-2 animate__animated animate__fadeOutLeft" : "row px-2 bgcolorwhite mb-2 border5px border-0 py-3"}`}>
					<div className="col-3 text-start">
						<Link to={`bundles/${item?.guid}`}>
							<img className="border5px shadow" src={item?.bundle_thumbnail || tempImg2} width="150px" height="150px" alt="Bundle_Image" />
						</Link>
					</div>
					<div className="col-8 d-flex flex-column justify-content-center text-start">
						<div className="row">
							<div className="col-lg-12">
								<Link className="colorblack fontsize16 darkbluetexthover" to={`/${"/bundles/"}${item?.slug}`}>
									{item?.bundle_title}
								</Link>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-lg-7 d-flex align-items-center">
								<p className="mb-0 colorblack fontsize16">
									{item?.bundle_discount > 0 ? (
										<>
											₹&nbsp;
											{(Math.abs(parseInt(item?.bundle_sp) - parseFloat(item?.bundle_sp)) > 0.5 ? parseInt(item?.bundle_sp) + 1 : parseInt(item?.bundle_sp)).toLocaleString(
												undefined,
												{ maximumFractionDigits: 2 }
											)}
											&nbsp;
											<span className="fontsize14 notaccepted text-decoration-line-through">₹ {item?.bundle_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
											<span className="colorlightblue fontsize16">
												&nbsp;
												{Math.abs(parseInt(item?.bundle_discount) - parseFloat(item?.bundle_discount)) > 0.5
													? parseInt(item?.bundle_discount) + 1
													: parseInt(item?.bundle_discount)}
												% OFF
											</span>
										</>
									) : (
										<>₹ {item?.bundle_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</>
									)}
								</p>
							</div>
						</div>
					</div>
					<div className="col-1 d-flex flex-column justify-content-center text-end">
						<button
							className="colorblack border-0 border5px bgcolorgreyish me-3"
							onClick={() => {
								deletebutton();
							}}
							style={{ width: 40, height: 40 }}
						>
							<i className="fas fa-times" />
						</button>
					</div>
				</div>
			)}
			{isTabletOrMobile && (
				<div className={`${deleteToggle ? "row py-2 animate__animated animate__fadeOutLeft" : "row px-2 bgcolorwhite mb-2 border5px border-0 py-3"}`}>
					<div className="col-4 text-start">
						<Link to={`bundles/${item?.guid}`}>
							<img className="border5px shadow" src={item?.bundle_thumbnail || tempImg2} width="100px" height="100px" alt="Bundle_Image" />
						</Link>
					</div>
					<div className="col-8 d-flex flex-column justify-content-center text-start">
						<div className="row">
							<div className="col-lg-12">
								<Link className="colorblack fontsize16 darkbluetexthover" to={`/${"/bundles/"}${item?.slug}`}>
									{item?.bundle_title}
								</Link>
							</div>
						</div>
						<div className="row mt-1">
							<div className="col-lg-12 d-flex align-items-center">
								<p className="mb-0 colorblack fontsize16">
									{item?.bundle_discount > 0 ? (
										<>
											₹&nbsp;
											{(Math.abs(parseInt(item?.bundle_sp) - parseFloat(item?.bundle_sp)) > 0.5 ? parseInt(item?.bundle_sp) + 1 : parseInt(item?.bundle_sp)).toLocaleString(
												undefined,
												{ maximumFractionDigits: 2 }
											)}
											&nbsp;
											<span className="fontsize14 notaccepted text-decoration-line-through">₹ {item?.bundle_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
											<span className="colorlightblue fontsize16">
												&nbsp;
												{Math.abs(parseInt(item?.bundle_discount) - parseFloat(item?.bundle_discount)) > 0.5
													? parseInt(item?.bundle_discount) + 1
													: parseInt(item?.bundle_discount)}
												% OFF
											</span>
										</>
									) : (
										<>₹ {item?.bundle_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</>
									)}
								</p>
							</div>
						</div>
						<div className="row mt-1">
							<div className="col-lg-12 d-flex justify-content-start">
								<button
									className="colorblack border-0 border5px bgcolorgreyish me-3"
									onClick={() => {
										deletebutton();
									}}
									style={{ width: 30, height: 30 }}
								>
									<i className="fas fa-times" />
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
export default CartCard;
