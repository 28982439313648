import Breadcrumb from "../Components/Breadcrumb";
import TestimonialsVideoCard from "../Components/TestimonialsVideoCard";
import { testimonialsData } from "../data/others/testimonials";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Base from "../Base";
const Testimonials = ({ handleTestimonials, testimonials }) => {
	useEffect(() => {
		testimonialsData((data) => {
			handleTestimonials(data);
		});
	}, []);
	return (
		<>
			<Helmet>
				<title>International Lunar University | Testimonials</title>
			</Helmet>
			<Base>
				<Breadcrumb title="Testimonials" />
				<section className="section overflow-hidden">
					<div className="container">
						<div className="row">
							{testimonials?.length <= 0 ? (
								<div className="row mt-2">
									<div className="col-lg-12 text-center">
										<h3 className="mb-5 mb-0 pt-3 text-center colorblack">No testimonials have been added by the administrator yet!</h3>
										<img width="300px" src="images/SVGs/Feedback.svg" className="loginsvg" alt="No_Testimonials" />
									</div>
								</div>
							) : (
								<>
									{testimonials?.map((testimonial, index) => {
										return <TestimonialsVideoCard key={index} testimonial={testimonial} />;
									})}
								</>
							)}
						</div>
					</div>
				</section>
			</Base>
		</>
	);
};
export default Testimonials;
