import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useContext, useState } from "react";
import Breadcrumb from "../../Components/Breadcrumb";
import { Helmet } from "react-helmet-async";
import Base from "../../Base";
import { WishlistContext } from "../../Contexts/WishlistContext";
import ProfileWishlistCard from "../../Components/ProfileWishlistCard";
import { BaseContext } from "../../Context";
import tempImg from "../../Assets/images/Course_Image.webp";
import { profileDataUpdate } from "../../data/users/profileData";
import CSRFToken from "../../CSRFToken";
import { toast } from "react-toastify";
import { passwordChange } from "../../helpers/auth/passChange";
import { emailChange } from "../../helpers/auth/emailChange";
import MyOrderCard from "../../Components/MyOrdersCard";
import OrderDetailCard from "../../Components/OrderDetailCard";
import DataLoader2 from "../../Components/DataLoaders/DataLoader2";
import ProfileWishlistCardBundle from "../../Components/ProfileWishlistCardBundle";
import ProfileCourseCard from "../../Components/ProfileCourseCard";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMediaQuery } from "react-responsive";
import { CopyToClipboard } from "react-copy-to-clipboard";
const Profile = (props) => {
	const { coursesCount, bundlesCount, wishlistItems } = useContext(WishlistContext);
	const { course_Enrollments, cookies, setCookie, myOrders, course_Accomplishments, handleNotification } = useContext(BaseContext);
	const [profile, setProfile] = useState(cookies?.user?.[0]);
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [imageChanged, setImageChanged] = useState(false);
	const [toggle, setToggle] = useState(false);
	const handleProfileUpdate = (event, data) => {
		event.preventDefault();
		const uploadData = new FormData();
		for (const key in profile) {
			if (key == "email") {
				uploadData.append(key, profile[key].toLowerCase());
			} else {
				uploadData.append(key, profile[key]);
			}
		}
		for (let [key, value] of uploadData) {
			if (key === "image") {
				!imageChanged && uploadData.set(key, "");
			}
		}
		profileDataUpdate(uploadData, (d) => {
			setToggle(!toggle);
			setCookie("user", d, { path: "/" });
			return toast("Your profile has been updated.", {
				type: "success",
				autoClose: 5000,
				position: "bottom-center",
				hideProgressBar: false,
				pauseOnHover: true,
				pauseOnFocusLoss: true,
			});
		});
		setImageChanged(false);
	};
	const [image, setImage] = useState("");
	const [originalImage, setOriginalImage] = useState(cookies?.user?.[0].image);
	const photoUpload = async (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setImage(file);
			setImageChanged(true);
			setOriginalImage(reader.result);
		};
		reader.readAsDataURL(file);
	};
	useEffect(() => {
		image !== "" &&
			setProfile({
				...profile,
				image: image,
			});
	}, [image]);
	const history = useHistory();
	const [oldPassword, setOldPassword] = useState("");
	const [password1, setpassword1] = useState("");
	const [password2, setpassword2] = useState("");
	const [showOldPassword, setShowOldPassword] = useState(false);
	const seeOldPassword = () => {
		setShowOldPassword(!showOldPassword);
	};
	const [showPassword1, setShowPassword1] = useState(false);
	const seePassword1 = () => {
		setShowPassword1(!showPassword1);
	};
	const [showPassword2, setShowPassword2] = useState(false);
	const seePassword2 = () => {
		setShowPassword2(!showPassword2);
	};
	const changePassword = (e) => {
		e.preventDefault();
		passwordChange(
			{
				old_password: oldPassword,
				new_password1: password1,
				new_password2: password2,
			},
			(data) => {
				setOldPassword("");
				setpassword1("");
				setpassword2("");
				if (data?.detail) {
					setLoading(false);
					return toast(data.detail, {
						type: "success",
						autoClose: 5000,
						position: "bottom-center",
						hideProgressBar: false,
						pauseOnHover: true,
						pauseOnFocusLoss: true,
					});
				}
				if (data?.old_password) {
					setLoading(false);
					return toast(data.old_password[0], {
						type: "error",
						autoClose: 5000,
						position: "bottom-center",
						hideProgressBar: false,
						pauseOnHover: true,
						pauseOnFocusLoss: true,
					});
				}
				if (data?.new_password1) {
					setLoading(false);
					return toast(data.new_password1[0], {
						type: "error",
						autoClose: 5000,
						position: "bottom-center",
						hideProgressBar: false,
						pauseOnHover: true,
						pauseOnFocusLoss: true,
					});
				}
				if (data?.new_password2) {
					setLoading(false);
					return toast(data.new_password2[0], {
						type: "error",
						autoClose: 5000,
						position: "bottom-center",
						hideProgressBar: false,
						pauseOnHover: true,
						pauseOnFocusLoss: true,
					});
				}
			}
		);
	};
	const [newEmail1, setNewEmail1] = useState("");
	const [newEmail2, setNewEmail2] = useState("");
	const changeEmail = (e) => {
		e.preventDefault();
		emailChange(
			{
				newemail1: newEmail1.toLowerCase(),
				newemail2: newEmail2.toLowerCase(),
			},
			(data) => {
				setNewEmail1("");
				setNewEmail2("");
				if (data?.detail) {
					setLoading(false);
					return toast(data.detail, {
						type: "success",
						autoClose: 5000,
						position: "bottom-center",
						hideProgressBar: false,
						pauseOnHover: true,
						pauseOnFocusLoss: true,
					});
				}
				if (data?.newemail1) {
					setLoading(false);
					return toast(data.newemail1[0], {
						type: "error",
						autoClose: 5000,
						position: "bottom-center",
						hideProgressBar: false,
						pauseOnHover: true,
						pauseOnFocusLoss: true,
					});
				}
				if (data?.newemail2) {
					setLoading(false);
					return toast(data.newemail2[0], {
						type: "error",
						autoClose: 5000,
						position: "bottom-center",
						hideProgressBar: false,
						pauseOnHover: true,
						pauseOnFocusLoss: true,
					});
				}
			}
		);
	};
	const [toggleClass, setToggleClass] = useState(true);
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	useEffect(() => {
		if (cookies?.user?.[0].is_social) {
			if (location.pathname.includes("changeemail") || location.pathname.includes("changepassword")) {
				history.push("/profile/account/");
			}
		}
	}, []);
	return (
		<>
			<Helmet>
				<title>International Lunar University | Your Account</title>
			</Helmet>
			<Base>
				<Breadcrumb title="Your Account" />
				<section className="section pt-4">
					<div className="container">
						<div className="row mx-1">
							<div className="col-lg-6 px-lg-3 px-0">
								<div className="card border-0 border5px shadow">
									<div className="card-body">
										<div className="row align-items-center">
											<div className="col-lg-3 pe-0 d-flex justify-content-center justify-content-lg-start">
												<label htmlFor="photo-upload" className="custom-file-upload fas">
													<div className="img-wrap img-upload">
														<img for="photo-upload" src={originalImage} alt="Profile_Pic" />
													</div>
													<input className="d-none" id="photo-upload" type="file" onChange={photoUpload} />
												</label>
											</div>
											<div className="col-lg-9">
												<div className="row">
													<div className="mt-3 mt-lg-0 text-center text-lg-start">
														<h4 className="colorblack mb-1">
															{`${
																cookies.user[0].first_name !== "" || cookies.user[0].last_name !== ""
																	? cookies.user[0].first_name + " " + cookies.user[0].last_name
																	: cookies.user[0].username
															}`}
														</h4>
														<p className="colorblack mb-1">{cookies.user[0].email || "No Email Entered!"}</p>
														{imageChanged && (
															<button
																className="mybtnsame mt-2 fontsize14 bglightblue colorblack bgyellow border5px border-0 text-uppercase"
																onClick={(e) => {
																	handleProfileUpdate(e, profile);
																}}
															>
																Save
															</button>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-6 mt-4 mt-lg-0 px-lg-0 px-0">
								<div className="card border-0 h-100 border5px shadow">
									<div className="card-body">
										<div className="row align-items-center">
											<div className="col-lg-3 pe-0 d-flex justify-content-center justify-content-lg-start">
												<img className="img-wrap overflow-visible" src={props.badge.badge} alt="Badge_Pic" />
											</div>
											<div className="col-lg-9">
												<div className="row">
													<div className="mt-3 mt-lg-0 text-center text-lg-start">
														<h4 className="colorblack mb-1">{props.badge.experience}</h4>
														<p className="colorblack mb-1">Experience Points</p>
														<p className="colorblack mb-1">
															{props.badge.experience < 100
																? "Next badge unlocks at 100 points!"
																: props.badge.experience >= 100 && props.badge.experience < 200
																? "Next badge unlocks at 200 points!"
																: props.badge.experience >= 200 && props.badge.experience < 300
																? "Next badge unlocks at 300 points!"
																: props.badge.experience >= 300 && props.badge.experience < 500
																? "Next badge unlocks at 500 points!"
																: props.badge.experience >= 500
																? "This is the final badge!"
																: ""}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row mx-1 mt-4">
							<div className="col-lg-4 px-lg-3 px-0 col-12">
								<div className="p-3 mt-4 mt-lg-0 sticky-bar position-sticky border5px shadow">
									<ul className="nav d-block nav-pills" id="pills-tab" role="tablist">
										<li className="nav-item" role="presentation">
											<button
												className={`nav-link fontsize14 p-0 py-2 darkbluetexthover colorblack bgcolorwhite text-uppercase ${
													props.match.params.option === "account" && "active"
												}`}
												id="pills-account-tab"
												data-bs-toggle="pill"
												data-bs-target="#pills-account"
												type="button"
												role="tab"
												aria-controls="pills-account"
												aria-selected={`${props.match.params.option === "account" ? "true" : "false"}`}
												onClick={() => {
													history.push("/profile/account");
												}}
											>
												<i className="fas fa-user" />
												&nbsp;&nbsp;Account
											</button>
										</li>
										<li className="nav-item" role="presentation">
											<button
												className={`nav-link fontsize14 p-0 py-2 darkbluetexthover colorblack bgcolorwhite text-uppercase ${
													props.match.params.option === "mycourses" && "active"
												}`}
												id="pills-mycourses-tab"
												data-bs-toggle="pill"
												data-bs-target="#pills-mycourses"
												type="button"
												role="tab"
												aria-controls="pills-mycourses"
												aria-selected={`${props.match.params.option === "mycourses" ? "true" : "false"}`}
												onClick={() => {
													history.push("/profile/mycourses");
												}}
											>
												<i className="fas fa-book-reader" />
												&nbsp;&nbsp;My Courses
											</button>
										</li>
										<li className="nav-item" role="presentation">
											<button
												className={`nav-link fontsize14 p-0 py-2 darkbluetexthover colorblack bgcolorwhite text-uppercase ${
													props.match.params.option === "coursewishlist" && "active"
												}`}
												id="pills-coursewishlist-tab"
												data-bs-toggle="pill"
												data-bs-target="#pills-coursewishlist"
												type="button"
												role="tab"
												aria-controls="pills-coursewishlist"
												aria-selected={`${props.match.params.option === "coursewishlist" ? "true" : "false"}`}
												onClick={() => {
													history.push("/profile/coursewishlist");
												}}
											>
												<i className="fas fa-box-heart" />
												{coursesCount > 0 && <span className="topnumbercart">{coursesCount}</span>}&nbsp;&nbsp;Course Wishlist
											</button>
										</li>
										<li className="nav-item" role="presentation">
											<button
												className={`nav-link fontsize14 p-0 py-2 darkbluetexthover colorblack bgcolorwhite text-uppercase ${
													props.match.params.option === "bundlewishlist" && "active"
												}`}
												id="pills-bundlewishlist-tab"
												data-bs-toggle="pill"
												data-bs-target="#pills-bundlewishlist"
												type="button"
												role="tab"
												aria-controls="pills-bundlewishlist"
												aria-selected={`${props.match.params.option === "bundlewishlist" ? "true" : "false"}`}
												onClick={() => {
													history.push("/profile/bundlewishlist");
												}}
											>
												<i className="fas fa-box-heart" />
												{bundlesCount > 0 && <span className="topnumbercart">{bundlesCount}</span>}&nbsp;&nbsp;Bundle Wishlist
											</button>
										</li>
										<li className="nav-item" role="presentation">
											<button
												className={`nav-link fontsize14 p-0 py-2 darkbluetexthover colorblack bgcolorwhite text-uppercase ${
													props.match.params.option === "accomplishments" && "active"
												}`}
												id="pills-accomplishments-tab"
												data-bs-toggle="pill"
												data-bs-target="#pills-accomplishments"
												type="button"
												role="tab"
												aria-controls="pills-accomplishments"
												aria-selected={`${props.match.params.option === "accomplishments" ? "true" : "false"}`}
												onClick={() => {
													history.push("/profile/accomplishments");
												}}
											>
												<i className="fas fa-stars" />
												&nbsp;&nbsp;Accomplishments
											</button>
										</li>
										<li className="nav-item" role="presentation">
											<button
												className={`nav-link fontsize14 p-0 py-2 darkbluetexthover colorblack bgcolorwhite text-uppercase ${
													(props.match.params.option === "myorders" || location.pathname.includes("orderdetail")) && "active"
												}`}
												id="pills-myorders-tab"
												data-bs-toggle="pill"
												data-bs-target="#pills-myorders"
												type="button"
												role="tab"
												aria-controls="pills-myorders"
												aria-selected={`${props.match.params.option === "myorders" ? "true" : "false"}`}
												onClick={() => {
													history.push("/profile/myorders");
												}}
											>
												<i className="fas fa-shopping-cart" />
												&nbsp;&nbsp;My Orders
											</button>
										</li>
										<li className="nav-item d-none" role="presentation">
											<button
												className={`nav-link fontsize14 p-0 py-2 darkbluetexthover colorblack bgcolorwhite text-uppercase ${
													props.match.params.option === "orderdetail" && "active"
												}`}
												id="pills-orderdetail-tab"
												data-bs-toggle="pill"
												data-bs-target="#pills-orderdetail"
												type="button"
												role="tab"
												aria-controls="pills-orderdetail"
												aria-selected={`${location.pathname.includes("orderdetail") ? "true" : "false"}`}
												onClick={() => {
													history.push("/profile/orderdetail");
												}}
											>
												<i className="fas fa-shopping-cart" />
												&nbsp;&nbsp;My Orders
											</button>
										</li>
										{!!!cookies?.user?.[0].is_social && (
											<>
												<li className="nav-item" role="presentation">
													<button
														className={`nav-link fontsize14 p-0 py-2 darkbluetexthover colorblack bgcolorwhite text-uppercase ${
															props.match.params.option === "changepassword" && "active"
														}`}
														id="pills-changepassword-tab"
														data-bs-toggle="pill"
														data-bs-target="#pills-changepassword"
														type="button"
														role="tab"
														aria-controls="pills-changepassword"
														aria-selected={`${props.match.params.option === "changepassword" ? "true" : "false"}`}
														onClick={() => {
															history.push("/profile/changepassword");
														}}
													>
														<i className="fas fa-lock" />
														&nbsp;&nbsp;Change Password
													</button>
												</li>

												<li className="nav-item" role="presentation">
													<button
														className={`nav-link fontsize14 p-0 py-2 darkbluetexthover colorblack bgcolorwhite text-uppercase ${
															props.match.params.option === "changeemail" && "active"
														}`}
														id="pills-changeemail-tab"
														data-bs-toggle="pill"
														data-bs-target="#pills-changeemail"
														type="button"
														role="tab"
														aria-controls="pills-changeemail"
														aria-selected={`${props.match.params.option === "changeemail" ? "true" : "false"}`}
														onClick={() => {
															history.push("/profile/changeemail");
														}}
													>
														<i className="fas fa-envelope" />
														&nbsp;&nbsp;Change Email
													</button>
												</li>
											</>
										)}
									</ul>
								</div>
							</div>
							<div className="col-lg-8 px-0 mt-lg-0 mt-3">
								<div className="tab-content" id="pills-tabContent">
									<div
										className={`tab-pane fade text-center bgcolorgreyish border5px p-3 ${props.match.params.option === "account" && "show active"}`}
										id="pills-account"
										role="tabpanel"
										aria-labelledby="pills-account-tab"
									>
										<form>
											<div className="row">
												<div className="col-lg-12 mb-3">
													<h5 className="colorblack text-start mb-2 fontsize14">Username</h5>
													<input
														value={profile?.username || ""}
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="text"
														placeholder="Username"
														onChange={(e) => {
															setProfile({
																...profile,
																username: e.target.value,
															});
														}}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-6 mb-3">
													<h5 className="colorblack text-start mb-2 fontsize14">First Name</h5>
													<input
														value={profile?.first_name || ""}
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="text"
														placeholder="First Name"
														onChange={(e) => {
															setProfile({
																...profile,
																first_name: e.target.value,
															});
														}}
													/>
												</div>
												<div className="col-lg-6 mb-3">
													<h5 className="colorblack text-start mb-2 fontsize14">Last Name</h5>
													<input
														value={profile?.last_name || ""}
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="text"
														placeholder="Last Name"
														onChange={(e) => {
															setProfile({
																...profile,
																last_name: e.target.value,
															});
														}}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-6 mb-3">
													<h5 className="colorblack text-start mb-2 fontsize14">Date Of Birth</h5>
													<input
														value={profile?.dob || ""}
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="date"
														onChange={(e) => {
															setProfile({
																...profile,
																dob: e.target.value,
															});
														}}
													/>
												</div>
												<div className="col-lg-6 mb-3">
													<h5 className="colorblack text-start mb-2 fontsize14">Gender</h5>
													<select
														value={profile?.gender || ""}
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														placeholder="Gender"
														onChange={(e) => {
															setProfile({
																...profile,
																gender: e.target.value,
															});
														}}
													>
														<option value="Prefer Not To Say">Prefer Not To Say</option>
														<option value="Male">Male</option>
														<option value="Female">Female</option>
														<option value="Non-Binary">Non-Binary</option>
														<option value="Other">Other</option>
													</select>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-12 mb-3">
													<h5 className="colorblack text-start mb-2 fontsize14">Mobile Number</h5>
													<PhoneInput
														inputClass="input100 w-100 shadow-none border5px pe-5 border-0 colorblack"
														buttonClass="border5px border-0 ps-2 colorblack bgcolorwhite"
														inputStyle={{ height: "50px" }}
														specialLabel={""}
														country={"in"}
														value={profile?.mobile || ""}
														onChange={(value) => {
															setProfile({
																...profile,
																mobile: value,
															});
														}}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-12 mb-3">
													<h5 className="colorblack text-start mb-2 fontsize14">Address</h5>
													<input
														value={profile?.address_line_1 === " " ? "" : profile?.address_line_1}
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="text"
														placeholder="Address"
														onChange={(e) => {
															setProfile({
																...profile,
																address_line_1: e.target.value,
															});
														}}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-6 mb-3">
													<h5 className="colorblack text-start mb-2 fontsize14">Pincode</h5>
													<input
														value={profile?.pin_code || ""}
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="number"
														placeholder="Pincode"
														onChange={(e) => {
															setProfile({
																...profile,
																pin_code: e.target.value,
															});
														}}
													/>
												</div>
												<div className="col-lg-6 mb-3">
													<h5 className="colorblack text-start mb-2 fontsize14">City</h5>
													<input
														value={profile?.city === " " ? "" : profile?.city}
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="text"
														placeholder="Town / City"
														onChange={(e) => {
															setProfile({
																...profile,
																city: e.target.value,
															});
														}}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-6 mb-3">
													<h5 className="colorblack text-start mb-2 fontsize14">State</h5>
													<input
														value={profile?.state === " " ? "" : profile?.state}
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="text"
														placeholder="State"
														onChange={(e) => {
															setProfile({
																...profile,
																state: e.target.value,
															});
														}}
													/>
												</div>
												<div className="col-lg-6 mb-3">
													<h5 className="colorblack text-start mb-2 fontsize14">Country</h5>
													<input
														value={profile?.country === " " ? "" : profile?.country}
														className="input100 w-100 border5px ps-3 border-0 colorblack"
														type="text"
														placeholder="Country"
														onChange={(e) => {
															setProfile({
																...profile,
																country: e.target.value,
															});
														}}
													/>
												</div>
											</div>
											<button
												className="mt-2 mybtnsame fontsize16 w-100 buynowbutton border5px text-uppercase"
												onClick={(e) => {
													handleProfileUpdate(e, profile);
												}}
											>
												Update
											</button>
										</form>
									</div>
									<div
										className={`tab-pane fade text-center bgcolorgreyish border5px p-4 pt-0 ${props.match.params.option === "mycourses" && "show active"}`}
										id="pills-mycourses"
										role="tabpanel"
										aria-labelledby="pills-mycourses-tab"
									>
										{course_Enrollments?.length <= 0 || course_Enrollments.error ? (
											<div className="row mt-2">
												<div className="col-lg-12">
													<h3 className="mb-4 pt-3 text-center colorblack">Looks like you haven't bought any courses, until now!</h3>
													<img width="250px" src="images/SVGs/My_Courses.svg" className="loginsvg" alt="No_Courses_Bought" />
												</div>
											</div>
										) : (
											<div className="row justify-content-center">
												{course_Enrollments.map((course, index) => {
													return <ProfileCourseCard course={course} toggleClass={toggleClass} key={index} />;
												})}
											</div>
										)}
									</div>
									<div
										className={`tab-pane fade text-center bgcolorgreyish border5px p-3 ${props.match.params.option === "coursewishlist" && "show active"}`}
										id="pills-coursewishlist"
										role="tabpanel"
										aria-labelledby="pills-coursewishlist-tab"
									>
										{wishlistItems?.courses.length <= 0 ? (
											<div className="row mt-2">
												<div className="col-lg-12">
													<h3 className="text-center colorblack">Your wishlist is empty!</h3>
													<h6>
														<Link to="/learn/all" className="fw-bold fontsize14 my-3 py-2 px-3 buynowbutton text-uppercase border5px d-inline-block">
															Browse courses now
														</Link>
													</h6>
													<img width="250px" src="images/SVGs/Bookmarked_Courses.svg" className="mt-4 loginsvg" alt="No_Items_In_Wishlist" />
												</div>
											</div>
										) : (
											<>
												{wishlistItems?.courses
													.filter((item) => item.userID === cookies?.user?.[0]?.id)
													.map((item, index) => {
														const { course } = item;
														return <ProfileWishlistCard key={index} item={course} />;
													})}
											</>
										)}
									</div>
									<div
										className={`tab-pane fade text-center bgcolorgreyish border5px p-3 ${props.match.params.option === "bundlewishlist" && "show active"}`}
										id="pills-bundlewishlist"
										role="tabpanel"
										aria-labelledby="pills-bundlewishlist-tab"
									>
										{wishlistItems?.bundles.length <= 0 ? (
											<div className="row mt-2">
												<div className="col-lg-12">
													<h3 className="text-center colorblack">Your wishlist is empty!</h3>
													<h6>
														<Link to="/learn/all" className="fw-bold fontsize14 my-3 py-2 px-3 buynowbutton text-uppercase border5px d-inline-block">
															Browse bundles now
														</Link>
													</h6>
													<img width="250px" src="images/SVGs/Bookmarked_Courses.svg" className="mt-4 loginsvg" alt="No_Items_In_Wishlist" />
												</div>
											</div>
										) : (
											<>
												{wishlistItems?.bundles
													.filter((item) => item.userID === cookies?.user?.[0]?.id)
													.map((item, index) => {
														const { bundle } = item;
														return <ProfileWishlistCardBundle key={index} item={bundle} />;
													})}
											</>
										)}
									</div>
									<div
										className={`tab-pane fade text-center bgcolorgreyish border5px p-3 ${props.match.params.option === "accomplishments" && "show active"}`}
										id="pills-accomplishments"
										role="tabpanel"
										aria-labelledby="pills-accomplishments-tab"
									>
										{course_Accomplishments.length <= 0 ? (
											<div className="row mt-2">
												<div className="col-lg-12">
													<h3 className="text-center colorblack">Let’s start learning!</h3>
													<h6>
														<Link to="/learn/all" className="fw-bold fontsize14 my-3 py-2 px-3 buynowbutton text-uppercase border5px d-inline-block">
															LEARN NOW
														</Link>
													</h6>
													<img width="250px" src="images/SVGs/Accomplishments.svg" className="mt-4 loginsvg" alt="No_Accomplishments" />
												</div>
											</div>
										) : (
											<>
												<div className="row justify-content-center">
													{course_Accomplishments.map((course, index) => {
														return (
															<>
																{isDesktopOrLaptop && (
																	<div key={index} className="row px-2 py-3">
																		<div className="col-3">
																			<Link to={`/learn/mycourses/${course?.course?.guid}`} className="h-50">
																				<img
																					className="border5px shadow"
																					src={course?.course?.course_thumbnail || tempImg}
																					width="150px"
																					height="150px"
																					alt="Course_Image"
																				/>
																			</Link>
																		</div>
																		<div className="col-9 text-center flex-column d-flex justify-content-center">
																			<div className="row">
																				<div className="col-lg-12">
																					<Link to={`/learn/mycourses/${course?.course?.guid}`} className="colorblack fontsize16 darkbluetexthover">
																						{course?.course?.course_title}
																					</Link>
																				</div>
																			</div>
																			<div className="row">
																				<div className="col-lg-12">
																					<h6 className="colorblack text-center mt-3 mb-0">Certificate ID - {course?.certificate_id}</h6>
																				</div>
																			</div>
																			<div className="row">
																				<div className="col-lg-12 mt-4 d-flex justify-content-center">
																					<Link
																						to={`/learn/course/${course?.course?.slug}`}
																						className="fw-bold w-50 text-center fontsize14 px-2 py-2 addtocartbutton border5px text-uppercase"
																					>
																						Leave A Rating
																					</Link>
																					{course?.is_course_completed && (
																						<CopyToClipboard
																							options={{ debug: props.debug, message: course?.verify_url }}
																							onCopy={() => {
																								return toast("Link of the certificate is copied.", {
																									type: "success",
																									autoClose: 5000,
																									position: "bottom-center",
																									hideProgressBar: false,
																									pauseOnHover: true,
																									pauseOnFocusLoss: true,
																								});
																							}}
																							text={course?.verify_url}
																						>
																							<a
																								href={course?.certificate_url}
																								target="_blank"
																								alt="Download Certificate"
																								rel="noopener noreferrer"
																								className="ms-2 w-25 fw-bold text-center fontsize14 px-2 py-2 buynowbutton border5px text-uppercase"
																							>
																								<i className="fas fa-file-certificate" />
																							</a>
																						</CopyToClipboard>
																					)}
																				</div>
																			</div>
																		</div>
																	</div>
																)}
																{isTabletOrMobile && (
																	<div key={index} className="row mb-2 p-1">
																		<div className="col-4">
																			<Link to={`/learn/mycourses/${course?.course?.guid}`} className="h-50">
																				<img
																					className="border5px shadow"
																					src={course?.course?.course_thumbnail || tempImg}
																					width="100px"
																					height="100px"
																					alt="Course_Image"
																				/>
																			</Link>
																		</div>
																		<div className="col-8 pe-2 flex-column d-flex justify-content-center mb-2 text-center">
																			<div className="row">
																				<div className="col-lg-12">
																					<Link to={`/learn/mycourses/${course?.course?.guid}`} className="colorblack fontsize12 darkbluetexthover">
																						{course?.course?.course_title}
																					</Link>
																				</div>
																			</div>
																			<div className="row">
																				<div className="col-lg-12">
																					<h6 className="colorblack fontsize14 text-center mt-2 mb-0">Certificate ID - {course?.certificate_id}</h6>
																				</div>
																			</div>
																			<div className="row mt-3">
																				<div className="col-lg-12">
																					<Link
																						to={`/learn/course/${course?.course?.slug}`}
																						className="fw-bold w-75 fontsize12 px-2 py-2 addtocartbutton border5px text-uppercase"
																					>
																						Leave A Rating
																					</Link>
																					{course?.is_course_completed && (
																						<CopyToClipboard
																							options={{ debug: props.debug, message: course?.verify_url }}
																							onCopy={() => {
																								return toast("Link of the certificate is copied.", {
																									type: "success",
																									autoClose: 5000,
																									position: "bottom-center",
																									hideProgressBar: false,
																									pauseOnHover: true,
																									pauseOnFocusLoss: true,
																								});
																							}}
																							text={course?.verify_url}
																						>
																							<a
																								href={course?.certificate_url}
																								target="_blank"
																								alt="Download Certificate"
																								rel="noopener noreferrer"
																								className="ms-2 w-25 fw-bold text-center fontsize14 px-2 py-2 buynowbutton border5px text-uppercase"
																							>
																								<i className="fas fa-file-certificate" />
																							</a>
																						</CopyToClipboard>
																					)}
																				</div>
																			</div>
																		</div>
																	</div>
																)}
															</>
														);
													})}
												</div>
											</>
										)}
									</div>
									<div
										className={`tab-pane fade text-center bgcolorgreyish border5px p-3 ${props.match.params.option === "myorders" && "show active"}`}
										id="pills-myorders"
										role="tabpanel"
										aria-labelledby="pills-myorders-tab"
									>
										{myOrders?.length == 0 ? (
											<div className="row mt-2">
												<div className="col-lg-12">
													<h3 className="mb-4 pt-3 text-center colorblack">Looks like you haven't ordered anything, until now!</h3>
													<img width="250px" src="images/SVGs/My_Orders.svg" className="loginsvg" alt="No_Orders" />
												</div>
											</div>
										) : (
											<>
												{myOrders
													.filter((item) => item.is_paid === true)
													.map((my_order, index) => {
														return <MyOrderCard key={index} my_order={my_order} />;
													})}
											</>
										)}
									</div>
									<div
										className={`tab-pane fade text-center bgcolorgreyish border5px p-3 ${location.pathname.includes("orderdetail") && "show active"}`}
										id="pills-orderdetail"
										role="tabpanel"
										aria-labelledby="pills-orderdetail-tab"
									>
										<OrderDetailCard id={props.match.params.id} />
									</div>
									<div
										className={`tab-pane fade text-center bgcolorgreyish border5px p-3 ${props.match.params.option === "changepassword" && "show active"}`}
										id="pills-changepassword"
										role="tabpanel"
										aria-labelledby="pills-changepassword-tab"
									>
										<form>
											<CSRFToken />
											<div className="row">
												<div className="col-lg-12">
													<h5 className="colorblack text-start mb-2 fontsize14">Old Password</h5>
													<div className="position-relative mb-3">
														<input
															className="input100 w-100 border5px border-0 colorblack"
															type={showOldPassword ? "text" : "password"}
															placeholder="Old Password"
															value={oldPassword}
															onChange={(e) => {
																setOldPassword(e.target.value);
															}}
															required
														/>
														<span className="focus-input100" />
														<span className="symbol-input100 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className="fas fa-lock" />
															</span>
														</span>
														<span onClick={seeOldPassword} className="symbol-input1000 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className={showOldPassword ? "far fa-eye-slash" : "far fa-eye"} />
															</span>
														</span>
													</div>
													<h5 className="colorblack text-start mb-2 fontsize14">New Password</h5>
													<div className="position-relative mb-3">
														<input
															className="input100 w-100 border5px border-0 colorblack"
															type={showPassword1 ? "text" : "password"}
															placeholder="New Password"
															value={password1}
															onChange={(e) => {
																setpassword1(e.target.value);
															}}
															required
														/>
														<span className="focus-input100" />
														<span className="symbol-input100 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className="fas fa-lock" />
															</span>
														</span>
														<span onClick={seePassword1} className="symbol-input1000 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className={showPassword1 ? "far fa-eye-slash" : "far fa-eye"} />
															</span>
														</span>
													</div>
													<h5 className="colorblack text-start mb-2 fontsize14">Confirm New Password</h5>
													<div className="position-relative mb-3">
														<input
															className="input100 w-100 border5px border-0 colorblack"
															type={showPassword2 ? "text" : "password"}
															placeholder="Confirm New Password"
															value={password2}
															onChange={(e) => {
																setpassword2(e.target.value);
															}}
															required
														/>
														<span className="focus-input100" />
														<span className="symbol-input100 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className="fas fa-lock" />
															</span>
														</span>
														<span onClick={seePassword2} className="symbol-input1000 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className={showPassword2 ? "far fa-eye-slash" : "far fa-eye"} />
															</span>
														</span>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-12 mb-0">
													<div className="d-grid">
														<button
															onClick={(e) => {
																setLoading(true);
																changePassword(e);
															}}
															className="fw-bold py-2 px-2 fontsize16 buynowbutton border5px text-uppercase d-inline-block"
															disabled={loading ? true : false}
														>
															{loading ? <DataLoader2 loaderSize={15} loaderType="ScaleLoader" loaderColor="#0007bf" /> : "Confirm"}
														</button>
													</div>
												</div>
											</div>
										</form>
									</div>
									<div
										className={`tab-pane fade text-center bgcolorgreyish border5px p-3 ${props.match.params.option === "changeemail" && "show active"}`}
										id="pills-changeemail"
										role="tabpanel"
										aria-labelledby="pills-changeemail-tab"
									>
										<p className="colorblack text-start mb-4">
											Please verify your email before submission. If the wrong email is provided you will not be able to login again. In such an event, you can contact
											<a className="fw-bold darkbluetext" target="_blank" rel="noreferrer noopener">
												&nbsp;ashutosh.mishra@community.isunet.edu&nbsp;
											</a>
											for account recovery.
										</p>
										<form>
											<CSRFToken />
											<div className="row">
												<div className="col-lg-12">
													<h5 className="colorblack text-start mb-2 fontsize14">Old Email</h5>
													<div className="position-relative mb-3">
														<input className="input100 w-100 border5px border-0 colorblack" defaultValue={profile?.email} type="email" placeholder="Old Email" required />
														<span className="focus-input100" />
														<span className="symbol-input100 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className="fas fa-envelope" />
															</span>
														</span>
													</div>
													<h5 className="colorblack text-start mb-2 fontsize14">New Email</h5>
													<div className="position-relative mb-3">
														<input
															className="input100 w-100 border5px border-0 colorblack"
															type="email"
															placeholder="New Email"
															value={newEmail1.toLowerCase()}
															onChange={(e) => {
																setNewEmail1(e.target.value);
															}}
															required
														/>
														<span className="focus-input100" />
														<span className="symbol-input100 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className="fas fa-envelope" />
															</span>
														</span>
													</div>
													<h5 className="colorblack text-start mb-2 fontsize14">Confirm New Email</h5>
													<div className="position-relative mb-3">
														<input
															className="input100 w-100 border5px border-0 colorblack"
															type="email"
															placeholder="Confirm New Email"
															value={newEmail2.toLowerCase()}
															onChange={(e) => {
																setNewEmail2(e.target.value);
															}}
															required
														/>
														<span className="focus-input100" />
														<span className="symbol-input100 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className="fas fa-envelope" />
															</span>
														</span>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-12 mb-0">
													<div className="d-grid">
														<button
															onClick={(e) => {
																setLoading(true);
																changeEmail(e);
															}}
															className="fw-bold py-2 px-2 fontsize16 buynowbutton border5px text-uppercase d-inline-block"
															disabled={loading ? true : false}
														>
															{loading ? <DataLoader2 loaderSize={15} loaderType="ScaleLoader" loaderColor="#0007bf" /> : "Confirm"}
														</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Base>
		</>
	);
};
export default Profile;
