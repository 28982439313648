import React, { useContext, useEffect, useState } from "react";
import DataLoader from "../Components/DataLoaders/DataLoader";
import { QuizResponseContext } from "../Contexts/QuizResponseContext";
import { courseContent } from "../data/courses/courses";
import { submitQuiz } from "../helpers/lms/submitQuiz";
import Breadcrumb from "../Components/Breadcrumb";
import Base from "../Base";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";
const MyQuiz = (props) => {
	const [contentID, setContentID] = useState(JSON.parse(localStorage.getItem("contentID"))?.contentID);
	const [chapterID, setChapterID] = useState(JSON.parse(localStorage.getItem("contentID"))?.chapterID);
	const [quizData, setQuizData] = useState({});
	const [loading, setLoading] = useState(true);
	const history = useHistory();
	const handleMSQChange = (event, question_guid) => {
		addResponse({
			question_guid,
			response: {
				name: [event.target.name],
				checked: event.target.checked,
			},
		});
	};
	const { quizResponseValue, addResponse, addResponse2 } = useContext(QuizResponseContext);
	const handleChange = (type, value, question_guid) => {
		switch (type) {
			case "MCQ":
				addResponse2({ question_guid, response: value });
				break;
			case "NAT":
				addResponse2({ question_guid, response: value });
				break;
			default:
				break;
		}
	};
	const [courseID, setCourseID] = useState("");
	useEffect(() => {
		courseContent(contentID, (data) => {
			setQuizData(data.find((val) => val?.id === chapterID).quiz.find((q) => q.guid === props.match.params.id));
			setCourseID(data.find((val) => val?.id === chapterID).course.guid);
			setLoading(false);
		});
	}, []);
	const quizSubmit = async () => {
		await submitQuiz(
			{
				quiz_guid: quizData?.guid,
				questions: quizResponseValue,
				course_id: courseID,
			},
			(data) => {
				if (data?.details == "Submitted Successfully") localStorage.setItem("q_update", true);
				alert(data?.details);
				history.push(`/viewquiz/${props.match.params.id}`);
			}
		);
	};
	useEffect(() => {
		window.addEventListener("unload", closeQuiz);
		return () => {
			window.removeEventListener("unload", closeQuiz);
		};
	});
	const closeQuiz = (e) => {
		e.preventDefault();
		window.close();
	};
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	return (
		<>
			<Helmet>
				<title>International Lunar University | Quiz</title>
			</Helmet>
			<Base>
				<Breadcrumb title="Quiz" />
				{loading ? (
					<DataLoader />
				) : (
					<div className="section">
						<div className="container">
							<div className="row bgcolorgreyish mx-1 p-3 border5px">
								<div className="col-lg-12 bg-white p-3 border5px mb-4">
									<h2 className="mb-3 text-center colorblack">{quizData.quiz_title}</h2>
									<p className="fontsize14 colorlightblue text-center fw-bold mb-2">
										{quizData.total_marks} Marks | {quizData.duration / 60 < 1 ? `${quizData.duration} Seconds` : `${(quizData.duration / 60).toFixed(0)} Mintues`}
									</p>
									<p className="fontsize14 colorlightblue text-center fw-bold mb-0">
										MCQ - Multiple Choice Question | MSQ - Multiple Solution Question | NAT - Numerical Answer Type
									</p>
								</div>
								{isDesktopOrLaptop &&
									quizData.questions.map((ques, index) => {
										return (
											<div key={ques?.guid} className="col-lg-12 mb-5 ms-5 px-0">
												{ques?.question?.option_type === "Text" && (
													<p className="fontsize16 colorblack fw-bold mb-0">
														Q{index + 1}. {ques?.question?.text} [ {ques?.questype} ]
													</p>
												)}
												{ques?.question?.option_type === "Image" && (
													<p className="fontsize16 colorblack fw-bold mb-0">
														Q{index + 1}. <img className="border5px" src={ques?.question?.image} height="200px" width="200px" />
													</p>
												)}
												{ques?.question?.option_type === "Both" && (
													<>
														<p className="fontsize16 colorblack fw-bold mb-0">
															Q{index + 1}. {ques?.question?.text} [ {ques?.questype} ]
														</p>
														<img className="mt-3 border5px" src={ques?.question?.image} height="200px" width="200px" />
													</>
												)}
												{ques?.questype === "MCQ" && (
													<div
														onChange={(e) => {
															handleChange("MCQ", e.target.value, ques?.guid);
														}}
													>
														<input type="radio" className="d-none one" name={`MCQ_Question${ques?.guid}`} id={`${index}${ques?.option_a?.id}`} value="A" />
														<input type="radio" className="d-none two" name={`MCQ_Question${ques?.guid}`} id={`${index}${ques?.option_b?.id}`} value="B" />
														<input type="radio" className="d-none three" name={`MCQ_Question${ques?.guid}`} id={`${index}${ques?.option_c?.id}`} value="C" />
														<input type="radio" className="d-none four" name={`MCQ_Question${ques?.guid}`} id={`${index}${ques?.option_d?.id}`} value="D" />
														<label htmlFor={`${index}${ques?.option_a?.id}`} className="box first">
															<div className="course">
																<span className="circle" />
																{ques?.option_a?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_a?.text}</p>}
																{ques?.option_a?.option_type === "Image" && <img className="border5px" src={ques?.option_a?.image} height="200px" width="200px" />}
																{ques?.option_a?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_a?.text}</p>
																		<img className="border5px" src={ques?.option_a?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label htmlFor={`${index}${ques?.option_b?.id}`} className="box second">
															<div className="course">
																<span className="circle" />
																{ques?.option_b?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_b?.text}</p>}
																{ques?.option_b?.option_type === "Image" && <img className="border5px" src={ques?.option_b?.image} height="200px" width="200px" />}
																{ques?.option_b?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_b?.text}</p>
																		<img className="border5px" src={ques?.option_b?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label htmlFor={`${index}${ques?.option_c?.id}`} className="box third">
															<div className="course">
																<span className="circle" />
																{ques?.option_c?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_c?.text}</p>}
																{ques?.option_c?.option_type === "Image" && <img className="border5px" src={ques?.option_c?.image} height="200px" width="200px" />}
																{ques?.option_c?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_c?.text}</p>
																		<img className="border5px" src={ques?.option_c?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label htmlFor={`${index}${ques?.option_d?.id}`} className="box fourth">
															<div className="course">
																<span className="circle" />
																{ques?.option_d?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_d?.text}</p>}
																{ques?.option_d?.option_type === "Image" && <img className="border5px" src={ques?.option_d?.image} height="200px" width="200px" />}
																{ques?.option_d?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_d?.text}</p>
																		<img className="border5px" src={ques?.option_d?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
													</div>
												)}
												{ques?.questype === "MSQ" && (
													<>
														<input
															type="checkbox"
															className="d-none one"
															name="A"
															id={`${index}${ques?.option_a?.id}`}
															value="A"
															onChange={(e) => handleMSQChange(e, ques?.guid)}
														/>
														<input
															type="checkbox"
															className="d-none two"
															name="B"
															id={`${index}${ques?.option_b?.id}`}
															value="B"
															onChange={(e) => handleMSQChange(e, ques?.guid)}
														/>
														<input
															type="checkbox"
															className="d-none three"
															name="C"
															id={`${index}${ques?.option_c?.id}`}
															value="C"
															onChange={(e) => handleMSQChange(e, ques?.guid)}
														/>
														<input
															type="checkbox"
															className="d-none four"
															name="D"
															id={`${index}${ques?.option_d?.id}`}
															value="D"
															onChange={(e) => handleMSQChange(e, ques?.guid)}
														/>
														<label htmlFor={`${index}${ques?.option_a?.id}`} className="box first">
															<div className="course">
																<span className="circle" />
																{ques?.option_a?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_a?.text}</p>}
																{ques?.option_a?.option_type === "Image" && <img className="border5px" src={ques?.option_a?.image} height="200px" width="200px" />}
																{ques?.option_a?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_a?.text}</p>
																		<img className="border5px" src={ques?.option_a?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label htmlFor={`${index}${ques?.option_b?.id}`} className="box second">
															<div className="course">
																<span className="circle" />
																{ques?.option_b?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_b?.text}</p>}
																{ques?.option_b?.option_type === "Image" && <img className="border5px" src={ques?.option_b?.image} height="200px" width="200px" />}
																{ques?.option_b?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_b?.text}</p>
																		<img className="border5px" src={ques?.option_b?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label htmlFor={`${index}${ques?.option_c?.id}`} className="box third">
															<div className="course">
																<span className="circle" />
																{ques?.option_c?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_c?.text}</p>}
																{ques?.option_c?.option_type === "Image" && <img className="border5px" src={ques?.option_c?.image} height="200px" width="200px" />}
																{ques?.option_c?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_c?.text}</p>
																		<img className="border5px" src={ques?.option_c?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label htmlFor={`${index}${ques?.option_d?.id}`} className="box fourth">
															<div className="course">
																<span className="circle" />
																{ques?.option_d?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_d?.text}</p>}
																{ques?.option_d?.option_type === "Image" && <img className="border5px" src={ques?.option_d?.image} height="200px" width="200px" />}
																{ques?.option_d?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_d?.text}</p>
																		<img className="border5px" src={ques?.option_d?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
													</>
												)}
												{ques?.questype === "NAT" && (
													<div className="col-lg-12">
														<input
															className="input100 mt-3 px-3 py-2 w-50 border5px border-0 colorblack"
															type="text"
															placeholder="Enter your answer here."
															onChange={(e) => {
																handleChange("NAT", e.target.value, ques?.guid);
															}}
														/>
													</div>
												)}
											</div>
										);
									})}
								{isTabletOrMobile &&
									quizData.questions.map((ques, index) => {
										return (
											<div key={ques?.guid} className="col-lg-12 mb-5 px-0">
												{ques?.question?.option_type === "Text" && (
													<p className="fontsize16 colorblack fw-bold mb-0">
														Q{index + 1}. {ques?.question?.text} [ {ques?.questype} ]
													</p>
												)}
												{ques?.question?.option_type === "Image" && (
													<p className="fontsize16 colorblack fw-bold mb-0">
														Q{index + 1}. <img className="border5px" src={ques?.question?.image} height="200px" width="200px" />
													</p>
												)}
												{ques?.question?.option_type === "Both" && (
													<>
														<p className="fontsize16 colorblack fw-bold mb-0">
															Q{index + 1}. {ques?.question?.text} [ {ques?.questype} ]
														</p>
														<img className="mt-3 border5px" src={ques?.question?.image} height="200px" width="200px" />
													</>
												)}
												{ques?.questype === "MCQ" && (
													<div
														onChange={(e) => {
															handleChange("MCQ", e.target.value, ques?.guid);
														}}
													>
														<input type="radio" className="d-none one" name={`MCQ_Question${ques?.guid}`} id={`${index}${ques?.option_a?.id}`} value="A" />
														<input type="radio" className="d-none two" name={`MCQ_Question${ques?.guid}`} id={`${index}${ques?.option_b?.id}`} value="B" />
														<input type="radio" className="d-none three" name={`MCQ_Question${ques?.guid}`} id={`${index}${ques?.option_c?.id}`} value="C" />
														<input type="radio" className="d-none four" name={`MCQ_Question${ques?.guid}`} id={`${index}${ques?.option_d?.id}`} value="D" />
														<label htmlFor={`${index}${ques?.option_a?.id}`} className="box first">
															<div className="course">
																<span className="circle" />
																{ques?.option_a?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_a?.text}</p>}
																{ques?.option_a?.option_type === "Image" && <img className="border5px" src={ques?.option_a?.image} height="200px" width="200px" />}
																{ques?.option_a?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_a?.text}</p>
																		<img className="border5px" src={ques?.option_a?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label htmlFor={`${index}${ques?.option_b?.id}`} className="box second">
															<div className="course">
																<span className="circle" />
																{ques?.option_b?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_b?.text}</p>}
																{ques?.option_b?.option_type === "Image" && <img className="border5px" src={ques?.option_b?.image} height="200px" width="200px" />}
																{ques?.option_b?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_b?.text}</p>
																		<img className="border5px" src={ques?.option_b?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label htmlFor={`${index}${ques?.option_c?.id}`} className="box third">
															<div className="course">
																<span className="circle" />
																{ques?.option_c?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_c?.text}</p>}
																{ques?.option_c?.option_type === "Image" && <img className="border5px" src={ques?.option_c?.image} height="200px" width="200px" />}
																{ques?.option_c?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_c?.text}</p>
																		<img className="border5px" src={ques?.option_c?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label htmlFor={`${index}${ques?.option_d?.id}`} className="box fourth">
															<div className="course">
																<span className="circle" />
																{ques?.option_d?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_d?.text}</p>}
																{ques?.option_d?.option_type === "Image" && <img className="border5px" src={ques?.option_d?.image} height="200px" width="200px" />}
																{ques?.option_d?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_d?.text}</p>
																		<img className="border5px" src={ques?.option_d?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
													</div>
												)}
												{ques?.questype === "MSQ" && (
													<>
														<input
															type="checkbox"
															className="d-none one"
															name="A"
															id={`${index}${ques?.option_a?.id}`}
															value="A"
															onChange={(e) => handleMSQChange(e, ques?.guid)}
														/>
														<input
															type="checkbox"
															className="d-none two"
															name="B"
															id={`${index}${ques?.option_b?.id}`}
															value="B"
															onChange={(e) => handleMSQChange(e, ques?.guid)}
														/>
														<input
															type="checkbox"
															className="d-none three"
															name="C"
															id={`${index}${ques?.option_c?.id}`}
															value="C"
															onChange={(e) => handleMSQChange(e, ques?.guid)}
														/>
														<input
															type="checkbox"
															className="d-none four"
															name="D"
															id={`${index}${ques?.option_d?.id}`}
															value="D"
															onChange={(e) => handleMSQChange(e, ques?.guid)}
														/>
														<label htmlFor={`${index}${ques?.option_a?.id}`} className="box first">
															<div className="course">
																<span className="circle" />
																{ques?.option_a?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_a?.text}</p>}
																{ques?.option_a?.option_type === "Image" && <img className="border5px" src={ques?.option_a?.image} height="200px" width="200px" />}
																{ques?.option_a?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_a?.text}</p>
																		<img className="border5px" src={ques?.option_a?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label htmlFor={`${index}${ques?.option_b?.id}`} className="box second">
															<div className="course">
																<span className="circle" />
																{ques?.option_b?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_b?.text}</p>}
																{ques?.option_b?.option_type === "Image" && <img className="border5px" src={ques?.option_b?.image} height="200px" width="200px" />}
																{ques?.option_b?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_b?.text}</p>
																		<img className="border5px" src={ques?.option_b?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label htmlFor={`${index}${ques?.option_c?.id}`} className="box third">
															<div className="course">
																<span className="circle" />
																{ques?.option_c?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_c?.text}</p>}
																{ques?.option_c?.option_type === "Image" && <img className="border5px" src={ques?.option_c?.image} height="200px" width="200px" />}
																{ques?.option_c?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_c?.text}</p>
																		<img className="border5px" src={ques?.option_c?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
														<label htmlFor={`${index}${ques?.option_d?.id}`} className="box fourth">
															<div className="course">
																<span className="circle" />
																{ques?.option_d?.option_type === "Text" && <p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_d?.text}</p>}
																{ques?.option_d?.option_type === "Image" && <img className="border5px" src={ques?.option_d?.image} height="200px" width="200px" />}
																{ques?.option_d?.option_type === "Both" && (
																	<>
																		<p className="fontsize16 colorblack fw-bold mb-0">{ques?.option_d?.text}</p>
																		<img className="border5px" src={ques?.option_d?.image} height="200px" width="200px" />
																	</>
																)}
															</div>
														</label>
													</>
												)}
												{ques?.questype === "NAT" && (
													<div className="col-lg-12">
														<input
															className="input100 mt-3 px-3 py-2 w-100 border5px border-0 colorblack"
															type="text"
															placeholder="Enter your answer here."
															onChange={(e) => {
																handleChange("NAT", e.target.value, ques?.guid);
															}}
														/>
													</div>
												)}
											</div>
										);
									})}
							</div>
							<div className="row">
								<div className="col-lg-12 text-center mt-4">
									<button onClick={() => quizSubmit()} className="transitionease px-3 py-2 fw-bold fontsize16 buynowbutton text-uppercase border5px">
										Submit
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</Base>
		</>
	);
};
export default MyQuiz;
