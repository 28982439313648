import Breadcrumb from "../../Components/Breadcrumb";
import React, { useState } from "react";
import Login from "../../Components/AuthComponents/Login";
import Signup from "../../Components/AuthComponents/Signup";
import Base from "../../Base";
import { useMediaQuery } from "react-responsive";
const LoginPage = ({ handleRememberMe, rememberMe }) => {
	const [toggle, setToggle] = useState(false);
	const handleToggle = (toggleValue) => {
		setToggle(toggleValue);
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	return (
		<Base>
			<Breadcrumb title="Login" />
			<section className="section">
				<div className="container">
					<div className="row align-items-center pb-4">
						<div className="col-lg-6 col-md-6">
							{isDesktopOrLaptop && (
								<div className="">
									<img src="images/SVGs/Astronaut.svg" height="400px" className="loginsvg" alt="Astronaut" />
								</div>
							)}
							{isTabletOrMobile && (
								<div className="mb-5 text-center">
									<img src="images/SVGs/Astronaut.svg" width="300px" className="loginsvg" alt="Astronaut" />
								</div>
							)}
						</div>
						{!toggle ? <Login rememberMe={rememberMe} handleRememberMe={handleRememberMe} handleToggle={handleToggle} /> : <Signup handleToggle={handleToggle} />}
					</div>
				</div>
			</section>
		</Base>
	);
};
export default LoginPage;
