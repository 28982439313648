import React, { useContext, useEffect, useState } from "react";
import Base from "../Base";
import Breadcrumb from "../Components/Breadcrumb";
import CourseDetailCard from "../Components/CourseDetailCard";
import DataLoader from "../Components/DataLoaders/DataLoader";
import { CoursesContext, BaseContext } from "../Context";
import { singleCourse } from "../data/courses/courses";
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";
import CSRFToken from "../CSRFToken";
import { reviewratingForm, updateReview, deleteReview } from "../../src/helpers/others/reviewratingForm";
import { reviews, singleReview } from "../data/others/reviews";
import { CartContext } from "../Contexts/CartContext";
import { WishlistContext } from "../Contexts/WishlistContext";
import { toast } from "react-toastify";
import { isAuthenticated } from "../helpers/auth/authentication";
import DataLoader2 from "../Components/DataLoaders/DataLoader2";
import tempImg from "../Assets/images/User_Image.webp";
import { SingleEntityContext } from "../Contexts/SingleEntityContext";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
const CourseDetail = (props) => {
	const history = useHistory();
	const [dataLoading, setDataLoading] = useState(false);
	const { course_Enrollments, cookies, handleNotification } = useContext(BaseContext);
	const { findCourse, course } = useContext(CoursesContext);
	const [allReviews, setAllReviews] = useState([]);
	const { addCourse, cartItems } = useContext(CartContext);
	const { addSingleCourse } = useContext(SingleEntityContext);
	const { addCourseToWishlist, removeCourseFromWishlist, wishlistItems } = useContext(WishlistContext);
	const isInCart = (guid) => {
		return !!!cartItems.courses.find((item) => item.course.guid === guid && item.userID === cookies?.user?.[0]?.id);
	};
	const isInWishlist = (guid, userID) => {
		return wishlistItems.courses.find((item) => item.course.guid === guid && item.userID === userID);
	};
	const [loading, setLoading] = useState(false);
	const [animateButton, setAnimateButton] = useState(false);
	useEffect(() => {
		const timer = setTimeout(() => setAnimateButton(false), 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [animateButton]);

	// ANCHOR Stars For Rating
	var showStarsForRating = (number) => {
		var starsForRating = [];
		for (var i = 0; i < number; i++) {
			starsForRating.push(<i key={i} className="fas fontsize16 fa-star coloryellow" />);
		}
		return starsForRating;
	};
	const [target, setTarget] = useState(-1);
	var showStarsForRatingForm = (number, indexValue) => {
		var starsForRatingForm = [];
		for (var i = 0; i < number; i++) {
			starsForRatingForm.push(<i key={i} className={`${indexValue === target ? "fas fa-star coloryellow hvr-icon" : "far fa-star"}`} />);
		}
		return starsForRatingForm;
	};
	const [review, setReview] = useState("");
	const [product_id, setProduct_id] = useState("");
	const [rating, setRating] = useState(0);
	const [toggleButton, setToggleButton] = useState(false);
	const revs = async (item) => {
		await reviews((data) => {
			if (data.find((review) => review.product_id === item?.guid)) {
				setAllReviews(data.filter((review) => review.product_id === item?.guid));
				if (data.filter((review) => review.product_id === item?.guid).find((item) => item?.user?.id === cookies?.user?.[0]?.id)) {
					setIsReviewed(true);
					singleReview(data.filter((review) => review.product_id === item?.guid).find((item) => item?.user?.id === cookies?.user?.[0]?.id).id, (data) => {
						setReview(data?.review);
						setRating(data?.rating);
						setTarget(data?.rating - 1);
						showStarsForRatingForm(data?.rating, data?.rating - 1);
						setReviewID(data?.id);
						setReviewUserID(data?.user?.id);
						setProduct_id(data?.product_id);
					});
				} else setIsReviewed(false);
			}
		});
	};
	const reviewrating = async (e) => {
		e.preventDefault();
		setDataLoading(true);
		if (localStorage.getItem("token")) {
			if (target === -1) {
				setDataLoading(false);
				return toast("Please recheck whether you have filled the form correctly or not!", {
					type: "error",
					autoClose: 5000,
					position: "bottom-center",
					hideProgressBar: false,
					pauseOnHover: true,
					pauseOnFocusLoss: true,
				});
			}
			await reviewratingForm({ product_id: course.guid, review, rating }).then((data) => {
				if (data.user) {
					setToggleButton(!toggleButton);
					setDataLoading(false);
					revs(course);
					return toast("Review Posted!", {
						type: "success",
						autoClose: 5000,
						position: "bottom-center",
						hideProgressBar: false,
						pauseOnHover: true,
						pauseOnFocusLoss: true,
					});
				} else {
					setDataLoading(false);
					return toast("Something went wrong!", {
						type: "error",
						autoClose: 5000,
						position: "bottom-center",
						hideProgressBar: false,
						pauseOnHover: true,
						pauseOnFocusLoss: true,
					});
				}
			});
			setReview("");
			setRating(0);
			setTarget(-1);
		} else {
			history.push("/signin");
		}
	};
	const [isReviewed, setIsReviewed] = useState(false);
	const [reviewID, setReviewID] = useState(null);
	const [reviewUserID, setReviewUserID] = useState(null);
	const reviewDelete = async (e, id) => {
		e.preventDefault();
		await deleteReview(id);
		setAllReviews(allReviews.filter((item) => item.id !== id));
		setIsReviewed(false);
		setRating(0);
		setTarget(-1);
		setReview("");
		setReviewUserID(null);
		setProduct_id("");
		return toast("Review Deleted Successfully!", { type: "success", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
	};
	const reviewUpdate = async (e, rev) => {
		e.preventDefault();
		await updateReview(rev, {
			product_id,
			review,
			rating,
		});
		revs(course);
		return toast("Review Updated Successfully!", { type: "success", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
	};
	useEffect(() => {
		singleCourse(props.match.params.id, (data) => {
			findCourse(data);
			revs(data);
			setLoading(true);
		});
	}, []);
	useEffect(() => {
		var mounted = true;
		if (mounted) {
			!loading && revs(course);
		}
		return () => {
			mounted = false;
		};
	}, [toggleButton]);
	const confirmModal = (e, reviewid) => {
		e.preventDefault();
		return confirmAlert({
			title: "Delete Review",
			message: "Do you really want to delete your review?",
			buttons: [
				{
					label: "Yes",
					onClick: () => reviewDelete(e, reviewid),
				},
				{
					label: "No",
				},
			],
		});
	};
	return (
		<>
			<Helmet title={`International Lunar University | ${course?.course_title}`}>
				<meta name="title" content={course.course_title} />
				<meta name="description" content={course.course_meta_description} />
				<meta name="keywords" content={course.course_meta_tags} />
				<meta property="og:image" content={course?.course_thumbnail || tempImg} />
			</Helmet>
			<Base>
				<Breadcrumb title={course?.course_title} />
				{loading ? (
					<>
						<section className="section">
							<div className="container">
								<div className="row">
									<div className="col-lg-6 order-lg-1 order-2 px-4">
										<div className="row">
											<div className="col-12">
												<h3 className="colorblack">{course.course_title}</h3>
												<p className="colorblack overflow-hidden mb-1">{course.course_description}</p>
											</div>
										</div>
										<div className="row mt-1 mb-3">
											<div className="col d-flex justify-content-center justify-content-lg-start">
												<div className="colorblack fontsize16 border5px py-1 mb-0">
													{course?.course_discount > 0 ? (
														<>
															₹&nbsp;
															{(Math.abs(parseInt(course.course_sp) - parseFloat(course.course_sp)) > 0.5
																? parseInt(course.course_sp) + 1
																: parseInt(course.course_sp)
															).toLocaleString(undefined, { maximumFractionDigits: 2 })}
															&nbsp;
															<span className="fontsize14 notaccepted text-decoration-line-through">
																₹&nbsp;
																{course?.course_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
															</span>
															<span className="darkbluetext fontsize16">
																&nbsp;
																{Math.abs(parseInt(course.course_discount) - parseFloat(course.course_discount)) > 0.5
																	? parseInt(course.course_discount) + 1
																	: parseInt(course.course_discount)}
																% OFF
															</span>
														</>
													) : (
														<>
															₹&nbsp;
															{course?.course_mrp?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
														</>
													)}
												</div>
											</div>
										</div>
										{!course_Enrollments.error && course_Enrollments.filter((item) => item.course?.guid === course?.guid).length > 0 && isAuthenticated() ? (
											<>
												<div className="row mb-3">
													<div className="col-12 col-lg-6 mb-3 pe-lg-3 mb-lg-0">
														<Link
															to={`/learn/mycourses/${course.guid}`}
															className="w-75 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-100 addtocartbutton border5px text-uppercase"
														>
															Go To Course
														</Link>
													</div>
													<div className="col-12 col-lg-6 ps-lg-0 mb-3 mb-lg-0 d-flex justify-content-center align-items-center">
														<a
															href={course?.course_brochure}
															className="w-75 me-3 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-100 buynowbutton border5px text-uppercase"
														>
															<i className="fas fa-file-download" />
															&nbsp;&nbsp;Brochure
														</a>
													</div>
												</div>
											</>
										) : (
											<>
												<div className="row mb-3">
													<div className="col-12 col-lg-12 mb-lg-0 d-flex justify-content-start align-items-center">
														{isInCart(course?.guid) ? (
															<>
																<button
																	className={`${
																		animateButton
																			? "add-to-cart w-25 py-2 px-2 me-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-100 overflow-hidden addtocartbutton border5px text-uppercase is-added"
																			: "add-to-cart w-25 py-2 px-2 me-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-100 overflow-hidden addtocartbutton border5px text-uppercase"
																	}`}
																	onClick={() => {
																		if (isAuthenticated()) {
																			setAnimateButton(true);
																			addCourse({ course, userID: cookies?.user?.[0]?.id });
																		} else {
																			handleNotification("Please login to access cart!", "warning");
																			history.push("/signin");
																		}
																	}}
																>
																	<span>Add To Cart</span>
																	<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
																		<path
																			className={`${animateButton ? "pathatc" : ""}`}
																			strokeDasharray="19.79 19.79"
																			strokeDashoffset="19.79"
																			fill="none"
																			stroke="#fff"
																			strokeWidth={2}
																			strokeLinecap="square"
																			strokeMiterlimit={10}
																			d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
																		/>
																	</svg>
																</button>
																<button
																	className="w-25 me-2 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-100 buynowbutton border5px text-uppercase"
																	onClick={() => {
																		if (isAuthenticated()) {
																			addSingleCourse(course);
																			history.push("/checkout");
																		} else {
																			handleNotification("Please login to buy!", "warning");
																			history.push("/signin");
																		}
																	}}
																>
																	<span>Buy Now</span>
																</button>
															</>
														) : (
															<>
																<button
																	className={`${
																		animateButton
																			? "add-to-cart w-25 py-2 px-2 me-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-100 overflow-hidden addtocartbutton border5px text-uppercase is-added"
																			: "add-to-cart w-25 py-2 px-2 me-2 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative h-100 overflow-hidden addtocartbutton border5px text-uppercase"
																	}`}
																	onClick={() => {
																		history.push("/cart");
																		setAnimateButton(true);
																	}}
																>
																	<span>Go To Cart</span>
																	<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
																		<path
																			className={`${animateButton ? "pathatc" : ""}`}
																			strokeDasharray="19.79 19.79"
																			strokeDashoffset="19.79"
																			fill="none"
																			stroke="#fff"
																			strokeWidth={2}
																			strokeLinecap="square"
																			strokeMiterlimit={10}
																			d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
																		/>
																	</svg>
																</button>
															</>
														)}
														<button
															onClick={() => {
																if (isAuthenticated()) {
																	isInWishlist(course.guid, cookies?.user?.[0]?.id)
																		? removeCourseFromWishlist({ guid: course.guid, userID: cookies?.user?.[0].id })
																		: addCourseToWishlist({ course, userID: cookies?.user?.[0].id });
																} else {
																	return toast("Please login to access wishlist!", {
																		type: "warning",
																		autoClose: 5000,
																		position: "bottom-center",
																		hideProgressBar: false,
																		pauseOnHover: true,
																		pauseOnFocusLoss: true,
																	});
																}
															}}
															className="h-100 py-2 fontsize14 px-3 border5px wishlistbutton"
														>
															<i className={`${isInWishlist(course.guid, cookies?.user?.[0]?.id) ? "fas fa-heart darkbluetext" : "far fa-heart darkbluetext"}`} />
														</button>
													</div>
												</div>
												<div className="row mb-3">
													<div className="col-12 col-lg-12 mb-3 mb-lg-0">
														<a
															href={course?.course_brochure}
															className="w-25 me-3 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-100 buynowbutton border5px text-uppercase"
														>
															<i className="fas fa-file-download" />
															&nbsp;&nbsp;Brochure
														</a>
													</div>
												</div>
											</>
										)}
										<div className="row my-4 mb-3">
											<div className="col-12">
												<h3 className="colorblack">Outcomes Of The Course</h3>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<ul className="list-styled colorblack">
													{course?.course_outcomes && course?.course_outcomes.includes(",") ? (
														course?.course_outcomes.split(",").map((outcome, index) => {
															return (
																<li key={index}>
																	<p className="colorblack">{outcome}</p>
																</li>
															);
														})
													) : (
														<li>
															<p className="colorblack">{course.course_outcomes}</p>
														</li>
													)}
												</ul>
											</div>
										</div>
										<div className="row mt-3 mb-3">
											<div className="col-12">
												<h3 className="colorblack">Instructors</h3>
											</div>
										</div>
										{course?.course_instructor.length > 0 &&
											course.course_instructor.map((instructor, index) => {
												return (
													<div key={index} className="row">
														<div className="col-12">
															<div className="teacher d-flex align-items-center">
																<img src={instructor?.user?.image || tempImg} className="avatar-md-lg rounded-circle shadow" alt="Instructor_Image" />
																<div className="ms-3">
																	<h6 className="my-2 colorblack">
																		{instructor?.user?.first_name !== "" || null ? instructor?.user?.first_name : instructor?.user?.username}
																	</h6>
																</div>
															</div>
															<p className="colorblack my-3">{course?.course_instructor.description}</p>
														</div>
													</div>
												);
											})}
										<div className="row">
											<div className="col-lg-12 mt-3">
												<ul className="nav d-flex justify-content-center nav-pills mb-3" id="pills-tab" style={{ borderBottom: "2.5px dotted #cfcfcf" }} role="tablist">
													<li className="nav-item" role="presentation">
														<button
															className="nav-link fontsize14 mybtnsame colorblack darkbluetexthover bgcolorwhite text-uppercase active"
															id="pills-Reviews-tab"
															data-bs-toggle="pill"
															data-bs-target="#pills-Reviews"
															type="button"
															role="tab"
															aria-controls="pills-Reviews"
															aria-selected="true"
														>
															Reviews
														</button>
													</li>
													{!course_Enrollments.error &&
														course_Enrollments.find((item) => item.course?.guid === course?.guid) &&
														course_Enrollments.find((item) => item.course?.guid === course?.guid)?.is_course_completed &&
														isAuthenticated() && (
															<li className="nav-item" role="presentation">
																<button
																	className="nav-link fontsize14 mybtnsame colorblack darkbluetexthover bgcolorwhite text-uppercase"
																	id={isReviewed ? "pills-editreview-tab" : "pills-addareview-tab"}
																	data-bs-toggle="pill"
																	data-bs-target={isReviewed ? "#pills-editreview" : "#pills-addareview"}
																	type="button"
																	role="tab"
																	aria-controls={isReviewed ? "pills-editreview" : "pills-addareview"}
																	aria-selected="false"
																	style={{ border: "2.5px", borderRadius: "0px", borderLeft: "2.5px dotted #cfcfcf" }}
																>
																	{isReviewed ? "Edit Review" : "Add Review"}
																</button>
															</li>
														)}
												</ul>
												<div className="tab-content" id="pills-tabContent">
													<div className="tab-pane fade show active" id="pills-Reviews" role="tabpanel" aria-labelledby="pills-Reviews-tab">
														{allReviews.length > 0 ? (
															allReviews
																.sort((a, b) => {
																	return a.rating - b.rating;
																})
																.reverse()
																.map((review, index) => {
																	return (
																		<div key={index} className="row my-4">
																			<div className="col-12">
																				<div className="teacher d-flex justify-content-end align-items-center">
																					<img src={review?.user?.image || tempImg} className="avatar-md-lg rounded-circle shadow" alt="User_Image" />
																					<div className="ms-3 flex-grow-1">
																						<h4 className="mb-1 colorblack">
																							{review?.user?.first_name || review?.user?.username}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																							{showStarsForRating(review?.rating)}
																						</h4>
																						<p className="colorblack mb-0">{review?.review}</p>
																					</div>
																				</div>
																			</div>
																		</div>
																	);
																})
														) : (
															<div className="row mt-5">
																<div className="col-lg-12 text-center">
																	<h3 className="mb-5 pt-0 text-center colorblack">There are no reviews to show as of now!</h3>
																	<img width="250px" src="images/SVGs/No_Reviews.svg" className="loginsvg" alt="No_Reviews" />
																</div>
															</div>
														)}
													</div>
													<div
														className="tab-pane fade"
														id={isReviewed ? "pills-editreview" : "pills-addareview"}
														role="tabpanel"
														aria-labelledby={isReviewed ? "pills-editreview-tab" : "pills-addareview-tab"}
													>
														<section className="feedback">
															<div className="row align-items-center">
																<div className="col-lg-12">
																	<div className="card bgcolorgreyish border-0 border5px p-4">
																		<form className="">
																			<CSRFToken />
																			<div className="row">
																				<div className="col-lg-12">
																					<div className="position-relative mb-4">
																						<h5 className="colorblack">Your Rating</h5>
																						{new Array(5).fill().map((val, index) => {
																							return (
																								<div key={index} className="d-flex align-items-center">
																									<input
																										className="d-none"
																										type="radio"
																										name="star_review"
																										id={`star${index + 1}`}
																										value={`${index + 1}`}
																										onChange={(e) => {
																											setRating(e.target.value);
																										}}
																										style={{ margin: "3px 3px 0px 5px" }}
																										required
																									/>
																									<label
																										className="yellowhover hvr-icon-grow"
																										onClick={() => {
																											setTarget(index);
																										}}
																										htmlFor={`star${index + 1}`}
																									>
																										{showStarsForRatingForm(index + 1, index)}
																									</label>
																								</div>
																							);
																						})}
																					</div>
																				</div>
																				<div className="col-lg-12">
																					<div className="position-relative mb-4">
																						<textarea
																							className="colorblack bgcolorwhite p-3 border5px border-0 w-100"
																							style={{ height: "150px", resize: "none" }}
																							placeholder="Your review"
																							value={review}
																							onChange={(e) => {
																								setReview(e.target.value);
																							}}
																							required
																						/>
																					</div>
																				</div>
																				<div className="col-lg-12">
																					<div className="d-grid">
																						<button
																							className="fw-bold py-2 px-3 fontsize16 buynowbutton border5px text-uppercase d-inline-block"
																							type="submit"
																							onClick={(e) => {
																								isReviewed ? reviewUpdate(e, reviewID) : reviewrating(e);
																							}}
																							disabled={dataLoading ? true : false}
																						>
																							{dataLoading ? <DataLoader2 loaderSize={15} loaderType="ScaleLoader" loaderColor="#0007bf" /> : "Submit"}
																						</button>
																						{reviewUserID === cookies?.user?.[0]?.id && (
																							<button
																								onClick={(e) => confirmModal(e, reviewID)}
																								className="mt-3 fw-bold py-2 px-3 fontsize16 addtocartbutton border5px text-uppercase d-inline-block"
																							>
																								DELETE
																							</button>
																						)}
																					</div>
																				</div>
																			</div>
																		</form>
																	</div>
																</div>
															</div>
														</section>
													</div>
												</div>
											</div>
										</div>
									</div>
									<CourseDetailCard />
								</div>
							</div>
						</section>
					</>
				) : (
					<>
						<DataLoader />
					</>
				)}
			</Base>
		</>
	);
};
export default CourseDetail;
