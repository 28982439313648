import { useState, useContext, useEffect } from "react";
import { CartContext } from "../Contexts/CartContext";
import "hover.css";
import "animate.css";
import { Link, useHistory } from "react-router-dom";
import { WishlistContext } from "../Contexts/WishlistContext";
import tempImg2 from "../Assets/images/Course_Image.webp";
import { BaseContext } from "../Context";
import { toast } from "react-toastify";
import { isAuthenticated } from "../helpers/auth/authentication";
import { SingleEntityContext } from "../Contexts/SingleEntityContext";
import { useMediaQuery } from "react-responsive";
const ProfileWishlistCard = ({ item }) => {
	const history = useHistory();
	const [animateButton, setAnimateButton] = useState(false);
	const { addCourseToWishlist, removeCourseFromWishlist, wishlistItems } = useContext(WishlistContext);
	const { cartItems, addCourse } = useContext(CartContext);
	const { cookies, course_Enrollments, handleNotification } = useContext(BaseContext);
	const { addSingleCourse } = useContext(SingleEntityContext);
	const isCourseInWishlist = (guid) => {
		return wishlistItems.courses.find((item) => item.course.guid === guid && item.userID === cookies?.user?.[0]?.id);
	};
	const isCourseInCart = (id) => {
		return !!!cartItems.courses.find((item) => item.course.guid === id && item.userID === cookies?.user?.[0]?.id);
	};
	useEffect(() => {
		const timer = setTimeout(() => setAnimateButton(false), 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [animateButton]);
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	return (
		<>
			{isDesktopOrLaptop && (
				<div className="row px-2 py-3">
					<div className="col-3">
						<Link to={`/learn/course/${item?.slug}`}>
							<img className="border5px shadow" src={item?.course_thumbnail || tempImg2} width="150px" height="150px" alt="Course_Image" />
						</Link>
					</div>
					<div className="col-8 d-flex flex-column justify-content-center text-start">
						<div className="row">
							<div className="col-lg-12">
								<Link className="colorblack fontsize16 darkbluetexthover" to={`/learn/course/${item?.slug}`}>
									{item?.course_title}
								</Link>
							</div>
						</div>
						<div className="row mt-2">
							<div className="col-lg-12">
								<p className="mb-0 colorblack fontsize14">
									₹&nbsp;
									{(Math.abs(parseInt(item?.course_sp) - parseFloat(item?.course_sp)) > 0.5 ? parseInt(item?.course_sp) + 1 : parseInt(item?.course_sp)).toLocaleString(undefined, {
										maximumFractionDigits: 2,
									})}
								</p>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-lg-12 d-flex">
								{!course_Enrollments.error && course_Enrollments.filter((item) => item.course?.guid === item?.guid).length > 0 && isAuthenticated() ? (
									<Link
										to={`/learn/mycourses/${item?.guid}`}
										className="w-50 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-100 addtocartbutton border5px text-uppercase"
									>
										Go To Course
									</Link>
								) : isCourseInCart(item?.guid) ? (
									<>
										<button
											className={`${
												animateButton
													? "add-to-cart w-50 py-2 me-3 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative overflow-hidden addtocartbutton border5px text-uppercase is-added"
													: "add-to-cart w-50 py-2 me-3 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative overflow-hidden addtocartbutton border5px text-uppercase"
											}`}
											onClick={() => {
												if (isAuthenticated()) {
													setAnimateButton(true);
													addCourse({ course: item, userID: cookies?.user?.[0]?.id });
												} else {
													handleNotification("Please login to access cart!", "warning");
													history.push("/signin");
												}
											}}
										>
											<span>Add To Cart</span>
											<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
												<path
													className={`${animateButton ? "pathatc" : ""}`}
													strokeDasharray="19.79 19.79"
													strokeDashoffset="19.79"
													fill="none"
													stroke="#fff"
													strokeWidth={2}
													strokeLinecap="square"
													strokeMiterlimit={10}
													d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
												/>
											</svg>
										</button>
										<button
											className="w-50 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold buynowbutton border5px text-uppercase"
											onClick={() => {
												if (isAuthenticated()) {
													addSingleCourse(item);
													history.push("/checkout");
												} else {
													handleNotification("Please login to buy!", "warning");
													history.push("/signin");
												}
											}}
										>
											<span>Buy Now</span>
										</button>
									</>
								) : (
									<>
										<button
											className={`${
												animateButton
													? "add-to-cart w-50 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative overflow-hidden addtocartbutton border5px text-uppercase is-added"
													: "add-to-cart w-50 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative overflow-hidden addtocartbutton border5px text-uppercase"
											}`}
											onClick={() => {
												history.push("/cart");
												setAnimateButton(true);
											}}
										>
											<span>Go To Cart</span>
											<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
												<path
													className={`${animateButton ? "pathatc" : ""}`}
													strokeDasharray="19.79 19.79"
													strokeDashoffset="19.79"
													fill="none"
													stroke="#fff"
													strokeWidth={2}
													strokeLinecap="square"
													strokeMiterlimit={10}
													d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
												/>
											</svg>
										</button>
									</>
								)}
								<button
									className="wishlistbutton ms-3 py-2 px-3 border5px align-baseline"
									onClick={() => {
										if (isAuthenticated()) {
											isCourseInWishlist(item?.guid)
												? removeCourseFromWishlist({ guid: item?.guid, userID: cookies?.user?.[0].id })
												: addCourseToWishlist({ course: item, userID: cookies?.user?.[0].id });
										} else {
											return toast("Please login to access wishlist!", {
												type: "warning",
												autoClose: 5000,
												position: "bottom-center",
												hideProgressBar: false,
												pauseOnHover: true,
												pauseOnFocusLoss: true,
											});
										}
									}}
								>
									<i className={`${isCourseInWishlist(item?.guid) ? "fas fa-heart darkbluetext" : "far fa-heart darkbluetext"}`} />
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
			{isTabletOrMobile && (
				<div className="row mb-2 p-1">
					<div className="col-4">
						<Link to={`/learn/course/${item?.slug}`}>
							<img className="border5px shadow" src={item?.course_thumbnail || tempImg2} width="100px" height="100px" alt="Course_Image" />
						</Link>
					</div>
					<div className="col-8 text-start">
						<div className="row">
							<div className="col-lg-12">
								<Link className="colorblack fontsize16 darkbluetexthover" to={`/learn/course/${item?.slug}`}>
									{item?.course_title}
								</Link>
							</div>
						</div>
						<div className="row mt-2">
							<div className="col-lg-12">
								<p className="mb-0 colorblack fontsize14">
									₹&nbsp;
									{(Math.abs(parseInt(item?.course_sp) - parseFloat(item?.course_sp)) > 0.5 ? parseInt(item?.course_sp) + 1 : parseInt(item?.course_sp)).toLocaleString(undefined, {
										maximumFractionDigits: 2,
									})}
								</p>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-lg-12">
								{!course_Enrollments.error && course_Enrollments.filter((item) => item.course?.guid === item?.guid).length > 0 && isAuthenticated() ? (
									<Link
										to={`/learn/mycourses/${item?.guid}`}
										className="w-100 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold h-50 addtocartbutton border5px text-uppercase"
									>
										Go To Course
									</Link>
								) : isCourseInCart(item?.guid) ? (
									<>
										<button
											className={`${
												animateButton
													? "add-to-cart w-100 py-2 me-3 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative overflow-hidden addtocartbutton border5px text-uppercase is-added"
													: "add-to-cart w-100 py-2 me-3 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative overflow-hidden addtocartbutton border5px text-uppercase"
											}`}
											onClick={() => {
												if (isAuthenticated()) {
													setAnimateButton(true);
													addCourse({ course: item, userID: cookies?.user?.[0]?.id });
												} else {
													handleNotification("Please login to access cart!", "warning");
													history.push("/signin");
												}
											}}
										>
											<span>Add To Cart</span>
											<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
												<path
													className={`${animateButton ? "pathatc" : ""}`}
													strokeDasharray="19.79 19.79"
													strokeDashoffset="19.79"
													fill="none"
													stroke="#fff"
													strokeWidth={2}
													strokeLinecap="square"
													strokeMiterlimit={10}
													d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
												/>
											</svg>
										</button>
										<button
											className="mt-2 w-100 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold buynowbutton border5px text-uppercase"
											onClick={() => {
												if (isAuthenticated()) {
													addSingleCourse(item);
													history.push("/checkout");
												} else {
													handleNotification("Please login to buy!", "warning");
													history.push("/signin");
												}
											}}
										>
											<span>Buy Now</span>
										</button>
									</>
								) : (
									<>
										<button
											className={`${
												animateButton
													? "add-to-cart w-100 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative overflow-hidden addtocartbutton border5px text-uppercase is-added"
													: "add-to-cart w-100 py-2 px-3 fontsize14 d-flex justify-content-center align-items-center fw-bold position-relative overflow-hidden addtocartbutton border5px text-uppercase"
											}`}
											onClick={() => {
												history.push("/cart");
												setAnimateButton(true);
											}}
										>
											<span>Go To Cart</span>
											<svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
												<path
													className={`${animateButton ? "pathatc" : ""}`}
													strokeDasharray="19.79 19.79"
													strokeDashoffset="19.79"
													fill="none"
													stroke="#fff"
													strokeWidth={2}
													strokeLinecap="square"
													strokeMiterlimit={10}
													d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"
												/>
											</svg>
										</button>
									</>
								)}
								<button
									className="wishlistbutton mt-2 py-2 px-3 border5px align-baseline"
									onClick={() => {
										if (isAuthenticated()) {
											isCourseInWishlist(item?.guid)
												? removeCourseFromWishlist({ guid: item?.guid, userID: cookies?.user?.[0].id })
												: addCourseToWishlist({ course: item, userID: cookies?.user?.[0].id });
										} else {
											return toast("Please login to access wishlist!", {
												type: "warning",
												autoClose: 5000,
												position: "bottom-center",
												hideProgressBar: false,
												pauseOnHover: true,
												pauseOnFocusLoss: true,
											});
										}
									}}
								>
									<i className={`${isCourseInWishlist(item?.guid) ? "fas fa-heart darkbluetext" : "far fa-heart darkbluetext"}`} />
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
export default ProfileWishlistCard;
