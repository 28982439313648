import CourseListCard from "../Components/CourseListCard";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import Base from "../Base";
import { Helmet } from "react-helmet-async";
import { search } from "../data/others/search";
import DataLoader from "../Components/DataLoaders/DataLoader";
import ReactPaginate from "react-paginate";
const SearchPage = (props) => {
	// ANCHOR Pagination
	const [currentPage, setCurrentPage] = useState(0);
	const [pageCount, setPageCount] = useState(1);
	const PER_PAGE = 9;
	function handlePageClick({ selected: selectedPage }) {
		setCurrentPage(selectedPage);
	}
	const offset = currentPage * PER_PAGE;

	// ANCHOR Fetching Search Results
	const [loading, setLoading] = useState(true);
	const [resultsCount, setResultsCount] = useState(-1);
	useEffect(() => {
		search(props.match.params.input, PER_PAGE, offset, (data) => {
			props.setSearchResults(data.results);
			setResultsCount(data?.count);
			setPageCount(data?.count / PER_PAGE);
		});
	}, [props.match.params.input, currentPage]);
	useEffect(() => {
		props.searchResults?.length > 0 && setLoading(false);
	}, [props.searchResults, props.match.params.input]);
	return (
		<>
			<Helmet>
				<title>International Lunar University | Search</title>
			</Helmet>
			<Base>
				<Breadcrumb title="Search Results" />
				{resultsCount !== 0 ? (
					loading ? (
						<DataLoader />
					) : (
						<>
							<section className="section">
								<div className="container">
									<div className="row text-center">
										<h6 className="mb-5 colorblack">Showing results for - "{props.match.params.input}"</h6>
									</div>
									<div className="row">
										{props.searchResults.map((result, index) => {
											return <CourseListCard key={index} course={result} />;
										})}
									</div>
									<ReactPaginate
										previousLabel={<i className="fas fa-arrow-left"></i>}
										nextLabel={<i className="fas fa-arrow-right"></i>}
										pageCount={pageCount}
										pageRangeDisplayed={1}
										marginPagesDisplayed={2}
										onPageChange={handlePageClick}
										containerClassName={"pagination fontsize16 d-flex justify-content-center align-items-center mb-0 pb-0 mt-4 pt-4 p-0"}
										pageClassName={"hovergoup h-100 border5px mx-2 px-2"}
										nextClassName={"hovergoup d-flex align-items-center mx-2 fontsize14 h-100 px-2 border5px"}
										previousClassName={" hovergoup d-flex align-items-center mx-2 fontsize14 h-100 px-2 border5px"}
										previousLinkClassName={"pagination__link colorblack"}
										nextLinkClassName={"pagination__link colorblack"}
										disabledClassName={"pagination__link--disabled"}
										activeClassName={"pagination__link--active"}
									/>
								</div>
							</section>
						</>
					)
				) : (
					<section className="section">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 text-center">
									<h1 className="mb-5 colorblue">No results found for "{props.match.params.input}"!</h1>
									<img width="225px" src="images/SVGs/Search.svg" className="loginsvg" alt="Search" />
								</div>
							</div>
						</div>
					</section>
				)}
			</Base>
		</>
	);
};
export default SearchPage;
