import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { BaseContext } from "../Context";
import { OrderDetailContext } from "../Contexts/OrderDetailContext";
import { invoiceRequest } from "../helpers/others/invoiceRequest";
import { toast } from "react-toastify";
import DataLoader2 from "../Components/DataLoaders/DataLoader2";
const MyOrderCard = ({ my_order }) => {
	const { addOrderDetail, orderdetailItems } = useContext(OrderDetailContext);
	const { cookies } = useContext(BaseContext);
	useEffect(() => {
		if (!orderdetailItems.find((item) => item.order_id === my_order.order_id)) {
			addOrderDetail({ order: my_order, userID: cookies?.user?.[0]?.id });
		}
	}, []);
	const [dataLoading, setdDataLoading] = useState(false);
	const [order_id, setOrder_Id] = useState(my_order.order_id);
	const invoice = async (e) => {
		e.preventDefault();
		setdDataLoading(true);
		await invoiceRequest({ order_id }).then((data) => {
			if (data.id) {
				setdDataLoading(false);
				return toast("Invoice Request Posted", { type: "success", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
			}
			if (data?.details) {
				setdDataLoading(false);
				return toast(data?.details, { type: "success", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
			} else {
				setdDataLoading(false);
			}
		});
	};
	return (
		<>
			<div className="row bgcolorwhite shadow border5px text-start mx-1 mb-3">
				<div className="col-lg-6">
					<p className="colorblack my-2">
						Order ID:&nbsp;&nbsp;
						<Link to={`/profile/orderdetail/${my_order.order_id}`} className="darkbluetext text-decoration-underline">
							{my_order.order_id}
						</Link>
					</p>
				</div>
				<div className="col-lg-6">
					<p className="colorblack my-2">
						Date:&nbsp;&nbsp;
						<span className="darkbluetext">
							{my_order.order_date.split(" ")[0].toString() + " " + my_order.order_date.split(" ")[1].toString() + " " + my_order.order_date.split(" ")[2].toString()}
						</span>
					</p>
				</div>
				<div className="col-lg-6">
					<p className="colorblack my-2">
						Payment Staus:&nbsp;&nbsp;
						<span className="darkbluetext">{my_order.is_paid ? "Paid" : "Not Paid"}</span>
					</p>
				</div>
				<div className="col-lg-6">
					<p className="colorblack my-2">
						Total Amount:&nbsp;&nbsp;<span className="darkbluetext">₹ {my_order.payable.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
					</p>
				</div>
				{my_order.applied_coupon ? (
					<div className="col-lg-12">
						<p className="colorblack my-2">
							Coupon Applied:&nbsp;&nbsp;
							<span className="darkbluetext">{my_order.applied_coupon}</span>
						</p>
					</div>
				) : (
					""
				)}
				<div className="col-lg-12 shipaddscrollbar overflow-scroll" style={{ borderTop: "2px solid #cfcfcf" }}>
					<p className="colorblack my-2">
						Billing Address:&nbsp;&nbsp;
						<span className="darkbluetext">{my_order.billing_address}</span>
					</p>
				</div>
				<div className="col-lg-12 mb-2 text-end" style={{ borderTop: "2px solid #cfcfcf" }}>
					<button
						type="submit"
						onClick={(e) => {
							invoice(e);
						}}
						disabled={dataLoading ? true : false}
						className="mt-2 p-2 transitionease fontsize14 text-uppercase border5px fw-bold buynowbutton"
					>
						{dataLoading ? <DataLoader2 loaderSize={15} loaderType="ScaleLoader" loaderColor="#0007bf" /> : "Request Invoice"}
					</button>
				</div>
			</div>
		</>
	);
};
export default MyOrderCard;
