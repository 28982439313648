import React, { useState, useEffect, useRef, useContext } from "react";
import { BaseContext } from "../Context";
import { markAsComplete, markAsCompleteGet } from "../helpers/lms/markAsComplete";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";
const Playlist = ({ courseContent, videoURLSelector, guid, videoURLUpdate, enrollmentID, handleQuizData, contentID, setUpdateQ, updateQ }) => {
	const { course_Enrollments, handleNotification } = useContext(BaseContext);
	const [videoActive, setVideoActive] = useState(0);
	const [toggle, setToggle] = useState(false);
	const autoClose = useRef(null);
	const history = useHistory();
	const [finishStatus, setfinishStatus] = useState(false);
	const { setUpdate_Enrollments, update_Enrollments } = useContext(BaseContext);
	const handleMarkAsComplete = (e, contentID, enID, index) => {
		e.preventDefault();
		markAsComplete(
			{
				content_id: contentID,
			},
			enID,
			() => {
				setToggle(!toggle);
				setUpdate_Enrollments(!update_Enrollments);
			}
		);
		if (index < courseContent.length - 1 && courseContent?.[index + 1]?.video != null) {
			handleNotification("Next video will play in few seconds!", "success");
			const timer = setTimeout(() => {
				videoURLSelector(index + 1);
				videoURLUpdate();
				index < courseContent.length - 1 && setVideoActive(index + 1);
			}, 3500);
			return () => {
				clearTimeout(timer);
			};
		} else {
			if (courseContent?.[index + 1]?.video == null) {
				handleNotification("Lectures of this section completed!", "success");
			}
		}
	};
	const [isToggled1, setIsToggled1] = useState(false);
	const navbarToggler1 = () => {
		setIsToggled1(!isToggled1);
	};
	const [markedContent, setMarkedContent] = useState([]);
	useEffect(() => {
		if (course_Enrollments.find((item) => item.course.guid === guid)?.id !== undefined) {
			markAsCompleteGet(course_Enrollments.find((item) => item?.course?.guid === guid)?.id, (data) => {
				setMarkedContent(data?.content);
			});
		}
	}, [toggle]);
	const confirmModal = (content, id) => {
		return confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert-body">
						<h1>International Lunar University Honor Code</h1>
						<p className="colorblack mb-0">We’re dedicated to protecting the integrity of your work on International Lunar University.</p>
						<br />
						<p className="colorblack mb-0">
							As part of this effort, we’ve created an honor code that we ask everyone to follow.&nbsp;
							<a target="_blank" rel="noopener noreferrer" className="colorblack darkbluetexthover text-decoration-underline" href="/honorcode">
								Learn more
							</a>
						</p>
						<div className="react-confirm-alert-button-group">
							<a
								className="transitionease px-2 py-1 fontsize12 buynowbutton text-uppercase border5px"
								onClick={() => {
									handleQuizData({ contentID: contentID, chapterID: content?.id });
									onClose();
								}}
								href={`/quiz/${id}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								Start Quiz
							</a>
							<button className="d-none" ref={autoClose} onClick={onClose}>
								No
							</button>
						</div>
					</div>
				);
			},
			onClickOutside: () => setfinishStatus(false),
		});
	};
	useEffect(() => {
		const onBackButtonEvent = (e) => {
			e.preventDefault();
			if (finishStatus) {
				autoClose.current.click();
			}
			history.push(window.location.pathname);
		};
		window.addEventListener("popstate", onBackButtonEvent);
		return () => {
			window.removeEventListener("popstate", onBackButtonEvent);
		};
	}, [finishStatus]);
	useEffect(() => {
		const onReceiveChange = (e) => {
			if (!!localStorage.getItem("q_update")) {
				setUpdateQ(!updateQ);
				localStorage.removeItem("q_update");
			}
		};
		window.addEventListener("storage", onReceiveChange);
		return () => {
			window.removeEventListener("storage", onReceiveChange);
		};
	});
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	return (
		<>
			{isDesktopOrLaptop && (
				<div className="card shadow border5px overflow-hidden border-0">
					<div className="card-body">
						<h1 className="colorblack text-center mb-3">Playlist</h1>
						<ul className="list-group">
							{courseContent.map((content, index) => {
								return (
									<>
										{content.video !== null ? (
											<li
												key={index}
												className={
													videoActive === index
														? "list-group-item bgcolorgreyish p-3 d-flex w-100 justify-content-between align-items-center"
														: "list-group-item p-3 d-flex w-100 justify-content-between align-items-center"
												}
											>
												<a
													className={
														videoActive === index
															? "mb-0 me-3 cursorpointer flex-grow-1 h-100 w-25 fontsize16 fw-bold colorlightblue"
															: "mb-0 me-3 cursorpointer flex-grow-1 h-100 w-25 fontsize16 fw-bold colorblack darkbluetexthover"
													}
													onClick={() => {
														videoURLSelector(index);
														videoURLUpdate();
														setVideoActive(index);
													}}
													style={{ borderRight: "3px dotted #555555" }}
												>
													{content.chapter_name}
												</a>
												{content?.reading && (
													<>
														<a
															className="cursorpointer mx-3 transitionease p-2 fontsize12 bglightblue bgyellow text-uppercase border5px colorblack border-0"
															href={content?.reading}
															target="_blank"
															rel="noopener noreferrer"
														>
															Reading
														</a>
														{!markedContent.find((item) => item.id === content.id) ? (
															<button
																className="transitionease w-25 p-2 fontsize12 text-uppercase border5px addtocartbutton"
																onClick={(e) => {
																	handleMarkAsComplete(e, content?.id, enrollmentID, index);
																}}
															>
																Mark As Complete
															</button>
														) : (
															<button disabled={true} style={{ width: "15%" }} className="transitionease p-2 fontsize12 buynowbutton text-uppercase border5px">
																<i className="fas fa-check" />
															</button>
														)}
													</>
												)}
												{!content?.reading &&
													(!markedContent.find((item) => item.id === content.id) ? (
														<button
															className="transitionease ms-3 w-50 p-2 fontsize12 text-uppercase border5px addtocartbutton"
															onClick={(e) => {
																handleMarkAsComplete(e, content?.id, enrollmentID, index);
															}}
														>
															Mark As Complete
														</button>
													) : (
														<button disabled={true} style={{ width: "15%" }} className="transitionease ms-3 p-2 fontsize12 buynowbutton text-uppercase border5px">
															<i className="fas fa-check" />
														</button>
													))}
											</li>
										) : (
											<li key={index} className="list-group-item bgcolorgreyish p-3 d-flex w-100 justify-content-center align-items-center">
												<h4 className="mb-0 h-100 fw-bold colorblack">{content.chapter_name}</h4>
											</li>
										)}
										{content.quiz.length > 0 &&
											content.quiz.map((ele) => {
												return (
													<>
														<li key={ele?.guid} className="list-group-item p-3 d-flex w-100 justify-content-between align-items-center">
															<h6 className="mb-0 w-50 colorblack">{ele?.quiz_title}</h6>
															{content?.quiz_completion_status.length > 0 &&
															content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid && val?.value == false) ? (
																<p className="mb-0 text-center w-25 colorblack">
																	{content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid)?.total_marks} Marks
																</p>
															) : (
																<p className="mb-0 text-center w-25 colorblack">
																	{content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid)?.obtained_marks}/
																	{content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid)?.total_marks} Marks
																</p>
															)}
															{content?.quiz_completion_status.length > 0 &&
															content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid && val?.value == false) ? (
																<a
																	className="transitionease p-2 cursorpointer fontsize12 buynowbutton text-uppercase border5px"
																	onClick={() => {
																		setfinishStatus(true);
																		confirmModal(content, ele?.guid);
																	}}
																	rel="noopener noreferrer"
																>
																	Take Quiz
																</a>
															) : (
																<a
																	className="transitionease p-2 cursorpointer fontsize12 buynowbutton text-uppercase border5px"
																	href={`/viewquiz/${ele?.guid}`}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	View Quiz
																</a>
															)}
														</li>
													</>
												);
											})}
									</>
								);
							})}
						</ul>
					</div>
				</div>
			)}
			{isTabletOrMobile && (
				<>
					<nav className="navbar navbar-expand-lg py-4 justify-content-center border-0 border5px">
						<button
							className={isToggled1 ? "hamburger hamburger--slider is-active navbar-toggler me-4" : "hamburger hamburger--slider navbar-toggler me-4"}
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#myown-nav1"
							aria-controls="myown-nav1"
							aria-expanded={isToggled1 ? "false" : "true"}
							aria-label="Toggle navigation"
							onClick={navbarToggler1}
						>
							<span className="hamburger-box">
								<span className="hamburger-inner" />
							</span>
						</button>
						<h1 className="mb-0 me-5 colorblack">Playlist</h1>
						<div className="collapse navbar-collapse" id="myown-nav1">
							<ul className="navbar-nav mt-3 pt-1">
								{courseContent.map((content, index) => {
									return (
										<>
											{content?.reading || content.quiz.length > 0 ? (
												<>
													{content.video !== null ? (
														<li
															key={index}
															className={
																videoActive === index
																	? "nav-item dropdown d-flex flex-wrap bgcolorgreyish p-4 w-100 justify-content-between align-items-center"
																	: "nav-item dropdown d-flex flex-wrap p-4 w-100 justify-content-between align-items-center"
															}
														>
															<a
																className={
																	videoActive === index
																		? "mb-0 me-3 cursorpointer flex-grow-1 h-100 w-25 fontsize16 fw-bold colorlightblue show"
																		: "mb-0 me-3 cursorpointer flex-grow-1 h-100 w-25 fontsize16 fw-bold colorblack darkbluetexthover"
																}
																id="navbarDropdown"
																role="button"
																data-bs-toggle="dropdown"
																aria-expanded="false"
																onClick={() => {
																	videoURLSelector(index);
																	videoURLUpdate();
																	setVideoActive(index);
																}}
															>
																{content.chapter_name}&nbsp;
															</a>
															{!markedContent.find((item) => item.id === content.id) ? (
																<button
																	className="transitionease ms-3 w-50 p-2 fontsize12 border5px addtocartbutton"
																	onClick={(e) => {
																		handleMarkAsComplete(e, content?.id, enrollmentID, index);
																	}}
																>
																	Mark As Complete
																</button>
															) : (
																<button disabled={true} style={{ width: "15%" }} className="transitionease ms-3 p-2 fontsize12 buynowbutton text-uppercase border5px">
																	<i className="fas fa-check" />
																</button>
															)}
															<ul className="dropdown-menu w-100 border5px shadow-sm mt-3 border-0 show" id="mydropdownitem" aria-labelledby="navbarDropdown">
																{content?.reading && (
																	<li className="d-flex align-items-center">
																		<a
																			className="cursorpointer mx-3 transitionease p-2 fontsize12 bglightblue bgyellow text-uppercase border5px colorblack border-0"
																			href={content?.reading}
																			target="_blank"
																			rel="noopener noreferrer"
																		>
																			Reading
																		</a>
																	</li>
																)}
																{content?.reading && content.quiz.length > 0 && (
																	<li>
																		<hr className="dropdown-divider dropdowndividernav" />
																	</li>
																)}
																{content.quiz.length > 0 &&
																	content.quiz.map((ele) => {
																		return (
																			<>
																				<li key={ele?.guid} className="p-3 d-flex w-100 justify-content-between align-items-center">
																					<h6 className="mb-0 w-25 fontsize14 colorblack">{ele?.quiz_title}</h6>
																					{content?.quiz_completion_status.length > 0 &&
																					content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid && val?.value == false) ? (
																						<p className="mb-0 text-center fontsize14 w-25 colorblack">
																							{content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid)?.total_marks} Marks
																						</p>
																					) : (
																						<p className="mb-0 text-center fontsize14 w-25 colorblack">
																							{content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid)?.obtained_marks}/
																							{content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid)?.total_marks} Marks
																						</p>
																					)}
																					{content?.quiz_completion_status.length > 0 &&
																					content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid && val?.value == false) ? (
																						<a
																							className="transitionease p-2 cursorpointer fontsize12 buynowbutton text-uppercase border5px"
																							onClick={() => {
																								setfinishStatus(true);
																								confirmModal(content, ele?.guid);
																							}}
																							rel="noopener noreferrer"
																						>
																							Take Quiz
																						</a>
																					) : (
																						<a
																							className="transitionease p-2 cursorpointer fontsize12 buynowbutton text-uppercase border5px"
																							href={`/viewquiz/${ele?.guid}`}
																							target="_blank"
																							rel="noopener noreferrer"
																						>
																							View Quiz
																						</a>
																					)}
																				</li>
																			</>
																		);
																	})}
															</ul>
														</li>
													) : (
														<>
															<li key={index} className="bgcolorgreyish p-4 d-flex w-100 justify-content-center align-items-center">
																<h4 className="mb-0 h-100 fw-bold colorblack">{content.chapter_name}</h4>
															</li>
															<ul className="w-100 p-0 border-0 show">
																{content.quiz.length > 0 &&
																	content.quiz.map((ele) => {
																		return (
																			<>
																				<li key={ele?.guid} className="p-3 d-flex w-100 justify-content-between align-items-center">
																					<h6 className="mb-0 w-25 fontsize14 colorblack">{ele?.quiz_title}</h6>
																					{content?.quiz_completion_status.length > 0 &&
																					content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid && val?.value == false) ? (
																						<p className="mb-0 text-center fontsize14 w-25 colorblack">
																							{content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid)?.total_marks} Marks
																						</p>
																					) : (
																						<p className="mb-0 text-center fontsize14 w-25 colorblack">
																							{content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid)?.obtained_marks}/
																							{content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid)?.total_marks} Marks
																						</p>
																					)}
																					{content?.quiz_completion_status.length > 0 &&
																					content?.quiz_completion_status.find((val) => val?.quiz_guid == ele?.guid && val?.value == false) ? (
																						<a
																							className="transitionease p-2 cursorpointer fontsize12 buynowbutton text-uppercase border5px"
																							onClick={() => {
																								setfinishStatus(true);
																								confirmModal(content, ele?.guid);
																							}}
																							rel="noopener noreferrer"
																						>
																							Take Quiz
																						</a>
																					) : (
																						<a
																							className="transitionease p-2 cursorpointer fontsize12 buynowbutton text-uppercase border5px"
																							href={`/viewquiz/${ele?.guid}`}
																							target="_blank"
																							rel="noopener noreferrer"
																						>
																							View Quiz
																						</a>
																					)}
																				</li>
																			</>
																		);
																	})}
															</ul>
														</>
													)}
												</>
											) : (
												<>
													{content.video !== null ? (
														<li
															key={index}
															className={
																videoActive === index
																	? "bgcolorgreyish p-4 d-flex w-100 justify-content-between align-items-center"
																	: "p-4 d-flex w-100 justify-content-between align-items-center"
															}
														>
															<a
																className={
																	videoActive === index
																		? "mb-0 me-3 cursorpointer flex-grow-1 h-100 w-25 fontsize16 fw-bold colorlightblue"
																		: "mb-0 me-3 cursorpointer flex-grow-1 h-100 w-25 fontsize16 fw-bold colorblack darkbluetexthover"
																}
																onClick={() => {
																	videoURLSelector(index);
																	videoURLUpdate();
																	setVideoActive(index);
																}}
															>
																{content.chapter_name}
															</a>
															{!markedContent.find((item) => item.id === content.id) ? (
																<button
																	className="transitionease ms-3 w-50 p-2 fontsize12 text-uppercase border5px addtocartbutton"
																	onClick={(e) => {
																		handleMarkAsComplete(e, content?.id, enrollmentID, index);
																	}}
																>
																	Mark As Complete
																</button>
															) : (
																<button disabled={true} style={{ width: "15%" }} className="transitionease ms-3 p-2 fontsize12 buynowbutton text-uppercase border5px">
																	<i className="fas fa-check" />
																</button>
															)}
														</li>
													) : (
														<li key={index} className="bgcolorgreyish p-4 d-flex w-100 justify-content-center align-items-center">
															<h4 className="mb-0 h-100 fw-bold colorblack">{content.chapter_name}</h4>
														</li>
													)}
												</>
											)}
										</>
									);
								})}
							</ul>
						</div>
					</nav>
				</>
			)}
		</>
	);
};
export default Playlist;
