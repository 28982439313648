import { CourseForumForm_API } from "../../backend";
export const courseforumForm = async (course_id, message) => {
	const tokenValue = localStorage.getItem("token").replace(/['"]+/g, "");
	return await fetch(CourseForumForm_API, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Token " + tokenValue,
		},
		body: JSON.stringify(course_id, message),
	})
		.then((res) => {
			return res.json();
		})
		.catch((err) => console.log(err));
};
