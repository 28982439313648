import BundlesListCard from "./BundlesListCard";
import { useContext } from "react";
import { BundlesContext } from "../Context";
const BundleList = () => {
	const { allBundles } = useContext(BundlesContext);
	return (
		<>
			{allBundles.length > 0 && (
				<section className="section pt-0 overflow-hidden">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12">
								<div className="text-center my-2 py-4" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
									<h2 className="colorblack mt-5">Popular Bundles</h2>
								</div>
							</div>
						</div>
						<div className="row">
							{allBundles.map((bundle, index) => {
								return <BundlesListCard key={index} bundle={bundle} />;
							})}
						</div>
					</div>
				</section>
			)}
		</>
	);
};
export default BundleList;
