const Storage = (cartItems) => {
	localStorage.setItem(
		"cart",
		JSON.stringify(
			cartItems.courses.length > 0 || cartItems.bundles.length > 0
				? cartItems
				: {
						courses: [],
						bundles: [],
				  }
		)
	);
};
export const sumItems = (allCartItems, filteredCartItems) => {
	Storage(allCartItems);
	let allItemsCount = 0;
	let allItemsTotalPrice = 0;
	let allItemsTotalDiscount = 0;
	let coursesCount = 0;
	let bundlesCount = 0;
	let totalCoursesPrice = 0;
	let totalBundlesPrice = 0;
	let totalCoursesDiscount = 0;
	let totalBundlesDiscount = 0;
	if (filteredCartItems.courses.length > 0) {
		coursesCount = filteredCartItems?.courses.length;
		totalCoursesPrice = filteredCartItems?.courses.reduce((total, item) => total + item.course.course_mrp, 0);
		totalCoursesDiscount = filteredCartItems?.courses.reduce((total, item) => total + (item.course.course_mrp - item.course.course_sp), 0);
	}
	if (filteredCartItems.bundles.length > 0) {
		bundlesCount = filteredCartItems?.bundles.length;
		totalBundlesPrice = filteredCartItems?.bundles.reduce((total, item) => total + item.bundle.bundle_mrp, 0);
		totalBundlesDiscount = filteredCartItems?.bundles.reduce((total, item) => total + (item.bundle.bundle_mrp - item.bundle.bundle_sp), 0);
	}
	allItemsCount = coursesCount + bundlesCount;
	allItemsTotalPrice = totalCoursesPrice + totalBundlesPrice;
	allItemsTotalDiscount = totalCoursesDiscount + totalBundlesDiscount;
	return { allItemsCount, allItemsTotalPrice, allItemsTotalDiscount };
};
export const CartReducer = (state, action) => {
	switch (action.type) {
		case "ADD_COURSE":
			if (!state.cartItems.courses.find((item) => item.course.guid === action.payload.course.guid && item.userID === action.payload.userID)) {
				state.cartItems.courses.push({
					...action.payload,
					userID: action.payload.userID,
				});
			}
			return {
				...state,
				...sumItems(state.cartItems, {
					courses: state.cartItems.courses.filter((item) => item.userID === action.payload.userID),
					bundles: state.cartItems.bundles.filter((item) => item.userID === action.payload.userID),
				}),
				cartItems: { ...state.cartItems },
			};
		case "REMOVE_COURSE":
			let newCourseArray = state.cartItems.courses.filter((item) => item.course.guid !== action.payload.guid);
			state.cartItems = {
				...state.cartItems,
				courses: newCourseArray,
			};
			return {
				...state,
				...sumItems(state.cartItems, {
					courses: state.cartItems.courses.filter((item) => item.userID === action.payload.userID),
					bundles: state.cartItems.bundles.filter((item) => item.userID === action.payload.userID),
				}),
				cartItems: { ...state.cartItems },
			};
		case "ADD_BUNDLE":
			if (!state.cartItems.bundles.find((item) => item.bundle.guid === action.payload.bundle.guid && item.userID === action.payload.userID)) {
				state.cartItems.bundles.push({
					...action.payload,
					userID: action.payload.userID,
				});
			}
			return {
				...state,
				...sumItems(state.cartItems, {
					courses: state.cartItems.courses.filter((item) => item.userID === action.payload.userID),
					bundles: state.cartItems.bundles.filter((item) => item.userID === action.payload.userID),
				}),
				cartItems: { ...state.cartItems },
			};
		case "REMOVE_BUNDLE":
			let newBundleArray = state.cartItems.bundles.filter((item) => item.bundle.guid !== action.payload.guid);
			state.cartItems = {
				...state.cartItems,
				bundles: newBundleArray,
			};
			return {
				...state,
				...sumItems(state.cartItems, {
					courses: state.cartItems.courses.filter((item) => item.userID === action.payload.userID),
					bundles: state.cartItems.bundles.filter((item) => item.userID === action.payload.userID),
				}),
				cartItems: { ...state.cartItems },
			};
		case "CLEAR":
			state.cartItems = {
				courses: state.cartItems.courses.filter((item) => item.userID !== action.payload),
				bundles: state.cartItems.bundles.filter((item) => item.userID !== action.payload),
			};
			return {
				...state,
				...sumItems(state.cartItems, {
					courses: [],
					bundles: [],
				}),
				cartItems: { ...state.cartItems },
			};
		case "UPDATE_CART_ON_AUTH":
			return {
				...state,
				...sumItems(state.cartItems, {
					courses: state.cartItems.courses.filter((item) => item.userID === action.payload),
					bundles: state.cartItems.bundles.filter((item) => item.userID === action.payload),
				}),
				cartItems: { ...state.cartItems },
			};
		default:
			return state;
	}
};
