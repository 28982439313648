import { MarkAsComplete_API } from "../../backend";
export const markAsComplete = async (data, id, next) => {
	const tokenValue = localStorage.getItem("token").replace(/['"]+/g, "");
	return await fetch(`${MarkAsComplete_API}${id}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Token " + tokenValue,
		},
		body: JSON.stringify(data),
	})
		.then((res) => {
			return res.json();
		})
		.then(() => {
			next();
		})
		.catch((err) => console.log(err));
};
export const markAsCompleteGet = async (id, next) => {
	const tokenValue = localStorage.getItem("token").replace(/['"]+/g, "");
	return await fetch(`${MarkAsComplete_API}${id}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Token " + tokenValue,
		},
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			next(data);
		})
		.catch((err) => console.log(err));
};
