import "../Loader/style.scss";
const Loader = () => {
	return (
		<>
			<div className="loader">
				<div className="content">
					<div className="planet">
						<div className="ring" />
						<div className="cover-ring" />
						<div className="spots">
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
							<span />
						</div>
					</div>
					<p>Welcome To International Lunar University!</p>
				</div>
			</div>
		</>
	);
};
export default Loader;
