import React, { createContext, useReducer } from "react";
import { CartReducer, sumItems } from "./CartReducer";
export const CartContext = createContext();
const storage = localStorage.getItem("cart")
	? JSON.parse(localStorage.getItem("cart"))
	: {
			courses: [],
			bundles: [],
	  };
const initialState = {
	cartItems: storage,
	...sumItems(storage, {
		courses: [],
		bundles: [],
	}),
};
const CartProvider = ({ children }) => {
	const [state, dispatch] = useReducer(CartReducer, initialState);
	const addCourse = (payload) => {
		dispatch({ type: "ADD_COURSE", payload });
	};
	const removeCourse = (payload) => {
		dispatch({ type: "REMOVE_COURSE", payload });
	};
	const addBundle = (payload) => {
		dispatch({ type: "ADD_BUNDLE", payload });
	};
	const removeBundle = (payload) => {
		dispatch({ type: "REMOVE_BUNDLE", payload });
	};
	const handleCheckout = (payload) => {
		dispatch({ type: "CLEAR", payload });
	};
	const updateCartOnAuth = (payload) => {
		dispatch({ type: "UPDATE_CART_ON_AUTH", payload });
	};
	const contextValues = {
		removeCourse,
		addCourse,
		addBundle,
		removeBundle,
		handleCheckout,
		updateCartOnAuth,
		...state,
	};
	return <CartContext.Provider value={contextValues}>{children}</CartContext.Provider>;
};
export default CartProvider;
