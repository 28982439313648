import CourseListCard from "../Components/CourseListCard";
import React, { useEffect, useState } from "react";
import { courses } from "../data/courses/courses";
import Base from "../Base";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
const CoursesList = (props) => {
	const [categoryWiseCourses, setCategoryWiseCourses] = useState([]);
	useEffect(async () => {
		var mounted = true;
		if (mounted) {
			await courses((data) => {
				if (props.match.params.category !== "all") {
					selectCourses(data, props.match.params.category);
				} else {
					setCategoryWiseCourses(data);
				}
			});
		}
		return () => {
			mounted = false;
		};
	}, [props.match.params.category]);

	// ANCHOR Category Wise Courses
	const selectCourses = (coursesArray, category) => {
		let newCoursesArray = [];
		newCoursesArray = coursesArray.filter(
			(course) => course.course_category.find((cat) => cat.category.toLowerCase() === category.toLowerCase())?.category.toLowerCase() === category.toLowerCase()
		);
		setCategoryWiseCourses(newCoursesArray);
	};
	return (
		<>
			<Helmet>
				<title>International Lunar University | Learn</title>
			</Helmet>
			<Base>
				<section className="section">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-5 my-3 mx-2 mx-lg-0 my-lg-0 col-lg-2 text-center">
								<Link
									className={
										props.match.params.category === "all"
											? "fw-bold py-2 px-3 fontsize14 buynowbutton border5px"
											: "fw-bold py-2 px-3 fontsize14 addtocartbutton border5px"
									}
									to="/learn/all"
								>
									All
								</Link>
							</div>
							<div className="col-5 my-3 mx-2 mx-lg-0 my-lg-0 col-lg-2 text-center">
								<Link
									className={
										props.match.params.category === "course"
											? "fw-bold py-2 px-3 fontsize14 buynowbutton border5px"
											: "fw-bold py-2 px-3 fontsize14 addtocartbutton border5px"
									}
									to="/learn/course"
								>
									Courses
								</Link>
							</div>
							<div className="col-5 my-3 mx-2 mx-lg-0 my-lg-0 col-lg-2 text-center">
								<Link
									className={
										props.match.params.category === "specialization"
											? "fw-bold py-2 px-3 fontsize14 buynowbutton border5px"
											: "fw-bold py-2 px-3 fontsize14 addtocartbutton border5px"
									}
									to="/learn/specialization"
								>
									Specializations
								</Link>
							</div>
							<div className="col-5 my-3 mx-2 mx-lg-0 my-lg-0 col-lg-2 text-center">
								<Link
									className={
										props.match.params.category === "masterclass"
											? "fw-bold py-2 px-3 fontsize14 buynowbutton border5px"
											: "fw-bold py-2 px-3 fontsize14 addtocartbutton border5px"
									}
									to="/learn/masterclass"
								>
									Masterclasses
								</Link>
							</div>
							<div className="col-5 my-3 mx-2 mx-lg-0 my-lg-0 col-lg-2 text-center">
								<Link
									className={
										props.match.params.category === "project"
											? "fw-bold py-2 px-3 fontsize14 buynowbutton border5px"
											: "fw-bold py-2 px-3 fontsize14 addtocartbutton border5px"
									}
									to="/learn/project"
								>
									Projects
								</Link>
							</div>
						</div>
						<div className="row mt-4">
							{categoryWiseCourses.map((course, index) => {
								return <CourseListCard key={index} course={course} />;
							})}
						</div>
					</div>
				</section>
			</Base>
		</>
	);
};
export default CoursesList;
