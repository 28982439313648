import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "./authentication";
const PrivateRoutes = ({ component: Component, courseContent, findCourseContent, profile, handleProfileData, course, findCourse, handleQuizData, quizData, badge, ...rest }) => {
	const [status] = useState(null);
	if (status === 403 || status === 401) {
		localStorage.removeItem("token");
		return <Redirect to="/signin" />;
	}
	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated() ? (
					<Component
						{...props}
						courseContent={courseContent}
						findCourseContent={findCourseContent}
						profile={profile}
						handleProfileData={handleProfileData}
						course={course}
						findCourse={findCourse}
						handleQuizData={handleQuizData}
						quizData={quizData}
						badge={badge}
					/>
				) : (
					<Redirect
						to={{
							pathname: "/signin",
						}}
					/>
				)
			}
		/>
	);
};
export default PrivateRoutes;
