import Hero from "../Components/Hero";
import Info1 from "../Components/Info1";
import BundleList from "../Components/BundleList";
import CourseList from "../Components/CourseList";
import TestimonialsList from "../Components/TestimonialsList";
import React, { useEffect, useState, useContext } from "react";
import Base from "../Base";
import AOS from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Helmet } from "react-helmet-async";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import { isAuthenticated } from "../helpers/auth/authentication";
import ProfileCourseCardList from "../Components/ProfileCourseCardList";
import { BaseContext } from "../Context";
const Home = () => {
	const [toggleAOS, setToggleAOS] = useState(false);
	const [home_courses, setHome_Courses] = useState([]);
	const [toggleSpacings, setToggleSpacings] = useState(false);
	const { course_Enrollments } = useContext(BaseContext);
	const handleHomeCourses = (array) => {
		setHome_Courses(array);
	};
	useEffect(() => {
		var mounted = true;
		if (mounted) {
			AOS.init();
		}
		return () => {
			mounted = false;
		};
	}, []);
	useEffect(() => {
		var mounted = true;
		if (mounted) {
			AOS.refresh();
		}
		return () => {
			mounted = false;
		};
	}, [toggleAOS]);
	useEffect(() => {
		if (isAuthenticated() && course_Enrollments && !course_Enrollments.error && course_Enrollments.length > 0) {
			setToggleSpacings(true);
		}
	}, []);
	const [countUpNum1, setCountUpNum1] = useState(0);
	const [countUpNum2, setCountUpNum2] = useState(0);
	const [countUpNum3, setCountUpNum3] = useState(0);
	return (
		<>
			<Helmet>
				<title>International Lunar University | Home</title>
				<meta name="description" content="International Lunar University is an online learning portal by International Lunar University!" />
				{/* <meta property="og:image" content="https://ilu-.spaceonova.space/images/Meta_Image.jpg" /> */}
			</Helmet>
			<Base>
				<Hero />
				{!isAuthenticated() && (
					<>
						<VisibilitySensor
							onChange={() => {
								setToggleAOS(!toggleAOS);
							}}
						>
							<section className="section mb-0 overflow-hidden">
								<div className="container">
									<div className="row mx-3 mt-3 mx-lg-0">
										<div className="col-lg-4 col-12" data-aos="flip-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="400">
											<div className="card border5px border-0 shadow text-center">
												<div className="card-body">
													<div className="explore-features rounded-circle hvr-icon-grow shadow-lg d-inline-block">
														<i className="fas hvr-icon fa-users" />
													</div>
													<div className="content mt-3">
														<h2 className="mb-0 colorblack pt-2">
															<span>
																<CountUp
																	duration={3.5}
																	end={33000}
																	start={countUpNum1}
																	onEnd={() => {
																		setCountUpNum1(33000);
																	}}
																>
																	{({ countUpRef, start }) => (
																		<VisibilitySensor onChange={start} delayedCall>
																			<span ref={countUpRef} />
																		</VisibilitySensor>
																	)}
																</CountUp>
															</span>
															+
														</h2>
														<h6 className="mb-0 mt-2 darkbluetext">Students Impacted</h6>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-4 col-12 mt-3 mt-lg-0" data-aos="flip-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="500">
											<div className="card border5px border-0 shadow text-center">
												<div className="card-body">
													<div className="explore-features rounded-circle hvr-icon-grow shadow-lg d-inline-block">
														<i className="fas hvr-icon fa-chalkboard-teacher" />
													</div>
													<div className="content mt-3">
														<h2 className="mb-0 colorblack pt-2">
															<span>
																<CountUp
																	duration={3}
																	end={6}
																	start={countUpNum2}
																	onEnd={() => {
																		setCountUpNum2(6);
																	}}
																>
																	{({ countUpRef, start }) => (
																		<VisibilitySensor onChange={start} delayedCall>
																			<span ref={countUpRef} />
																		</VisibilitySensor>
																	)}
																</CountUp>
															</span>
															+
														</h2>
														<h6 className="mb-0 mt-2 darkbluetext">Current Courses</h6>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-4 col-12 mt-3 mt-lg-0" data-aos="flip-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="600">
											<div className="card border5px border-0 shadow text-center">
												<div className="card-body">
													<div className="explore-features rounded-circle hvr-icon-grow shadow-lg d-inline-block">
														<i className="fas hvr-icon fa-trophy-alt" />
													</div>
													<div className="content mt-3">
														<h2 className="mb-0 colorblack pt-2">
															<span>
																<CountUp
																	duration={3}
																	end={50}
																	start={countUpNum3}
																	onEnd={() => {
																		setCountUpNum3(50);
																	}}
																>
																	{({ countUpRef, start }) => (
																		<VisibilitySensor onChange={start} delayedCall>
																			<span ref={countUpRef} />
																		</VisibilitySensor>
																	)}
																</CountUp>
															</span>
															+
														</h2>
														<h6 className="mb-0 mt-2 darkbluetext">Cummulative Years of Experience</h6>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</VisibilitySensor>
						<Info1 />
						<VisibilitySensor
							onChange={() => {
								setToggleAOS(!toggleAOS);
							}}
						>
							<section className="section mt-0 overflow-hidden">
								<div className="container">
									<div className="row mx-3 mx-lg-0">
										<div className="col-12">
											<div className="row">
												<div className="col-lg-4" data-aos="flip-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="">
													<div className="card border-0 text-center p-4 shadow overflow-hidden" style={{ height: "200px" }}>
														<div className="text-center mx-auto hvr-icon-grow">
															<i className="fas border5px shadow features colorwhite gradientblue hvr-icon fa-project-diagram d-block fa-2x mb-0" />
														</div>
														<div className="card-body p-0 mt-3">
															<p className="fontsize16 mb-1 text-uppercase darkbluetext">Project Based Learning</p>
															<p className="fontsize16 colorblack">Select your Project / training Programme.</p>
														</div>
													</div>
												</div>
												<div className="col-lg-4 mt-4 pt-2 mt-sm-0 pt-sm-0" data-aos="flip-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="200">
													<div className="card border-0 text-center p-4 shadow overflow-hidden" style={{ height: "200px" }}>
														<div className="text-center mx-auto hvr-icon-grow">
															<i className="fas border5px shadow features colorwhite gradientblue hvr-icon fa-badge-check d-block fa-2x mb-0" />
														</div>
														<div className="card-body p-0 mt-3">
															<p className="fontsize16 mb-1 text-uppercase darkbluetext">Lifetime Access</p>
															<p className="fontsize16 colorblack">Anytime, anywhere at your pace</p>
														</div>
													</div>
												</div>
												<div className="col-lg-4 mt-4 pt-2 mt-sm-0 pt-sm-0" data-aos="flip-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="300">
													<div className="card border-0 text-center p-4 shadow overflow-hidden" style={{ height: "200px" }}>
														<div className="text-center mx-auto hvr-icon-grow">
															<i className="fas border5px shadow features colorwhite gradientblue hvr-icon fa-user-headset d-block fa-2x mb-0" />
														</div>
														<div className="card-body p-0 mt-3">
															<p className="fontsize16 mb-1 text-uppercase darkbluetext">Career Support</p>
															<p className="fontsize16 colorblack">Expert Mentors Interactive Community Also Consultations</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</VisibilitySensor>
					</>
				)}
				{isAuthenticated() && course_Enrollments && !course_Enrollments.error && course_Enrollments.length > 0 && (
					<>
						<VisibilitySensor
							onChange={() => {
								setToggleAOS(!toggleAOS);
							}}
						>
							<ProfileCourseCardList />
						</VisibilitySensor>
					</>
				)}
				<VisibilitySensor
					onChange={() => {
						setToggleAOS(!toggleAOS);
					}}
				>
					<CourseList home_courses={home_courses} toggleSpacings={toggleSpacings} handleHomeCourses={handleHomeCourses} />
				</VisibilitySensor>
				<VisibilitySensor
					onChange={() => {
						setToggleAOS(!toggleAOS);
					}}
				>
					<BundleList />
				</VisibilitySensor>
				<VisibilitySensor
					onChange={() => {
						setToggleAOS(!toggleAOS);
					}}
				>
					<section className="section overflow-hidden">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-12" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true">
									<div className="mb-4">
										<h1 className="colorblack mb-5 text-center">Come Fly With Us</h1>
										<p className="text-center my-4">
											<img src="images/SVGs/Feedback.svg" width="350px" className="" alt="" />
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</VisibilitySensor>
				<VisibilitySensor
					onChange={() => {
						setToggleAOS(!toggleAOS);
					}}
				>
					<TestimonialsList />
				</VisibilitySensor>
			</Base>
		</>
	);
};
export default Home;
