const Storage = (wishlistItems) => {
	localStorage.setItem(
		"wishlist",
		JSON.stringify(
			wishlistItems.courses.length > 0 || wishlistItems.bundles.length > 0
				? wishlistItems
				: {
						courses: [],
						bundles: [],
				  }
		)
	);
};
export const setStorage = (allWishlistItems, filteredWishlistItems) => {
	Storage(allWishlistItems);
	let coursesCount = 0;
	let bundlesCount = 0;
	let totalWishlistItemsCount = 0;
	if (filteredWishlistItems.courses && filteredWishlistItems.courses.length > 0) {
		coursesCount = filteredWishlistItems?.courses.length;
	}
	if (filteredWishlistItems.bundles && filteredWishlistItems.bundles.length > 0) {
		console.log("checking");
		bundlesCount = filteredWishlistItems?.bundles.length;
	}
	totalWishlistItemsCount = coursesCount + bundlesCount;
	return { totalWishlistItemsCount, coursesCount, bundlesCount };
};
export const WishlistReducer = (state, action) => {
	switch (action.type) {
		case "ADD_COURSE":
			if (!state.wishlistItems.courses.find((item) => item.course.guid === action.payload.course.guid && item.userID === action.payload.userID)) {
				state.wishlistItems.courses.push({
					...action.payload,
					userID: action.payload.userID,
				});
			}
			return {
				...state,
				...setStorage(state.wishlistItems, {
					courses: state.wishlistItems.courses.filter((item) => item.userID === action.payload.userID),
					bundles: state.wishlistItems.bundles.filter((item) => item.userID === action.payload.userID),
				}),
				wishlistItems: { ...state.wishlistItems },
			};
		case "REMOVE_COURSE":
			let newCourseArray = state.wishlistItems.courses.filter((item) => item.course.guid !== action.payload.guid && item.userID === action.payload.userID);
			state.wishlistItems = {
				...state.wishlistItems,
				courses: newCourseArray,
			};
			return {
				...state,
				...setStorage(state.wishlistItems, {
					courses: state.wishlistItems.courses.filter((item) => item.userID === action.payload.userID),
					bundles: state.wishlistItems.bundles.filter((item) => item.userID === action.payload.userID),
				}),
				wishlistItems: { ...state.wishlistItems },
			};

		case "ADD_BUNDLE":
			if (!state.wishlistItems.bundles.find((item) => item.bundle.guid === action.payload.bundle.guid && item.userID === action.payload.userID)) {
				console.log("called");

				state.wishlistItems.bundles.push({
					...action.payload,
					userID: action.payload.userID,
				});
				// console.log(state.wishlistItems.bundles.filter((item) => item.userID === action.payload.userID));
			}
			return {
				...state,
				...setStorage(state.wishlistItems, {
					courses: state.wishlistItems.courses.filter((item) => item.userID === action.payload.userID),
					bundles: state.wishlistItems.bundles.filter((item) => item.userID === action.payload.userID),
				}),
				wishlistItems: { ...state.wishlistItems },
			};
		case "REMOVE_BUNDLE":
			let newBundleArray = state.wishlistItems.bundles.filter((item) => item.bundle.guid !== action.payload.guid && item.userID === action.payload.userID);
			state.wishlistItems = {
				...state.wishlistItems,
				bundles: newBundleArray,
			};
			return {
				...state,
				...setStorage(state.wishlistItems, {
					courses: state.wishlistItems.courses.filter((item) => item.userID === action.payload.userID),
					bundles: state.wishlistItems.bundles.filter((item) => item.userID === action.payload.userID),
				}),
				wishlistItems: { ...state.wishlistItems },
			};

		// case "CLEAR_WISHLIST":
		// 	state.cartItems = {
		// 		courses: state.wishlistItems.courses.filter((item) => item.userID !== action.payload),

		// 	};
		// 	return {
		// 		...state,
		// 		...setStorage(state.wishlistItems, {
		// 			courses: [],
		// 		}),
		// 		wishlistItems: { ...state.wishlistItems },
		// 	};
		case "UPDATE_WISHLIST_ON_AUTH":
			return {
				...state,
				...setStorage(state.wishlistItems, {
					courses: state.wishlistItems.courses.filter((item) => item.userID === action.payload),
					bundles: state.wishlistItems.bundles.filter((item) => item.userID === action.payload),
				}),
				wishlistItems: { ...state.wishlistItems },
			};
		default:
			return state;
	}
};
