import { Link } from "react-router-dom";
import { useContext } from "react";
import { OrderDetailContext } from "../Contexts/OrderDetailContext";
import tempImg from "../Assets/images/Course_Image.webp";
import { useMediaQuery } from "react-responsive";
const OrderDetailCard = ({ id }) => {
	const { orderdetailItems } = useContext(OrderDetailContext);
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
	return (
		<>
			<div className="row justify-content-center">
				{orderdetailItems.find((item) => item.order_id === id) && orderdetailItems.find((item) => item.order_id === id).courses && (
					<>
						{orderdetailItems
							.find((item) => item.order_id === id)
							.courses.split("\n")
							.reverse()
							.slice(1)
							.reverse()
							.map((order, index) => {
								return (
									<>
										{isDesktopOrLaptop && (
											<div key={index} className="row px-2 py-3">
												<div className="col-3">
													<Link to={`/learn/course/${order.split(",")[0]}`}>
														<img
															className="border5px shadow"
															src={order.split(",")[3] === "  " ? tempImg : order.split(",")[3]}
															width="150px"
															height="150px"
															alt="Course_Image"
														/>
													</Link>
												</div>
												<div className="col-9 d-flex flex-column justify-content-center text-start">
													<div className="row">
														<div className="col-lg-12">
															<Link to={`/learn/course/${order.split(",")[0]}`} className="colorblack fw-bold fontsize16 darkbluetexthover">
																{order.split(",")[1]}
															</Link>
														</div>
													</div>
													<div className="row mt-3">
														<div className="col-lg-7 d-flex align-items-center">
															<p className="mb-0 colorblack fontsize14">Course Price: ₹ {order.split(",")[2].toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
														</div>
													</div>
												</div>
											</div>
										)}
										{isTabletOrMobile && (
											<div key={index} className="row px-2 py-3">
												<div className="col-4">
													<Link to={`/learn/course/${order.split(",")[0]}`}>
														<img
															className="border5px shadow"
															src={order.split(",")[3] === "  " ? tempImg : order.split(",")[3]}
															width="100px"
															height="100px"
															alt="Course_Image"
														/>
													</Link>
												</div>
												<div className="col-8 d-flex flex-column justify-content-center text-start">
													<div className="row">
														<div className="col-lg-12">
															<Link to={`/learn/course/${order.split(",")[0]}`} className="colorblack fw-bold fontsize16 darkbluetexthover">
																{order.split(",")[1]}
															</Link>
														</div>
													</div>
													<div className="row mt-3">
														<div className="col-lg-7 d-flex align-items-center">
															<p className="mb-0 fontsize14">Course Price: ₹ {order.split(",")[2].toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
														</div>
													</div>
												</div>
											</div>
										)}
									</>
								);
							})}
					</>
				)}
			</div>
		</>
	);
};
export default OrderDetailCard;
