import React from "react";
import { Helmet } from "react-helmet-async";
import Base from "../../Base";
import Breadcrumb from "../../Components/Breadcrumb";
import { isAuthenticated } from "../../helpers/auth/authentication";
const VerifyEmailConfirm = () => {
	return (
		<>
			{!isAuthenticated() && localStorage.getItem("emailV") && (
				<>
					<Helmet>
						<title>International Lunar University | Verification Email Sent</title>
					</Helmet>
					<Base>
						<>
							<Breadcrumb title="Verification Email Sent" />
							<div className="container" style={{ width: "350px" }}>
								<form className="bgcolorgreyish border-0 border5px my-4 p-4">
									<div className="text-center w-100 mb-4">
										<img src="images/SVGs/Verification_Email_Sent.svg" alt="Verify_Email" className="ps-3 loginsvg" height="200px" />
									</div>
									<div className="text-center w-100">
										<p className="colorblack mb-0">Please confirm your email by clicking on the link in the mail sent to you.</p>
									</div>
								</form>
							</div>
						</>
					</Base>
				</>
			)}
		</>
	);
};
export default VerifyEmailConfirm;
