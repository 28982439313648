import { Badges_API } from "../../backend";
export const badgesdata = async (next) => {
	const tokenValue = localStorage.getItem("token").replace(/['"]+/g, "");
	await fetch(`${Badges_API}`, {
		method: "GET",
		headers: {
			Authorization: "Token " + tokenValue,
			"Content-Type": "application/json",
		},
	})
		.then((resp) => {
			return resp.json();
		})
		.then((data) => {
			next(data);
		})
		.catch((err) => console.log(err));
};
