import Breadcrumb from "../Components/Breadcrumb";
import React, { useContext, useState } from "react";
import Base from "../Base";
import { Helmet } from "react-helmet-async";
import CSRFToken from "../CSRFToken";
import { contactusForm } from "../../src/helpers/others/contactusForm";
import { toast } from "react-toastify";
import DataLoader2 from "../Components/DataLoaders/DataLoader2";
import { isAuthenticated } from "../helpers/auth/authentication";
import { BaseContext } from "../Context";
const ContactUs = () => {
	const { cookies } = useContext(BaseContext);
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState(isAuthenticated() ? (cookies?.user?.[0]?.first_name.length > 0 ? cookies?.user?.[0]?.first_name : cookies?.user?.[0]?.username) : "");
	const [email, setEmail] = useState(isAuthenticated() ? cookies?.user?.[0]?.email : "");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const contact = async (e) => {
		setLoading(true);
		e.preventDefault();
		if (email.includes("@")) {
			await contactusForm({ name, email, subject, message }, (data) => {
				if (data?.error) {
					setLoading(false);
					return toast(data.error, { type: "error", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
				} else {
					setName(isAuthenticated() ? (cookies?.user?.[0]?.first_name.length > 0 ? cookies?.user?.[0]?.first_name : cookies?.user?.[0]?.username) : "");
					setEmail(isAuthenticated() ? cookies?.user?.[0]?.email : "");
					setSubject("");
					setMessage("");
					setLoading(false);
					return toast("Form submitted!", { type: "success", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
				}
			});
		} else {
			setLoading(false);
			return toast("You have not entered an email.", { type: "error", autoClose: 5000, position: "bottom-center", hideProgressBar: false, pauseOnHover: true, pauseOnFocusLoss: true });
		}
	};
	return (
		<>
			<Helmet>
				<title>International Lunar University | Contact Us</title>
			</Helmet>
			<Base>
				<Breadcrumb title="Contact Us" />
				<section className="section feedback">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 col-md-6">
								<div className="me-lg-5 mb-3 mb-lg-0">
									<img src="images/SVGs/Contact_Us.svg" className="loginsvg" alt="Contact_Us" />
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="card bgcolorgreyish border-0 border5px p-4">
									<div className="card-body p-0">
										<h2 className="card-title colorblack text-center">Get In Touch!</h2>
										<form className="mt-4">
											<CSRFToken />
											<div className="row">
												<div className="col-lg-12">
													<h5 className="colorblack text-start mb-2 fontsize14">Name</h5>
													<div className="position-relative mb-3">
														<input
															className="input100 w-100 border5px border-0 colorblack"
															type="text"
															placeholder="Name"
															value={
																isAuthenticated() ? (cookies?.user?.[0]?.first_name.length > 0 ? cookies?.user?.[0]?.first_name : cookies?.user?.[0]?.username) : name
															}
															onChange={(e) => {
																setName(e.target.value);
															}}
															required
															disabled={isAuthenticated() ? true : false}
														/>
														<span className="focus-input100" />
														<span className="symbol-input100 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className="far fa-user-astronaut" />
															</span>
														</span>
													</div>
												</div>
												<div className="col-lg-12">
													<h5 className="colorblack text-start mb-2 fontsize14">Email</h5>
													<div className="position-relative mb-3">
														<input
															className="input100 w-100 border5px border-0 colorblack"
															type="email"
															placeholder="Email"
															value={isAuthenticated() ? cookies?.user?.[0]?.email : email}
															onChange={(e) => {
																setEmail(e.target.value);
															}}
															required
															disabled={isAuthenticated() ? true : false}
														/>
														<span className="focus-input100" />
														<span className="symbol-input100 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className="far fa-envelope" />
															</span>
														</span>
													</div>
												</div>
												<div className="col-lg-12">
													<h5 className="colorblack text-start mb-2 fontsize14">Subject</h5>
													<div className="position-relative mb-3">
														<input
															className="input100 w-100 border5px border-0 colorblack"
															type="text"
															placeholder="Subject"
															value={subject}
															onChange={(e) => {
																setSubject(e.target.value);
															}}
															required
														/>
														<span className="focus-input100" />
														<span className="symbol-input100 d-flex align-items-center position-absolute colorblack h-100">
															<span>
																<i className="far fa-envelope-open-text" />
															</span>
														</span>
													</div>
												</div>
												<div className="col-lg-12">
													<h5 className="colorblack text-start mb-2 fontsize14">Message</h5>
													<div className="position-relative mb-3">
														<textarea
															className="colorblack bgcolorwhite p-3 border5px border-0 w-100"
															style={{ height: "150px", resize: "none" }}
															placeholder="Message"
															value={message}
															onChange={(e) => {
																setMessage(e.target.value);
															}}
															required
														/>
													</div>
												</div>
												<div className="col-lg-12">
													<div className="d-grid">
														<button
															className="fw-bold py-3 px-2 fontsize16 buynowbutton border5px text-uppercase d-inline-block"
															type="submit"
															onClick={(e) => {
																contact(e);
															}}
														>
															{loading ? <DataLoader2 loaderSize={15} loaderType="ScaleLoader" loaderColor="#0007bf" /> : "Send Message"}
														</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container mt-5">
						<div className="row">
							<div className="col-md-4">
								<div className="card bgcolorgreyish border5px border-0 p-4 text-center h-100">
									<a target="_blank" rel="noreferrer noopener" href="tel:+91-965-189-XXXX">
										<div className="icons text-center hvr-icon-grow">
											<i className="fas colorwhite features border5px icon fa-2x fa-phone-alt hvr-icon" />
										</div>
									</a>
									<div className="mt-3">
										<p className="colorblack mb-2">You can contact on this number.</p>
										<div className="mt-4">
											<a target="_blank" rel="noreferrer noopener" href="tel:+91-965-189-XXXX" className="lightbluehover text-uppercase darkbluetext">
											+91-965-189-XXXX
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
								<div className="card bgcolorgreyish border5px border-0 p-4 text-center h-100">
									<a target="_blank" rel="noreferrer noopener" href="mailto:ashutosh.mishra@community.isunet.edu">
										<div className="icons text-center hvr-icon-grow">
											<i className="fas colorwhite features border5px icon fa-2x fa-envelope hvr-icon" />
										</div>
									</a>
									<div className="mt-3">
										<p className="colorblack mb-2">You can send us an email on this email.</p>
										<div className="mt-4">
											<a target="_blank" rel="noreferrer noopener" href="mailto:ashutosh.mishra@community.isunet.edu" className="lightbluehover text-uppercase darkbluetext">
												ashutosh.mishra@community.isunet.edu
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
								<div className="card bgcolorgreyish border5px border-0 p-4 text-center">
									<a target="_blank" rel="noreferrer noopener">
										<div className="icons text-center hvr-icon-grow">
											<i className="fas colorwhite features border5px icon fa-2x fa-map-marker-alt hvr-icon" />
										</div>
									</a>
									<div className="mt-3">
										<p className="colorblack mb-2">You can come and vist us at this address.</p>
										<a target="_blank" rel="noreferrer noopener" className="lightbluehover text-uppercase darkbluetext">
											My Home, 123 Street, You Are Welcome
											<br />
											Delhi, India
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Base>
		</>
	);
};
export default ContactUs;
