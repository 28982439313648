import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";
import CartProvider from "./Contexts/CartContext";
import OrderDetailProvider from "./Contexts/OrderDetailContext";
import WishlistProvider from "./Contexts/WishlistContext";
import SingleEntityProvider from "./Contexts/SingleEntityContext";
import QuizResponseProvider from "./Contexts/QuizResponseContext";
ReactDOM.render(
	<React.StrictMode>
		<HelmetProvider>
			<CookiesProvider>
				<CartProvider>
					<SingleEntityProvider>
						<WishlistProvider>
							<OrderDetailProvider>
								<QuizResponseProvider>
									<Routes />
								</QuizResponseProvider>
							</OrderDetailProvider>
						</WishlistProvider>
					</SingleEntityProvider>
				</CartProvider>
			</CookiesProvider>
		</HelmetProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
