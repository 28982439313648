import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "@videojs/themes/dist/city/index.css";

export default class VideoPlayer extends React.Component {
    componentDidMount() {
        this.player = videojs(this.videoNode, {
            controls: true,
            autoplay: false,
            preload: 'auto',
            sources: [{
                src: this.props.src,
                type: 'video/mp4'
            }]
        }, function onPlayerReady() {
            console.log('Player is ready');
        });
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    render() {
        return (
            <div className="vplayer">
                <div data-vjs-player>
                    <video ref={(node) => (this.videoNode = node)} id="videoJS" className="video-js vjs-theme-fantasy vjs-big-play-centered vjs-fluid"></video>
                </div>
            </div>
        );
    }
}
