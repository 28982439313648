import React from "react";
import { useContext, useState } from "react";
import { BaseContext } from "../Context";
import ProfileCourseCard from "./ProfileCourseCard";
const ProfileCourseCardList = () => {
	const { course_Enrollments } = useContext(BaseContext);
	const [toggleClass, setToggleClass] = useState(false);
	return (
		<>
			<section className="section overflow-hidden">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-12">
							<div className="text-center py-4" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
								<h2 className="mb-5 colorblack">My Courses</h2>
							</div>
						</div>
					</div>
					<div className="row">
						{!course_Enrollments.error &&
							course_Enrollments.length > 0 &&
							course_Enrollments.map((course, index) => {
								return <ProfileCourseCard course={course} toggleClass={toggleClass} key={index} />;
							})}
					</div>
				</div>
			</section>
		</>
	);
};
export default ProfileCourseCardList;
